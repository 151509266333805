.contact-container {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.contact-container .contact-form-container {
	width: 100%;
	max-width: 400px;
	background-color: #f6f6f6;
	margin: 1rem 0;
	padding: 2rem;
	border-radius: 10px;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.contact-container .contact-form-container h1 {
	font-size: 1.5rem;
}

.contact-container .contact-form-container .form-inputs {
	margin: 0.5rem 0;
	width: 100%;
}

.contact-container .contact-form-container .form-inputs p {
	font-size: 1rem;
	font-weight: 600;
	margin: 0;
	margin-bottom: 0.5rem;
}

.contact-container .contact-form-container .form-inputs .form-input {
	width: 100%;
	height: 40px;
	outline: none;
	border: 1px solid #c5c5c5;
	padding: 0 0.5rem;
	border-radius: 5px;
}


.contact-container .contact-form-container .form-inputs input{
	width: 100%;
}

.contact-container .contact-form-container .form-inputs textarea {
	width: 100%;
	height: 100px;
	resize: none;
	outline: none;
	border: 1px solid #c5c5c5;
	padding: 0.5rem;
	border-radius: 5px;
}

.contact-container .contact-form-container button {
	width: 120px;
	height: 40px;
	font-size: 1rem;
	background-color: #ef6f0b;
	border-radius: 5px;
	color: #fff;
	outline: none;
	border: none;
	cursor: pointer;
}
