.categorylist-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.categorylist-container .categorylist-breadcrumb {
  width: 100%;
  height: 50px;
  background-color: #f7f7f7;
  border-bottom: 1.2px solid #e5e5e5;
  box-shadow: inset 0px 1px 3px 0px rgba(0, 0, 0, 0.2);
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.categorylist-container .categorylist-breadcrumb .breadcrumb-menu {
  width: 85%;
  max-width: 1500px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.categorylist-container
  .categorylist-breadcrumb
  .breadcrumb-menu
  .bd-menu-list {
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.categorylist-container
  .categorylist-breadcrumb
  .breadcrumb-menu
  .bd-menu-list
  a {
  font-size: 14px;
  margin-right: 14px;
  text-decoration: none;
  color: #414141;
}

.categorylist-container
  .categorylist-breadcrumb
  .breadcrumb-menu
  .bd-menu-list
  a
  i {
  font-size: 10px;
}

.categorylist-container
  .categorylist-breadcrumb
  .breadcrumb-menu
  .bd-menu-stats {
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.categorylist-container
  .categorylist-breadcrumb
  .breadcrumb-menu
  .bd-menu-stats
  p {
  font-size: 14px;
  color: #414141;
}

@media (max-width: 680px){
  .categorylist-container
  .categorylist-breadcrumb
  .breadcrumb-menu
  .bd-menu-stats{
    display: none;
  }
}