.navbar {
	font-size: 14px;
	height: 60px;
	width: 100%;
	/* border-bottom: 1px solid darkgray; */
	padding: 0 2rem;
	background-color: #fff;
	position: sticky;
	top: 0;
	z-index: 999;
	box-shadow: 0 1px 3px 0 #dee0e2;
}

.navbar .navScrollContainer {
	display: none;
	flex-direction: row;
	height: 100%;
	width: 100%;
	align-items: center;
}

.navbar .navScrollContainer .fa-bars {
	cursor: pointer;
	color: #000;
	-webkit-tap-highlight-color: transparent;
	margin-right: 5px;
}

.navbar .searchContainer {
	width: 90%;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-left: 5px;
	position: relative;
}

.navbar .searchContainer .input-container {
	width: 90%;
	height: 100%;
	display: flex;
	align-items: center;
	border: 1.5px solid lightgrey;
	/* border-radius: 50px; */
	border-top-left-radius: 20px;
	border-bottom-left-radius: 20px;
	border-right: none;
	padding-left: 2rem;
	background-color: #fff;
	transition: all 0.25s ease-in-out;
}

.navbar .searchContainer.active .input-container {
	border-bottom-left-radius: 0px;
	border-top-left-radius: 10px;
	transition: all 0.25s ease-in-out;
}

.navbar .searchContainer .input-container i {
	width: 15%;
	font-size: 1rem;
	display: flex;
	align-items: center;
	height: 100%;
	justify-content: center;
	color: #fff;
}

.navbar .searchContainer .input-container input {
	width: 85%;
	height: 90%;
	outline: none;
	border: none;
	font-size: 16px;
	background-color: #fff;
	color: #000;
	/* font-weight: 200; */
}

.navbar .searchContainer .input-container input::placeholder {
	color: #000;
}

.navbar .searchContainer button {
	height: 100%;
	width: 15%;
	background-color: #fff;
	border: none;
	border-top-right-radius: 20px;
	border-bottom-right-radius: 20px;
	border: 1.5px solid lightgray;
	border-left: none;
	font-size: 1.2rem;
	color: #000;
	cursor: pointer;
	outline: none;
	transition: all 0.25s ease-in-out;
}

.navbar .searchContainer.active button {
	border-bottom-right-radius: 0px;
	border-top-right-radius: 10px;
	transition: all 0.25s ease-in-out;
}

.navbar .nav-container {
	display: flex !important;
	flex-direction: row;
	height: 100%;
	width: 100%;
	align-items: center;
	justify-content: space-between;
}

.navbar .nav-container .first-container {
	display: flex !important;
	flex-direction: row;
	align-items: center;
	/* justify-content: space-between; */
	height: 100%;
	width: 33%;
	transition: width 0.5s;
}

.navbar .nav-container .first-container-none {
	width: 0;
	transition: width 0.5s;
}

.navbar .nav-container .first-container .fa-bars {
	cursor: pointer;
	color: #000;
	-webkit-tap-highlight-color: transparent;
}

.navbar .nav-container .first-container .logo {
	display: flex;
	align-items: center;
	height: 60%;
	margin-left: 1rem;
	text-decoration: none;
	-webkit-tap-highlight-color: transparent;
}

.navbar .nav-container .first-container .logo img {
	height: 100%;
	object-fit: contain;
}

/* .navbar .nav-container .first-container .logo .logo-title{
    width: 80%;
} */

.navbar .nav-container .second-container {
	/* background-color: white; */
	width: 33%;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	/* overflow: hidden; */
	padding-left: 5px;
	position: relative;
}

.navbar .nav-container .second-container .input-container {
	width: 90%;
	height: 100%;
	display: flex;
	align-items: center;
	border: 1.5px solid lightgrey;
	border-top-left-radius: 20px;
	border-bottom-left-radius: 20px;
	border-right: none;
	padding-left: 2rem;
	background-color: #fff;
	transition: all 0.25s ease-in-out;
}

.navbar .nav-container .second-container.active .input-container {
	border-bottom-left-radius: 0px;
	border-top-left-radius: 10px;
	transition: all 0.25s ease-in-out;
}

.navbar .nav-container .second-container .input-container i {
	width: 15%;
	font-size: 1rem;
	display: flex;
	align-items: center;
	height: 100%;
	justify-content: center;
	color: #fff;
}

.navbar .nav-container .second-container .input-container input {
	width: 85%;
	height: 90%;
	outline: none;
	border: none;
	font-size: 16px;
	background-color: #fff;
	color: #000;
	/* font-weight: 200; */
}

.navbar .nav-container .second-container .input-container input::placeholder {
	color: #000;
}

.navbar .nav-container .second-container button {
	height: 100%;
	width: 15%;
	background-color: #fff;
	border: none;
	border-top-right-radius: 20px;
	border-bottom-right-radius: 20px;
	border: 1.5px solid lightgray;
	border-left: none;
	font-size: 1.2rem;
	color: #000;
	cursor: pointer;
	outline: none;
	transition: all 0.25s ease-in-out;
}

.navbar .nav-container .second-container.active button {
	border-bottom-right-radius: 0px;
	border-top-right-radius: 10px;
	transition: all 0.25s ease-in-out;
}

.navbar .nav-container .third-container {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	font-size: 16px;
	height: 100%;
	width: 33%;
	transition: width 0.5s;
}

.navbar .nav-container .third-container-none {
	width: 0;
	display: none;
	transition: all 0.5s;
}

.navbar .nav-container .third-container .fa-times {
	display: none;
}

.navbar .nav-container .third-container .links {
	display: flex;
	margin: auto 10px;
	text-decoration: none;
	color: #010302;
	padding: 5px;
	transition: transform 0.2s, margin 0.5s;
}

.navbar .nav-container .third-container .search-icon {
	color: #fff;
	display: none;
}

.navbar .nav-container .third-container a i {
	margin: auto 5px;
}

.navbar .nav-container .third-container a p {
	font-weight: 500;
	font-size: 0.9rem;
}
.navbar .nav-container .third-container .links:last-child {
	position: relative;
}
.navbar .nav-container .third-container .links:last-child .cartSize {
	position: absolute;
	top: -12px;
	left: 0px;
	border: 1px solid #fb641b;
	border-radius: 30px;
	width: 20px;
	height: 20px;
	text-align: center;
	color: white;
	background-color: #fb641b;
	font-size: 13px;
}

.navbar .nav-container .third-container a.login-signup {
	color: #fb641b;
	display: flex;
	transition: transform 0.2s;
}

.navbar .nav-container .third-container a.login-signup p {
	font-weight: 600;
}

.navbar .nav-container .third-container .profile {
	text-decoration: none;
	/* display: flex; */
	margin: auto 10px;
	color: #fb641b;
	font-weight: bold;
	position: relative;
	align-items: center;
	flex-direction: column;
	height: 100%;
	/* width: 30%; */
	/* width: 150px; */
}

.navbar .nav-container .third-container .profile .username {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	height: 100%;
	margin: auto 10px;
	position: relative;
}

.navbar .nav-container .third-container .profile .username p {
	font-size: 0.9rem;
	/* width: 90%;
  margin-left: auto; */
}

.navbar .nav-container .third-container .profile .username i {
	font-size: 12px;
	transition: all 0.2s;
}

.navbar .nav-container .third-container .profile:hover .username i {
	transform: rotate(180deg);
}

.navbar .nav-container .third-container .profile .options::after {
	content: "";
	background-color: #fff;
	width: 15px;
	height: 15px;
	border: 1px solid lightgray;
	border-right: none;
	border-bottom: none;
	position: absolute;
	top: -8px;
	left: 45%;
	transform: rotate(45deg);
	z-index: -1;
}

.navbar .nav-container .third-container .profile:hover .options {
	display: flex;
}

.navbar .nav-container .third-container .profile .options {
	display: none;
	flex-direction: column;
	background-color: #fff;
	width: 180px;
	padding: 0.25rem 0;
	position: absolute;
	top: 60px;
	right: 50%;
	transform: translateX(50%);
	align-items: center;
	height: fit-content;
	justify-content: center;
	border: 1px solid lightgrey;
	z-index: 2;
}

.navbar .nav-container .third-container .profile .options .option-links {
	display: flex;
	flex-direction: row;
	align-items: center;
	width: 100%;
	padding-left: 20px;
	text-decoration: none;
	height: 50px;
	color: #fb641b;
	transition: all 0.1s;
}

.navbar .nav-container .third-container .profileMobile i {
	color: #fb641b;
	display: none;
}

.navbar .nav-container .third-container .options .option-links p {
	text-align: center;
	font-size: 14px;
	font-weight: 500;
	padding-left: 30px;
}

.navbar .nav-container .third-container .options .option-links i {
	font-size: 13px;
}

.navbar .nav-container .third-container .options .option-links:hover {
	background-color: #e5e5e5;
	padding-left: 25px;
}

.navbar .nav-container .third-container .options .option-links:hover p {
	font-size: 15px;
}

.navbar .suggestions {
	width: 99%;
	position: absolute;
	top: 20px;
	z-index: 9;
	padding-top: 19px;
	border: 1px solid #ccc;
	border-top-width: 0;
	border-bottom-left-radius: 10px;
	border-bottom-right-radius: 10px;
	overflow: hidden;
}

.navbar .suggestions .suggestions-box {
	width: 100%;
	height: 250px;
	background-color: #fff;
	overflow-y: auto;
	border-top: 1px solid #ccc;
}

.navbar .suggestions .suggestions-box .suggest {
	font-size: 1rem;
	color: #464646;
	padding: 0.7rem 2rem;
	text-transform: capitalize;
	width: 100%;
	cursor: pointer;
}

.navbar .suggestions .suggestions-box .suggest:hover {
	background-color: #ccc;
}

@media (max-width: 1024px) {
	.navbar .nav-container .first-container {
		width: 30%;
	}

	.navbar .nav-container .first-container-none {
		width: 10%;
	}

	.navbar .nav-container .first-container-none .fa-bars {
		cursor: pointer;
		color: #000;
	}

	.navbar .nav-container .first-container-none .logo {
		display: none;
	}

	.navbar .nav-container .second-container {
		width: 30%;
	}

	.navbar .nav-container .third-container {
		width: 40%;
		justify-content: flex-end;
	}

	.navbar .nav-container .third-container-none {
		display: flex;
		width: 5%;
	}

	.navbar .nav-container .third-container-none a {
		display: none;
	}

	.navbar .nav-container .third-container-none .fa-times {
		font-size: 17px;
		margin-left: 5px;
		justify-content: center;
		cursor: pointer;
	}
}

@media (max-width: 768px) {
	.navbar .nav-container .third-container .search-icon {
		display: inline-block;
		color: #000;
		cursor: pointer;
	}
	.navbar {
		padding: 0 10px;
		height: 50px;
	}

	.navbar .suggestions {
		width: 98.5%;
	}

	.navbar .navScrollContainer {
		display: flex;
	}
	.navbar .hide {
		display: none !important;
	}

	.navbar .nav-container .second-container {
		display: none;
	}

	.navbar .nav-container .third-container {
		width: fit-content;
	}

	.navbar .nav-container .third-container a p {
		display: none;
	}

	.navbar .nav-container .first-container {
		width: 80%;
	}

	.navbar .nav-container .third-container .search-icon i {
		margin-right: 0;
	}

	.navbar .nav-container .third-container .profile {
		display: none;
	}
	.navbar .searchResult {
		top: 50px;
		width: 98%;
	}
	#cartId p:first-child {
		display: none;
	}
	.cartSize {
		display: flex !important;
		align-items: center;
		justify-content: center;
	}
	.navbar .nav-container .third-container .profileMobile i {
		display: block;
	}
}
