.coming-soon-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
}

.coming-soon-container .coming-box{
    width: 85%;
    height: 90vh;
    margin-top: 80px;
    padding: 2.5rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}

.coming-soon-container .coming-box h1 {
    color: #393280;
}