.productlist-container {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background-color: #fff;
}

.productlist-container .categorylist-breadcrumb {
	width: 100%;
	height: 50px;
	background-color: #f7f7f7;
	border-bottom: 1.2px solid #e5e5e5;
	box-shadow: inset 0px 1px 3px 0px rgba(0, 0, 0, 0.2);
	margin-bottom: 20px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.productlist-container .categorylist-breadcrumb .breadcrumb-menu {
	width: 85%;
	max-width: 1700px;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.productlist-container .categorylist-breadcrumb .breadcrumb-menu .bd-menu-list {
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-start;
}

.productlist-container .categorylist-breadcrumb .breadcrumb-menu .bd-menu-list a {
	font-size: 14px;
	margin-right: 14px;
	text-decoration: none;
	color: #414141;
	text-transform: capitalize;
}

.productlist-container .categorylist-breadcrumb .breadcrumb-menu .bd-menu-list a i {
	font-size: 10px;
}

.productlist-container .categorylist-breadcrumb .breadcrumb-menu .bd-menu-stats {
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

.productlist-container .categorylist-breadcrumb .breadcrumb-menu .bd-menu-stats p {
	font-size: 14px;
	color: #414141;
}

.productlist-container .catalogue {
	width: 85%;
	max-width: 1700px;
	display: flex;
	justify-content: space-between;
}

.productlist-container .catalogue .card-list-container {
	width: 75%;
}

.productlist-container .catalogue .card-list {
	width: 100%;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	padding: 0 0 0 1rem;
}

.productlist-container .catalogue .filter {
	width: 285px;
}

.productlist-container .catalogue .mobile-filter {
	display: none;
}
.mobile-filter-active {
	display: none;
}

@media (max-width: 1024px) {
	.productlist-container .catalogue {
		width: 95%;
	}
	.productlist-container .catalogue .card-list-container {
		width: 100%;
	}
}

@media (max-width: 680px) {
	.productlist-container .categorylist-breadcrumb .breadcrumb-menu .bd-menu-stats {
		display: none;
	}
	.productlist-container .catalogue {
		display: flex;
		justify-content: center;
		flex-direction: column;
	}
	.productlist-container .catalogue .filter {
		display: none;
	}
	.mobile-filter-active {
		display: flex;
		width: 100%;
		height: 50%;
	}
}

@media (max-width: 375px) {
	.productlist-container .catalogue .card-list {
		padding: 0;
	}
	.productlist-container .catalogue .card-list-container {
		width: 100%;
	}
}
