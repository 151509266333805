.poster {
	width: 98;
	height: 550px;
	background-color: #fff;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 2rem 0;
	max-width: 1700px;
	margin: 1rem auto;
}

.poster .image {
	display: flex;
	flex-direction: row;
	position: relative;
	width: 100%;
	height: 100%;
}

.poster .image img {
	width: 100%;
	height: 100%;
	object-fit: contain;
}

.poster .image .title img {
	height: 35%;
	width: 75%;
}

.poster .image .title p {
	font-size: 0.6rem;
	font-weight: 500;
}

.poster .image a {
	background-color: #fb641b;
	position: absolute;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	bottom: 20%;
	right: 30%;
	width: 250px;
	height: 50px;
	font-size: 14px;
	text-decoration: none;
	color: white;
	border-radius: 3px;
	/* padding: 0 40px 0 20px; */
}

.poster .image a .fa-arrow-right {
	font-size: 25px;
	margin-right: 10px;
	/* margin: 0 1rem; */
}

@media (max-width: 768px) {
	.poster {
		height: 380px;
		width: 100%;
	}

	.poster .image a {
		height: 40px;
		width: 150px;
		/* padding-right: 0; */
		font-size: 12px;
	}
}

@media (max-width: 680px) {
	.poster {
		height: 280px;
		padding-right: 10px;
	}

	.poster .image {
		width: 90%;
	}

	.poster .image a {
		height: 40px;
		width: 180px;
		/* padding-right: 0; */
		font-size: 14px;
	}

	.poster .image .title p {
		font-size: 0.8rem;
	}

	.poster .image a .fa-arrow-right {
		font-size: 17px;
		/* margin-right: 5px; */
	}
}

@media (max-width: 360px) {
	.poster .image a {
		/* font-size: 7px; */
		overflow: hidden;
	}

	.poster .image a .fa-arrow-right {
		font-size: 12px;
	}
}
