.not-found-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.not-found-container .not-found-box {
    width: 85%;
    height: 90vh;
    margin-top: 80px;
    padding: 2.5rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.not-found-container .not-found-box div {
    transform: scale(1.5);
    overflow: hidden;
}

