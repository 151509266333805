.loader {
	width: 100%;
	height: 100vh;
	position: fixed;
	top: 0;
	right: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #fff;
	z-index: 9999;
}

.loader .logo-box {
	width: 80px;
	height: 80px;
	border: 3px solid #000;
	border-radius: 50%;
	padding: 1rem;
}

.loader .logo-box img {
	width: 100%;
	object-fit: contain;
	animation: breath 2s infinite;
}

@keyframes breath {
	0%,
	100% {
		transform: scale(0.5);
	}
	50% {
		transform: scale(1);
	}
}
