.about {
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 98%;
  height: 500px;
  max-width: 1700px;
  margin: 1rem auto;
}

.about img {
  width: 33%;
  height: 100%;
  object-fit: cover;
}

.about .white-container {
  background-color: #000;
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 34%;
}

.about .white-container img {
  width: 150px;
  height: 30%;
  object-fit: contain;
  margin-bottom: 15px;
}

.about .white-container p {
  width: 75%;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  color: #fff;
}

@media (max-width: 768px) {
  .about {
    height: 400px;
  }
}

@media (max-width: 680px) {
  .about {
    flex-direction: column;
    height: auto;
  }

  .about img {
    width: 100%;
    height: 33%;
  }

  .about .white-container {
    width: 100%;
    padding: 20px 0;
  }

  .about .white-container p {
    font-size: 1.2rem;
  }
}

@media (max-width: 468px) {
  .about img {
    height: 30%;
  }

  .about .white-container {
    height: 40%;
  }

  .about .white-container img {
    width: 30%;
  }

  .about .white-container p {
    margin-top: 10px;
    font-size: 14px;
    width: 90%;
  }
}
