@import url("https://fonts.googleapis.com/css2?family=Mulish:wght@500;600;700;800&display=swap");
*,
body {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: "Mulish", sans-serif;
}

a {
	text-decoration: none;
}

.App {
	background-color: #eef0f3;
	position: relative;
	-webkit-tap-highlight-color: transparent;
}
