@import url(https://fonts.googleapis.com/css2?family=Mulish:wght@500;600;700;800&display=swap);


*,
body {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: "Mulish", sans-serif;
}

a {
	text-decoration: none;
}

.App {
	background-color: #eef0f3;
	position: relative;
	-webkit-tap-highlight-color: transparent;
}

.navbar {
	font-size: 14px;
	height: 60px;
	width: 100%;
	/* border-bottom: 1px solid darkgray; */
	padding: 0 2rem;
	background-color: #fff;
	position: -webkit-sticky;
	position: sticky;
	top: 0;
	z-index: 999;
	box-shadow: 0 1px 3px 0 #dee0e2;
}

.navbar .navScrollContainer {
	display: none;
	flex-direction: row;
	height: 100%;
	width: 100%;
	align-items: center;
}

.navbar .navScrollContainer .fa-bars {
	cursor: pointer;
	color: #000;
	-webkit-tap-highlight-color: transparent;
	margin-right: 5px;
}

.navbar .searchContainer {
	width: 90%;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-left: 5px;
	position: relative;
}

.navbar .searchContainer .input-container {
	width: 90%;
	height: 100%;
	display: flex;
	align-items: center;
	border: 1.5px solid lightgrey;
	/* border-radius: 50px; */
	border-top-left-radius: 20px;
	border-bottom-left-radius: 20px;
	border-right: none;
	padding-left: 2rem;
	background-color: #fff;
	transition: all 0.25s ease-in-out;
}

.navbar .searchContainer.active .input-container {
	border-bottom-left-radius: 0px;
	border-top-left-radius: 10px;
	transition: all 0.25s ease-in-out;
}

.navbar .searchContainer .input-container i {
	width: 15%;
	font-size: 1rem;
	display: flex;
	align-items: center;
	height: 100%;
	justify-content: center;
	color: #fff;
}

.navbar .searchContainer .input-container input {
	width: 85%;
	height: 90%;
	outline: none;
	border: none;
	font-size: 16px;
	background-color: #fff;
	color: #000;
	/* font-weight: 200; */
}

.navbar .searchContainer .input-container input::placeholder {
	color: #000;
}

.navbar .searchContainer button {
	height: 100%;
	width: 15%;
	background-color: #fff;
	border: none;
	border-top-right-radius: 20px;
	border-bottom-right-radius: 20px;
	border: 1.5px solid lightgray;
	border-left: none;
	font-size: 1.2rem;
	color: #000;
	cursor: pointer;
	outline: none;
	transition: all 0.25s ease-in-out;
}

.navbar .searchContainer.active button {
	border-bottom-right-radius: 0px;
	border-top-right-radius: 10px;
	transition: all 0.25s ease-in-out;
}

.navbar .nav-container {
	display: flex !important;
	flex-direction: row;
	height: 100%;
	width: 100%;
	align-items: center;
	justify-content: space-between;
}

.navbar .nav-container .first-container {
	display: flex !important;
	flex-direction: row;
	align-items: center;
	/* justify-content: space-between; */
	height: 100%;
	width: 33%;
	transition: width 0.5s;
}

.navbar .nav-container .first-container-none {
	width: 0;
	transition: width 0.5s;
}

.navbar .nav-container .first-container .fa-bars {
	cursor: pointer;
	color: #000;
	-webkit-tap-highlight-color: transparent;
}

.navbar .nav-container .first-container .logo {
	display: flex;
	align-items: center;
	height: 60%;
	margin-left: 1rem;
	text-decoration: none;
	-webkit-tap-highlight-color: transparent;
}

.navbar .nav-container .first-container .logo img {
	height: 100%;
	object-fit: contain;
}

/* .navbar .nav-container .first-container .logo .logo-title{
    width: 80%;
} */

.navbar .nav-container .second-container {
	/* background-color: white; */
	width: 33%;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	/* overflow: hidden; */
	padding-left: 5px;
	position: relative;
}

.navbar .nav-container .second-container .input-container {
	width: 90%;
	height: 100%;
	display: flex;
	align-items: center;
	border: 1.5px solid lightgrey;
	border-top-left-radius: 20px;
	border-bottom-left-radius: 20px;
	border-right: none;
	padding-left: 2rem;
	background-color: #fff;
	transition: all 0.25s ease-in-out;
}

.navbar .nav-container .second-container.active .input-container {
	border-bottom-left-radius: 0px;
	border-top-left-radius: 10px;
	transition: all 0.25s ease-in-out;
}

.navbar .nav-container .second-container .input-container i {
	width: 15%;
	font-size: 1rem;
	display: flex;
	align-items: center;
	height: 100%;
	justify-content: center;
	color: #fff;
}

.navbar .nav-container .second-container .input-container input {
	width: 85%;
	height: 90%;
	outline: none;
	border: none;
	font-size: 16px;
	background-color: #fff;
	color: #000;
	/* font-weight: 200; */
}

.navbar .nav-container .second-container .input-container input::placeholder {
	color: #000;
}

.navbar .nav-container .second-container button {
	height: 100%;
	width: 15%;
	background-color: #fff;
	border: none;
	border-top-right-radius: 20px;
	border-bottom-right-radius: 20px;
	border: 1.5px solid lightgray;
	border-left: none;
	font-size: 1.2rem;
	color: #000;
	cursor: pointer;
	outline: none;
	transition: all 0.25s ease-in-out;
}

.navbar .nav-container .second-container.active button {
	border-bottom-right-radius: 0px;
	border-top-right-radius: 10px;
	transition: all 0.25s ease-in-out;
}

.navbar .nav-container .third-container {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	font-size: 16px;
	height: 100%;
	width: 33%;
	transition: width 0.5s;
}

.navbar .nav-container .third-container-none {
	width: 0;
	display: none;
	transition: all 0.5s;
}

.navbar .nav-container .third-container .fa-times {
	display: none;
}

.navbar .nav-container .third-container .links {
	display: flex;
	margin: auto 10px;
	text-decoration: none;
	color: #010302;
	padding: 5px;
	transition: transform 0.2s, margin 0.5s;
}

.navbar .nav-container .third-container .search-icon {
	color: #fff;
	display: none;
}

.navbar .nav-container .third-container a i {
	margin: auto 5px;
}

.navbar .nav-container .third-container a p {
	font-weight: 500;
	font-size: 0.9rem;
}
.navbar .nav-container .third-container .links:last-child {
	position: relative;
}
.navbar .nav-container .third-container .links:last-child .cartSize {
	position: absolute;
	top: -12px;
	left: 0px;
	border: 1px solid #fb641b;
	border-radius: 30px;
	width: 20px;
	height: 20px;
	text-align: center;
	color: white;
	background-color: #fb641b;
	font-size: 13px;
}

.navbar .nav-container .third-container a.login-signup {
	color: #fb641b;
	display: flex;
	transition: transform 0.2s;
}

.navbar .nav-container .third-container a.login-signup p {
	font-weight: 600;
}

.navbar .nav-container .third-container .profile {
	text-decoration: none;
	/* display: flex; */
	margin: auto 10px;
	color: #fb641b;
	font-weight: bold;
	position: relative;
	align-items: center;
	flex-direction: column;
	height: 100%;
	/* width: 30%; */
	/* width: 150px; */
}

.navbar .nav-container .third-container .profile .username {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	height: 100%;
	margin: auto 10px;
	position: relative;
}

.navbar .nav-container .third-container .profile .username p {
	font-size: 0.9rem;
	/* width: 90%;
  margin-left: auto; */
}

.navbar .nav-container .third-container .profile .username i {
	font-size: 12px;
	transition: all 0.2s;
}

.navbar .nav-container .third-container .profile:hover .username i {
	transform: rotate(180deg);
}

.navbar .nav-container .third-container .profile .options::after {
	content: "";
	background-color: #fff;
	width: 15px;
	height: 15px;
	border: 1px solid lightgray;
	border-right: none;
	border-bottom: none;
	position: absolute;
	top: -8px;
	left: 45%;
	transform: rotate(45deg);
	z-index: -1;
}

.navbar .nav-container .third-container .profile:hover .options {
	display: flex;
}

.navbar .nav-container .third-container .profile .options {
	display: none;
	flex-direction: column;
	background-color: #fff;
	width: 180px;
	padding: 0.25rem 0;
	position: absolute;
	top: 60px;
	right: 50%;
	transform: translateX(50%);
	align-items: center;
	height: -webkit-fit-content;
	height: -moz-fit-content;
	height: fit-content;
	justify-content: center;
	border: 1px solid lightgrey;
	z-index: 2;
}

.navbar .nav-container .third-container .profile .options .option-links {
	display: flex;
	flex-direction: row;
	align-items: center;
	width: 100%;
	padding-left: 20px;
	text-decoration: none;
	height: 50px;
	color: #fb641b;
	transition: all 0.1s;
}

.navbar .nav-container .third-container .profileMobile i {
	color: #fb641b;
	display: none;
}

.navbar .nav-container .third-container .options .option-links p {
	text-align: center;
	font-size: 14px;
	font-weight: 500;
	padding-left: 30px;
}

.navbar .nav-container .third-container .options .option-links i {
	font-size: 13px;
}

.navbar .nav-container .third-container .options .option-links:hover {
	background-color: #e5e5e5;
	padding-left: 25px;
}

.navbar .nav-container .third-container .options .option-links:hover p {
	font-size: 15px;
}

.navbar .suggestions {
	width: 99%;
	position: absolute;
	top: 20px;
	z-index: 9;
	padding-top: 19px;
	border: 1px solid #ccc;
	border-top-width: 0;
	border-bottom-left-radius: 10px;
	border-bottom-right-radius: 10px;
	overflow: hidden;
}

.navbar .suggestions .suggestions-box {
	width: 100%;
	height: 250px;
	background-color: #fff;
	overflow-y: auto;
	border-top: 1px solid #ccc;
}

.navbar .suggestions .suggestions-box .suggest {
	font-size: 1rem;
	color: #464646;
	padding: 0.7rem 2rem;
	text-transform: capitalize;
	width: 100%;
	cursor: pointer;
}

.navbar .suggestions .suggestions-box .suggest:hover {
	background-color: #ccc;
}

@media (max-width: 1024px) {
	.navbar .nav-container .first-container {
		width: 30%;
	}

	.navbar .nav-container .first-container-none {
		width: 10%;
	}

	.navbar .nav-container .first-container-none .fa-bars {
		cursor: pointer;
		color: #000;
	}

	.navbar .nav-container .first-container-none .logo {
		display: none;
	}

	.navbar .nav-container .second-container {
		width: 30%;
	}

	.navbar .nav-container .third-container {
		width: 40%;
		justify-content: flex-end;
	}

	.navbar .nav-container .third-container-none {
		display: flex;
		width: 5%;
	}

	.navbar .nav-container .third-container-none a {
		display: none;
	}

	.navbar .nav-container .third-container-none .fa-times {
		font-size: 17px;
		margin-left: 5px;
		justify-content: center;
		cursor: pointer;
	}
}

@media (max-width: 768px) {
	.navbar .nav-container .third-container .search-icon {
		display: inline-block;
		color: #000;
		cursor: pointer;
	}
	.navbar {
		padding: 0 10px;
		height: 50px;
	}

	.navbar .suggestions {
		width: 98.5%;
	}

	.navbar .navScrollContainer {
		display: flex;
	}
	.navbar .hide {
		display: none !important;
	}

	.navbar .nav-container .second-container {
		display: none;
	}

	.navbar .nav-container .third-container {
		width: -webkit-fit-content;
		width: -moz-fit-content;
		width: fit-content;
	}

	.navbar .nav-container .third-container a p {
		display: none;
	}

	.navbar .nav-container .first-container {
		width: 80%;
	}

	.navbar .nav-container .third-container .search-icon i {
		margin-right: 0;
	}

	.navbar .nav-container .third-container .profile {
		display: none;
	}
	.navbar .searchResult {
		top: 50px;
		width: 98%;
	}
	#cartId p:first-child {
		display: none;
	}
	.cartSize {
		display: flex !important;
		align-items: center;
		justify-content: center;
	}
	.navbar .nav-container .third-container .profileMobile i {
		display: block;
	}
}

.login-container {
	width: 100%;
	height: 100vh;
	background-color: rgba(0, 0, 0, 0.6);
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 999;
}

.login-container .login {
	width: 360px;
	height: 500px;
	padding: 2rem 1rem;
	background-color: #fff;
	position: relative;
	display: flex;
	flex-direction: row;
	border-radius: 5px;
}

.login-container .login .fa-times {
	position: absolute;
	top: 7px;
	right: 10px;
	font-size: 19px;
	cursor: pointer;
}

.login-container .login .section-1 {
	width: 50%;
}

.login-container .login .section-1 img {
	width: 100%;
	height: 100%;
	border-radius: 5px;
}

/* Register CSS */

.login-container .login .register-form {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-around;
}

.login-container .login .register-form .marfit-img {
	height: 80px;
	width: 60%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
}

.login-container .login .register-form .marfit-img img {
	width: 20%;
}

.login-container .login .register-form .marfit-img img:last-child {
	width: 80%;
}

.login-container .login .register-form .input-fields {
	width: 80%;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
}

.login-container .login .register-form .input-fields input {
	width: 100%;
	height: 45px;
	outline: none;
	border: none;
	padding: 5px 0;
	border-bottom: 2px solid #ef6f0b;
	transition: border-bottom 0.5s;
	font-size: 0.85rem;
}

.login-container .login .register-form .agree {
	width: 80%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 15px;
}

.styled-checkbox {
	cursor: pointer;
}

.login-container .login .register-form .agree p {
	font-size: 12px;
	width: 90%;
}

.login-container .login .register-form .agree p a {
	text-decoration: none;
	font-weight: 500;
	text-transform: lowercase;
	color: #ef6f0b;
}

.login-container .login .register-form .btn-next {
	width: 80%;
	height: 45px;
	font-size: 16px;
	background-color: #fb641b;
	border: none;
	outline: none;
	color: #fff;
	font-weight: bold;
	border-radius: 5px;
	cursor: pointer;
	margin-bottom: 15px;
}

.login-container .login .register-form .lines {
	width: 75%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-around;
}

.login-container .login .register-form .lines .horizontal {
	height: 1px;
	width: 40%;
	background-color: darkgrey;
}

.login-container .login .register-form .lines .or {
	font-size: 12px;
	font-weight: 500px;
	color: grey;
}

.login-container .login .register-form .input-fields .phone-input {
	width: 100%;
	height: 45px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.login-container .login .register-form .input-fields .phone-input .phone-input-code {
	width: 45px;
	height: 45px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-bottom: 2px solid #ef6f0b;
}

.login-container .login .register-form .input-fields .phone-input .phone-input-code p {
	font-size: 0.85rem;
	border-right: 2px solid #ef6f0b;
	padding-right: 0.25rem;
}

.login-container .login .register-form .already-customer p {
	font-size: 12px;
}

.login-container .login .register-form .already-customer p a {
	text-decoration: none;
	font-weight: 500;
	cursor: pointer;
	color: blue;
}

.login-container .login .register-form .go-to-login {
	color: #ef6f0b;
	font-weight: 600;
	cursor: pointer;
	font-size: 0.85rem;
}

/* Log-in CSS */

.login-container .login .login-form {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-around;
}

.login-container .login .login-form .marfit-img {
	height: 80px;
	width: 60%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
}

.login-container .login .login-form .marfit-img img {
	width: 20%;
}

.login-container .login .login-form .marfit-img img:last-child {
	width: 80%;
}

.login-container .login .login-form .input-fields {
	width: 80%;
}

.login-container .login .login-form .input-fields .email-input {
	width: 100%;
	height: 45px;
	padding: 0.25rem;
	border: none;
	border-bottom: 2px solid #ef6f0b;
	outline: none;
}

.login-container .login .login-form .input-fields .email-input:focus {
	border-bottom: 2px solid #ef6f0b;
}

.login-container .login .login-form .input-fields .phone-input {
	width: 100%;
	height: 45px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.login-container .login .login-form .input-fields .phone-input .phone-input-code {
	width: 45px;
	height: 45px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-bottom: 2px solid #ef6f0b;
}

.login-container .login .login-form .input-fields .phone-input .phone-input-code p {
	font-size: 0.85rem;
	border-right: 2px solid #ef6f0b;
	padding-right: 0.25rem;
}

.login-container .login .login-form .input-fields .pass {
	height: 53%;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-end;
}

.login-container .login .login-form .input-fields .pass input[type="password"] {
	width: 100%;
}

.login-container .login .login-form .input-fields input::placeholder {
	color: rgba(0, 0, 0, 0.75);
}

.login-container .login .login-form .input-fields input:focus {
	border-bottom: 1px solid black;
}

.login-container .login .login-form .input-fields .pass p {
	font-size: 11px;
	cursor: pointer;
	color: darkblue;
	margin: 3px 0 7px;
}

.login-container .login .login-form .input-fields input {
	width: 100%;
}

.login-container .login .login-form .btn-next {
	width: 80%;
	height: 45px;
	font-size: 16px;
	background-color: #fb641b;
	border: none;
	outline: none;
	color: #fff;
	font-weight: bold;
	border-radius: 5px;
	cursor: pointer;
	margin-bottom: 10px;
	margin-top: 1rem;
}

.login-container .login .login-form .lines {
	width: 75%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-around;
}

.login-container .login .login-form .lines .horizontal {
	height: 1px;
	width: 40%;
	background-color: darkgrey;
}

.login-container .login .login-form .lines .or {
	font-size: 12px;
	font-weight: 500px;
	color: grey;
}

.login-container .login .login-form .social {
	width: 40%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-around;
	padding: 5px;
	border-radius: 5px;
	-webkit-user-select: none;
	        user-select: none;
	margin: 15px 0;
}

.login-container .login .login-form .social img {
	width: 35px;
	height: 35px;
	cursor: pointer;
}

/* .login-container .login .login-form .social img:last-child {
	width: 45px;
	height: 45px;
} */

.login-container .login .login-form .social p {
	font-size: 12px;
	font-weight: 500;
}

.login-container .login .login-form .already-customer p {
	font-size: 12px;
}

.login-container .login .login-form .already-customer p a {
	text-decoration: none;
	font-weight: 500;
	cursor: pointer;
	color: blue;
}

.login-container .login .login-form .resetPass {
	height: 71%;
	width: 80%;
}

.login-container .login .login-form .resetPass i {
	font-size: 16px;
	cursor: pointer;
}

.login-container .login .login-form .resetPass .sendmail {
	margin-top: 20px;
}

.login-container .login .login-form .resetPass .sendmail input {
	width: 100%;
	height: 40px;
	outline: none;
	border: none;
	padding: 5px 0;
	border-bottom: 1px solid lightgray;
	font-size: 15px;
	transition: border-bottom 0.5s;
}

.login-container .login .login-form .resetPass .sendmail input:focus {
	border-bottom: 1px solid black;
}

.login-container .login .login-form .resetPass .sendmail button {
	height: 40px;
	width: 100%;
	margin-top: 15px;
}

.login-container .login .login-form .go-to-register {
	color: #ef6f0b;
	font-weight: 600;
	cursor: pointer;
	font-size: 0.85rem;
}

.otp-cont .vrf {
	display: flex;
	flex-direction: column;
}

.otp-cont .vrf p {
	color: #a5a5a5;
	margin-bottom: 1rem;
	text-align: center;
}

.otp-cont .verification-cont {
	margin-bottom: 1rem;
	display: flex;
	align-items: center;
	width: 100%;
	display: flex;
	margin: 1rem 0;
}

.otp-cont .verification-cont .code-verification {
	margin: 0.5rem 0;
	display: flex;
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin: 15px 0;
	width: 45px;
	height: 45px;
	margin: 0 0.25rem;
}

.otp-cont .verification-cont .code-verification input {
	width: 100%;
	height: 100%;
	text-align: center;
	background-color: #f4f6f8;
	border: none;
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
	font-size: 25px;
	border: 1px solid lightgray;
	border-radius: 5px;
}

.otp-cont .button-verification {
	display: flex;
	justify-content: space-between;
	width: 82%;
	height: 100%;
	margin-top: 12px;
}

.otp-cont .button-verification .btn-cancel,
.otp-cont .button-verification .btn-register {
	width: 80px;
	background-color: #ef6f0b;
	outline: none;
	border: 1px solid #ef6f0b;
	color: white;
	border-radius: 10px;
	font-weight: bold;
	height: 37px;
	cursor: pointer;
}
@media (max-width: 1024px) {
	.login-container .login {
		width: 65%;
	}
}

@media (max-width: 768px) {
	.login-container .login {
		width: 90%;
	}

	.login-container .login .register-form .social,
	.login-container .login .login-form .social {
		width: 60%;
	}
}

@media (max-width: 468px) {
	.login-container {
		align-items: flex-start;
	}

	.login-container .login {
		margin-top: 1rem;
	}

	.login-container .login .section-1 {
		display: none;
	}

	.login-container .login .register-form,
	.login-container .login .login-form {
		width: 100%;
	}
}

.menu-ham {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: row;
	transform: translateX(-100%);
}

.menu-ham.active {
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 1000;
	transform: translateX(0%);
}

.blank {
	width: 100%;
	height: 100%;
}

.hide {
	display: none !important;
}

.menu-ham .hamburger-menu {
	width: 300px;
	overflow-y: scroll;
	scrollbar-width: none;
	background-color: #fff;
	padding: 0px;
	transform: translateX(-100%);
	transition: transform 0.5s ease-in-out;
}

.menu-ham .hamburger-menu::-webkit-scrollbar {
	width: 0;
	height: 0;
}

.menu-ham .hamburger-menu.active {
	transform: translateX(0%);
	transition: transform 0.5s ease-in-out;
}

.menu-ham .hamburger-menu .head {
	height: 12vh;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	border-bottom: 2px solid grey;
	padding: 7px;
}

.menu-ham .hamburger-menu .head .logo {
	height: 60px;
	width: 60px;
	margin-right: auto;
	display: flex;
	flex-direction: row;
	align-items: center;
}

.menu-ham .hamburger-menu .head .logo .logo-img {
	height: 100%;
	object-fit: contain;
}

.menu-ham .hamburger-menu .head i {
	position: absolute;
	top: 12px;
	right: 12px;
	font-size: 19px;
	cursor: pointer;
}

.menu-ham .hamburger-menu .ham-list {
	/* margin-top: 20px; */
	display: block;
}

.menu-ham .hamburger-menu .ham-list .MiniNav {
	display: flex;
	flex-direction: column;
}

.menu-ham .hamburger-menu .ham-list .MiniNav .MiniNav-category {
	display: flex;
	flex-direction: column;
}

.menu-ham .hamburger-menu .ham-list .MiniNav .MiniNav-category p {
	width: 100%;
	display: flex;
	justify-content: space-between;
}

.menu-ham .hamburger-menu .ham-list .MiniNav .MiniNav-category .subcategory-options {
	width: 100%;
	height: 0px;
	display: none;
	flex-direction: column;
	transition: height 0.5s;
}

.menu-ham .hamburger-menu .ham-list .MiniNav .MiniNav-category.opened .subcategory-options {
	height: -webkit-fit-content;
	height: -moz-fit-content;
	height: fit-content;
	display: flex;
	transition: height 0.5s;
	margin-top: 10px;
}

.menu-ham .hamburger-menu .ham-list .MiniNav .MiniNav-category.opened .subcategory-options a {
	width: 100%;
	padding: 0.5rem 0;
	display: flex;
	padding-left: 10px;
	align-items: center;
	font-size: 15px;
	color: #464646;
	font-weight: 500;
}

.menu-ham .hamburger-menu .ham-list::-webkit-scrollbar {
	width: 0;
	height: 0;
}

.menu-ham .hamburger-menu .ham-list .box,
.menu-ham .hamburger-menu .ham-list #cart,
.menu-ham .hamburger-menu .ham-list #wishlist {
	padding: 1.5rem 15px;
	background-color: #fff;
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	border-bottom: 1px solid lightgray;
	text-decoration: none;
	color: #000;
	transition: height 0.5s;
	-webkit-user-select: none;
	        user-select: none;
}

.menu-ham .hamburger-menu .ham-list p {
	cursor: pointer;
}

.menu-ham .hamburger-menu .ham-list p i {
	transform: rotate(0deg);
	transition: transform 0.2s;
}

.menu-ham .hamburger-menu .ham-list .box:hover,
.menu-ham .hamburger-menu .ham-list #cart:hover,
.menu-ham .hamburger-menu .ham-list #wishlist:hover {
	/* background-color: #f5f5f5; */
}

.menu-ham .hamburger-menu .ham-list .box:active,
.menu-ham .hamburger-menu .ham-list #cart:active,
.menu-ham .hamburger-menu .ham-list #wishlist:active {
	/* background-color: grey; */
}

.menu-ham .hamburger-menu .ham-list .box span,
.menu-ham .hamburger-menu .ham-list .box p {
	text-decoration: none;
	font-size: 16px;
	font-weight: 500;
	color: black;
}

.menu-ham .hamburger-menu .ham-list .box.disappear {
	display: none !important;
}

.open-arrow {
	transform: rotate(180deg) !important;
	transition: transform 0.2s !important;
}

.menu-ham .hamburger-menu .ham-list #cart {
	display: none;
}

.hamburger-menu .ham-list #wishlist {
	display: none;
}

.menu-ham .hamburger-menu .ham-list .orange span,
.menu-ham .hamburger-menu .ham-list .orange p,
.menu-ham .hamburger-menu .ham-list .orange i {
	color: #fb641b;
}

@media (max-width: 1024px) {
	.menu-ham .hamburger-menu {
		width: 500px;
	}
}

@media (max-width: 680px) {
	.menu-ham .hamburger-menu {
		width: 600px;
	}

	.hide {
		display: flex !important;
	}

	.menu-ham .hamburger-menu .ham-list .box:hover,
	.menu-ham .hamburger-menu .ham-list #cart:hover,
	.menu-ham .hamburger-menu .ham-list #wishlist:hover {
		background-color: #fff;
	}

	.menu-ham .hamburger-menu .ham-list .box:active,
	.menu-ham .hamburger-menu .ham-list #cart:active,
	.menu-ham .hamburger-menu .ham-list #wishlist:active {
		background-color: #fff;
	}
}

@media (max-width: 568px) {
	.menu-ham .hamburger-menu .ham-list #cart {
		display: flex;
	}
	.hamburger-menu .ham-list #wishlist {
		display: flex;
	}
	.menu-ham .hamburger-menu .ham-list .box.disappear {
		display: flex !important;
	}
}

.loader {
	width: 100%;
	height: 100vh;
	position: fixed;
	top: 0;
	right: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #fff;
	z-index: 9999;
}

.loader .logo-box {
	width: 80px;
	height: 80px;
	border: 3px solid #000;
	border-radius: 50%;
	padding: 1rem;
}

.loader .logo-box img {
	width: 100%;
	object-fit: contain;
	animation: breath 2s infinite;
}

@keyframes breath {
	0%,
	100% {
		transform: scale(0.5);
	}
	50% {
		transform: scale(1);
	}
}

.mini-container {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	box-shadow: 0 3px 6px 0 #dee0e2;
	background-color: #fff;
	align-items: center;
	position: -webkit-sticky;
	position: sticky;
	top: 60px;
	z-index: 998;
}

.mini-container .mini-container-main {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 40px;
}

.mini-container .mini-content {
	font-size: 16px;
	color: black;
	font-weight: bold;
	text-decoration: none;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 100%;
	cursor: pointer;
	margin: 0 20px;
	position: relative;
}

.mini-container .mini-content .mini-content-context {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.mini-container .mini-content a {
	font-size: 16px;
	color: black;
	font-weight: bold;
	text-decoration: none;
}

.mini-container .mini-content i {
	margin-left: 5px;
}

.mini-container .mini-content .fa-chevron-down {
	transition: transform 0.2s;
	font-size: 10px;
}

.mini-container .mini-content:hover .fa-chevron-down {
	transform: rotate(180deg);
}

.mini-container .mini-content .category-options {
	background-color: #fff;
	position: absolute;
	display: none;
	flex-direction: column;
	align-items: center;
	top: 40px;
	left: 50%;
	transform: translateX(-55%);
	padding: 0.2rem 0;
	border: 1px solid lightgray;
	width: 180px;
	z-index: 100;
}

.mini-container .mini-content .category-options::after {
	content: "";
	width: 8px;
	height: 8px;
	background-color: #fff;
	transform: rotate(45deg);
	position: absolute;
	top: -5px;
	left: 50%;
	border: 1px solid lightgray;
	border-right: none;
	border-bottom: none;
	z-index: -1;
}

.mini-container .mini-content:hover .category-options {
	display: flex;
}

.mini-container .mini-content .category-options a {
	width: 100%;
	padding: 8px 15px;
	font-weight: 500;
	display: flex;
	align-items: center;
}

.mini-container .mini-content .category-options p {
	color: #000;
	font-size: 16px;
	font-weight: 500;
	margin: 0 0 0 10px;
}

.mini-container .mini-content .category-options a:hover {
	color: #fb641b;
	background-color: #e5e5e5;
	/* font-weight: bold; */
}

.mini-container .new {
	color: #fb641b;
	font-size: 16px;
	font-weight: bold;
	text-decoration: none;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	margin: 0 15px;
	cursor: pointer;
	white-space: nowrap;
}

.mini-container .sale {
	color: #fb641b;
	font-size: 16px;
	font-weight: bold;
	text-decoration: none;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 80%;
	margin: 0 15px;
	cursor: pointer;
}

@media only screen and (max-width: 868px) {
	.mini-container {
		display: none;
	}
}

.panel-container {
	width: 100%;
	height: 400px;
	display: flex;
	justify-content: center;
	align-items: center;
	-webkit-user-select: none;
	        user-select: none;
	margin-top: 0.25rem;
	padding-top: 0.5rem;
}

.panel {
	width: 98%;
	max-width: 1700px;
	height: 100%;
	cursor: pointer;
	overflow: hidden;
	box-shadow: 0 2px 6px 0 #dee0e2;
	border-radius: 3px;
	overflow: hidden;
}

.panel .swiper-container {
	height: 100%;
}

.panel .swiper-container .swiper-pagination-bullet-active {
	background: #f35429;
}

.panel .swiper-container .cont {
	width: 100%;
	height: 100%;
}

.panel .swiper-container .cont img {
	width: 100%;
	height: 100%;
	object-fit: contain;
}

@media only screen and (max-width: 1400px) {
	.panel-container {
		height: 350px;
	}
}

@media only screen and (max-width: 1024px) {
	.panel-container {
		height: 270px;
	}
}

@media only screen and (max-width: 768px) {
	.panel-container {
		height: 200px;
	}
}

@media only screen and (max-width: 468px) {
	.panel-container {
		height: 110px;
	}
}

.poster {
	width: 98;
	height: 550px;
	background-color: #fff;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 2rem 0;
	max-width: 1700px;
	margin: 1rem auto;
}

.poster .image {
	display: flex;
	flex-direction: row;
	position: relative;
	width: 100%;
	height: 100%;
}

.poster .image img {
	width: 100%;
	height: 100%;
	object-fit: contain;
}

.poster .image .title img {
	height: 35%;
	width: 75%;
}

.poster .image .title p {
	font-size: 0.6rem;
	font-weight: 500;
}

.poster .image a {
	background-color: #fb641b;
	position: absolute;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	bottom: 20%;
	right: 30%;
	width: 250px;
	height: 50px;
	font-size: 14px;
	text-decoration: none;
	color: white;
	border-radius: 3px;
	/* padding: 0 40px 0 20px; */
}

.poster .image a .fa-arrow-right {
	font-size: 25px;
	margin-right: 10px;
	/* margin: 0 1rem; */
}

@media (max-width: 768px) {
	.poster {
		height: 380px;
		width: 100%;
	}

	.poster .image a {
		height: 40px;
		width: 150px;
		/* padding-right: 0; */
		font-size: 12px;
	}
}

@media (max-width: 680px) {
	.poster {
		height: 280px;
		padding-right: 10px;
	}

	.poster .image {
		width: 90%;
	}

	.poster .image a {
		height: 40px;
		width: 180px;
		/* padding-right: 0; */
		font-size: 14px;
	}

	.poster .image .title p {
		font-size: 0.8rem;
	}

	.poster .image a .fa-arrow-right {
		font-size: 17px;
		/* margin-right: 5px; */
	}
}

@media (max-width: 360px) {
	.poster .image a {
		/* font-size: 7px; */
		overflow: hidden;
	}

	.poster .image a .fa-arrow-right {
		font-size: 12px;
	}
}

.card {
	margin-top: 10px;
	background-color: #fff;
	padding: 10px 15px;
	padding-bottom: 0;
	box-shadow: 2px 2px 6px darkgray;
	/* box-shadow: 0 2px 6px 0 #dee0e2; */
	box-shadow: 0 2px 6px 0 #dee0e2;
	margin: 1rem auto;
	border-radius: 3px;
	max-width: 1700px;
	width: 98%;
}

.card .card-title {
	height: 20%;
	width: 100%;
	display: flex;
	padding-top: 0%;
	justify-content: space-between;
	align-items: center;
	padding-bottom: 1rem;
}

.card .card-title .card-title-mini p {
	font-weight: 600;
	color: rgba(0, 0, 0, 0.8);
	font-size: 21px;
}

.card .card-title .card-title-mini .lines {
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-top: 7px;
}

.card .card-title .card-title-mini .lines .line-through {
	width: 16px;
	height: 1px;
	background-color: rgba(0, 0, 0, 0.8);
	margin: 3px 0;
}

.card .card-title .card-title-mini .lines .rotated-line {
	transform: rotate(-45deg);
	display: inline-block;
	margin: 0 5px;
}

.card .card-title .card-title-mini .lines .horizontal {
	width: 60px;
	height: 1px;
	background-color: rgba(0, 0, 0, 0.6);
	/* display: inline-block; */
}

.card .card-title a {
	height: 40px;
	width: 90px;
	background-color: #fb641b;
	font-size: 14px;
	border-radius: 3px;
	color: white;
	text-decoration: none;
	text-align: center;
	padding: 4px;
	font-weight: 400;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
}

.swipe {
	overflow: hidden;
	visibility: hidden;
	position: relative;
}
.swipe-wrap {
	overflow: hidden;
	position: relative;
}

.card .slider {
	/* height: 80%; */
}

.card .slider .swiper-container {
	width: 100%;
}

.card .slider .swiper-container .swiper-wrapper {
	width: 100%;
	margin-bottom: 1rem;
}

.card .slider .swiper-container .swiper-wrapper .swiper-slide {
	height: 300px;
	width: 240px;
	margin-right: 1rem;
}

.swiper-button-next {
	color: black !important;
	height: 40px !important;
	width: 40px !important;
	border-radius: 50%;
	background-color: #f6f6f6;
	box-shadow: 0 0 10px 0 gray;
}

.swiper-button-next::after {
	font-size: 24px !important;
	transform: translate(25%, 0);
}

.swiper-button-prev {
	color: black !important;
	height: 40px !important;
	width: 40px !important;
	border-radius: 50%;
	background-color: #f6f6f6;
	box-shadow: 0 0 10px 0 gray;
}

.swiper-button-prev::after {
	font-size: 24px !important;
	transform: translate(-15%, 0);
}

@media (max-width: 768px) {
	.card .card-title {
		padding: 10px 10px;
	}

	.card .card-title .card-title-mini p {
		font-size: 16px;
	}

	.card .card-title a {
		/* width: 10%;
        height: 100%; */
		font-size: 16px !important;
	}

	.card .card-title .card-title-mini .lines .line-through {
		width: 12px;
		margin: 1px 0;
	}

	.card .card-title .card-title-mini .lines .horizontal {
		width: 25px;
	}

	.card .slider .swiper-container .swiper-wrapper .swiper-slide {
		height: auto;
	}

	.swiper-button-next {
		display: none !important;
	}

	.swiper-button-prev {
		display: none !important;
	}
}

@media (max-width: 468px) {
	.card .card-title a {
		font-size: 6px;
	}
}

.card-cont {
	height: 300px;
	width: 240px;
	border-top: 1px solid rgba(0, 0, 0, 0.03);
	border-left: 1px solid rgba(0, 0, 0, 0.1);
	box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.2);
	position: relative;
	background-color: white;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-bottom: 7px;
	cursor: pointer;
	margin: 0 1rem 1rem 0rem;
	text-decoration: none;
	color: black;
}

.card-cont.grey {
	position: relative;
}

.card-cont.grey::after {
	content: "";
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
}

.card-cont.grey .outOfStock {
	font-size: 1rem;
	font-weight: 600;
	color: red;
	position: absolute;
	top: 50%;
	left: 50%;
	width: 100%;
	text-align: center;
	transform: translate(-50%, -50%);
	z-index: 99;
}

.card-cont .circle {
	width: 25px;
	height: 25px;
	border-radius: 50%;
	box-shadow: 1px 1px 3px lightgray;
	position: absolute;
	top: 10px;
	right: 10px;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #fff;
	z-index: 99;
}

.card-cont .circle i {
	color: lightgray;
}

.card-cont .img-container {
	width: 90%;
	height: 160px;
	margin-top: 10px;
}

.card-cont .img-container img {
	width: 100%;
	height: 100%;
	object-fit: contain;
}

.card-cont .short-description {
	display: flex;
	flex-direction: column;
	margin-top: 14px;
	justify-content: space-around;
	text-decoration: none;
	color: #000;
	width: 80%;
}

.card-cont .short-description .item-title {
	font-weight: 400;
	font-size: 14px;
	margin-bottom: 7px;
}

.card-cont .short-description .price-flex {
	display: flex;
	flex-direction: row;
}

.card-cont .short-description .item-price {
	font-size: 14px;
	margin-bottom: 7px;
}

.card-cont .short-description .price-flex .price-line-through {
	text-decoration: line-through;
	color: grey;
	margin-right: 10px;
	font-size: 13px;
}

.card-cont .short-description .price-flex .discount {
	font-weight: bold;
	color: #fb6b25;
	font-size: 13px;
}

.card-cont .circle .red {
	color: red;
}

@media (max-width: 768px) {
	.card-cont {
		height: 340px;
		width: 210px;
	}
}

@media only screen and (max-width: 580px) {
	.card-cont {
		width: 45% !important;
		margin-right: 0.6rem !important;
	}
	.swiper-slide .card-cont {
		width: 95% !important;
		margin-right: 0.6rem !important;
		margin-bottom: 0 !important;
	}
}

.container {
	width: 100%;
	display: flex;
	align-items: center;
	flex-direction: column;
}
.container-main {
	width: 90%;
	display: flex;
	justify-content: center;
	position: relative;
	background-color: #fff;
}
.content {
	display: flex;
	width: 50%;
	flex-wrap: wrap !important;
}
.wrap {
	width: 100%;
}
.content .wrap .main {
	display: flex;
	flex-wrap: wrap !important;
	background-color: #fff;
}
.container-main .content .wrap .main main .head p {
	color: #fb641b;
	font-size: 32px;
	font-weight: 500;
	margin: 0;
	margin-top: 1rem;
}
.content .wrap .main main {
	width: 80%;
	/* padding: 30px 0; */
	margin: 0 auto;
}
.content .wrap .main main h2 {
	color: #333333;
	font-size: 18px;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
	font-weight: normal;
	/* display: inline-block; */
	margin-left: 15px;
}
.content .wrap .main main .Title .title {
	margin: 0px;
}
.content .wrap .main main .Title .text {
	margin: 0px;
	margin-top: 8px;
}
.text_area_a {
	min-width: 70px;
}
.text_area_a p {
	width: auto !important;
	margin-left: 0 !important;
}
.text_area_a,
.text_area_b h3 {
	margin-left: 15px;
}
.content .wrap .main main p {
	color: #545454;
	font-size: 14px;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
	/* font-weight: normal; */
	/* display: inline-block; */
	margin-left: 15px;
	width: 92%;
}
.content .wrap .main main p.need {
	width: 50%;
	margin: 0;
}
.section_content {
	margin-top: 30px;
	display: inline-block;
	width: 100%;
}

.icon {
	height: 80px;
}
.icon div {
	width: 40px;
	height: 100%;
	padding: 0.8rem 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
}
.icon .title,
.icon i {
	color: #777;
	white-space: nowrap;
}
.icon i {
	font-size: 12px;
	width: 27px;
	height: 27px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	border: 1px solid #c5c5c5;
	z-index: 1;
	background-color: #fff;
}

.icon i::after {
	transform-origin: left;
	transform: scaleX(0);
	transition: transform 0.2s ease;
}

.icon .done:last-child i {
	width: 30px;
	height: 30px;
	font-size: 16px;
}

.icon .done:first-child i::after {
	width: 0;
	height: 0;
}

.icon .done:last-child i::after {
	bottom: 15px;
}
.icon .done i::after {
	content: "";
	width: 92px;
	height: 3px;
	position: absolute;
	right: 27px;
	bottom: 12px;
	background-color: #179b4d;
	transform: scaleX(1);
	transition: transform 0.2s ease;
	z-index: 0;
}
.icon .done i {
	background-color: #179b4d;
	color: #fff;
	position: relative;
	font-size: 12px;
	border: none;
}
.icon .done .title {
	font-size: 14px;
	color: #464646;
}

.status {
	border: 1px solid #d9d9d9;
	border-radius: 5px;
	margin-bottom: 15px;
}
.status .icon_text {
	padding: 0.5rem;
}
.status .icon_text h2 {
	font-size: 16px !important;
	font-weight: 500 !important;
}
.content .wrap .main main .section_content .order_update {
	border: 1px solid #d9d9d9;
	border-radius: 5px;
	margin-top: 12px;
	padding: 0.5rem;
}
.content .wrap .main main .section_content .order_update h2 {
	font-weight: 500 !important;
}
.content .wrap .main main .section_content .message {
	border: 1px solid #d9d9d9;
	border-radius: 5px;
	margin-top: 12px;
	padding: 0.5rem;
}

.customer_information {
	border: 1px solid #d9d9d9;
	border-radius: 5px;
	margin-top: 12px;
	padding: 0.5rem 0;
	width: 100%;
}
.customer_information h2 {
	font-weight: 500 !important;
	margin-bottom: 10px;
}

.content_information {
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	justify-content: space-around;
}

.customer_information .content_information .text_area_a,
.customer_information .content_information .text_area_b,
.customer_information .content_information .text_area_c {
	width: 30%;
	margin: 0;
	padding: 0;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.customer_information .content_information .text_area_a h3,
.customer_information .content_information .text_area_b h3,
.customer_information .content_information .text_area_c h3 {
	font-size: 14px;
	color: #333333;
	word-wrap: break-word;
	text-align: center;
}

.customer_information .content_information .text_area_a p,
.customer_information .content_information .text_area_b p,
.customer_information .content_information .text_area_c p {
	font-size: 12px;
	color: #333333;
	word-wrap: break-word;
	text-align: center;
}

.step_footer span {
	text-decoration: none;
	color: #fb641b;
	font-size: 14px;
	cursor: pointer;
}

.step_footer {
	display: flex;
	width: 100%;
	flex-wrap: wrap !important;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding: 1rem 0;
}

.step_footer .replacement {
	display: flex;
	flex-direction: column;
}

.step_footer .replacement .need {
	display: flex;
}

.step_footer .replacement .replacement-section {
	display: flex;
	align-items: center;
}

.step_footer button {
	color: #ffffff;
	border: 1px solid #d9d9d9;
	border-radius: 5px;
	cursor: pointer;
	background-color: #fb641b;
	border: 1px transparent solid;
	border-radius: 5px;
	font-weight: 500;
	font-size: 14px;
	width: 100px;
	height: 40px;
	outline: none;
	margin-left: 0.5rem;
}

.step_footer button:hover {
	background-color: #fb641b;
}
.step_footer .need {
	margin-top: 10px;
}
.icon img {
	height: 15px;
	width: 15px;
	/* margin-left: 32px; */
	align-self: center;
}
.icon {
	display: flex;
	justify-content: space-around;
	justify-content: space-evenly;
}
.icon span {
	/* color: #45b5aa; */
	font-size: 14px;
	color: #333333;
	/* font-size: 18px; */
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
	font-weight: normal;
}

.container-main .sidebar {
	width: 50%;
	border-right: none;
	border-top: none;
	padding: 0 2rem;
	padding-top: 2rem;
}

.order_update h2 {
	margin-bottom: 10px;
}

.order_update .order-status {
	width: 90%;
	display: flex;
	flex-direction: column;
	margin: 0 auto;
}

.order_update .order-status .activity:not(:last-child) {
	border-bottom: 1px solid lightgray;
	margin-bottom: 10px;
	padding-bottom: 10px;
	padding-left: 10px;
}

.order_update .order-status .activity {
	padding-left: 10px;
}

.order_update .order-status .activity h5,
.order_update .order-status .activity p {
	margin-bottom: 5px;
}

.order_update .noUpdates {
	margin: 10px 0 20px;
	font-size: 16px;
}

.sidebar .items-container {
	width: 100%;
	margin: 1rem auto;
}

.sidebar .items-container .items {
	margin-left: 0;
	margin-top: 0;
	margin-bottom: 20px;
}

.sidebar .discount-sec {
	padding: 20px 0;
	width: 90%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	margin: 0 auto;
	border-top: 2px solid #ccc;
	border-bottom: 2px solid #ccc;
}

.sidebar .discount-sec input {
	height: 40px;
	width: 75%;
	border: 1px gray solid;
	outline: none;
	border-radius: 5px;
	padding: 5px 10px;
	font-size: 12px;
}

.sidebar .discount-sec button {
	height: 40px;
	width: 23%;
	border-radius: 5px;
	background-color: #9f9f9f;
	color: white;
	font-size: 12px;
	outline: none;
	border: 1px solid #9f9f9f;
	cursor: pointer;
}

.sidebar .order-details {
	display: flex;
	flex-direction: column;
	width: 100%;
	border-bottom: 2px solid #ccc;
	align-items: center;
	justify-content: space-between;
	margin-top: 2rem;
	padding: 1rem;
}

.sidebar .order-details .sub,
.sidebar .order-details .shipping-sub,
.sidebar .order-details .points-sub,
.sidebar .order-details .discount-sub {
	display: flex;
	flex-direction: row;
	width: 100%;
	justify-content: space-between;
	align-items: center;
	font-size: 14px;
	font-weight: 600;
	margin: 0.2rem 0;
}

.sidebar .order-details .discount-sub {
	color: green;
}

.sidebar .order-details .points-sub {
	color: goldenrod;
}

.sidebar .order-details .sub p,
.sidebar .order-details .shipping-sub p,
.sidebar .order-details .points-sub p,
.sidebar .order-details .discount-sub p {
	font-weight: bold;
}

.sidebar .order-details .sub .sub-title,
.sidebar .order-details .shipping-sub .sub-title {
	color: #555;
}

.sidebar .total {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 90%;
	align-items: center;
	margin: 15px auto;
	font-weight: bold;
	font-size: 18px;
	color: #fb641b;
}

.sidebar .invoice-button {
	width: 120px;
	background-color: #fb641b;
	height: 35px;
	border-radius: 3px;
	color: #fff;
	margin: 15px 0;
	border: none;
	cursor: pointer;
	outline: none;
}

.sidebar .total p:first-child {
	font-size: 14px;
	color: #000;
}

.step_footer p {
	display: inline-block !important;
}
.rating-cont {
	position: fixed;
	top: 0vh;
	right: 0;
	width: 100%;
	height: 100vh;
	background-color: rgba(0, 0, 0, 0.6);
	z-index: 999;
	display: flex;
	align-items: center;
	justify-content: center;
}
.rating-cont .rating-modal {
	padding: 0.5rem 1.4rem 1rem;
	display: flex;
	flex-direction: column;
	background-color: #fff;
	margin: auto;
	position: absolute;
	width: 40%;
	max-width: 500px;
	width: 90%;
	max-height: 90vh;
	overflow-y: scroll;
	border-radius: 10px;
}
.rating-cont .rating-modal .modal-head {
	height: 50px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-bottom: 1px solid #ccc;
	margin-bottom: 1rem;
}
.rating-cont .rating-modal .modal-head i {
	font-size: 18px;
	cursor: pointer;
}

.rating-cont .rating-modal .modal-body .selected-product {
	width: 100%;
	position: relative;
	cursor: pointer;
}

.rating-cont .rating-modal .modal-body .selected-product .selected-product-overlay {
	display: none;
}

.rating-cont .rating-modal .modal-body .selected-product.active .selected-product-overlay {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	background-color: rgba(251, 100, 27, 0.25);
	border-radius: 5px;
	display: flex;
}

.rating-cont .rating-modal .modal-body .selected-product .selected-product-overlay i {
	position: absolute;
	right: 10px;
	top: 10px;
	font-size: 1.25rem;
	color: #179b4d;
}

.rating-cont .rating-modal .modal-body .return-next {
	width: 100px;
	height: 35px;
	background-color: #fb641b;
	border: none;
	border-radius: 5px;
	color: #fff;
	font-weight: 600;
	cursor: pointer;
	outline: none;
}

.rating-cont .rating-modal .modal-body .return-next.done {
	background-color: #179b4d;
}

.rating-cont .rating-modal .modal-body .rating {
	margin-top: 23px;
	margin-bottom: 17px;
}

.rating-cont .rating-modal .modal-body .rating .stars {
	/* width: 230px; */
	display: flex;
	flex-direction: row;
	align-items: center;
}

.rating-cont .rating-modal .modal-body .rating .stars i {
	font-size: 20px;
	margin-right: 15px;
}

.rating-cont .rating-modal .modal-body .rating .stars p {
	font-weight: 500;
	margin-right: 30px;
	font-size: 15px;
}

.rating-cont .rating-modal .modal-body .rating .stars .fa-star {
	color: #fb641b;
	cursor: pointer;
}

.rating-cont .rating-modal .modal-body .rating .review {
	width: 100%;
	display: flex;
	align-items: flex-start;
	margin-top: 15px;
}
.rating-cont .rating-modal .modal-body .rating .review label {
	font-weight: 500;
	font-size: 15px;
	margin-right: 20px;
}
.rating-cont .rating-modal .modal-body .rating .review textarea {
	padding: 0.3rem 0.5rem;
	border: 1px solid lightgray;
	outline: none;
	border-radius: 5px;
	resize: none;
	height: 100px;
	transition: border 0.3s ease;
}
.rating-cont .rating-modal .modal-body .rating .review textarea:hover,
.rating-cont .rating-modal .modal-body .rating .review textarea:focus {
	border: 1px solid #fb641b;
	transition: border 0.3s ease;
}

.rating-cont .rating-modal .modal-footer {
	padding: 0rem 1rem 0;
	width: 100%;
	display: flex;
	justify-content: flex-end;
	align-items: center;
}

.rating-cont .rating-modal .modal-footer button {
	padding: 0.5rem 1rem;
	color: #fff;
	background-color: #fb641b;
	font-weight: 500;
	border: 1px solid #fb641b;
	border-radius: 5px;
	outline: none;
	cursor: pointer;
	font-size: 15px;
}

.dropdown {
	margin: 1rem 0;
	width: 100%;
}

.dropdown select {
	width: 100%;
	display: flex;
	justify-content: flex-start;
	height: 40px;
	align-items: center;
	padding-left: 15px;
	font-size: 15px;
	border-radius: 5px;
	border: 1px solid grey;
	outline: none;
	background-color: #f1f1f1;
}

.replace {
	padding: 0.5rem 1rem;
	color: #fff;
	background-color: #fb641b;
	font-weight: 500;
	border: 1px solid #fb641b;
	border-radius: 5px;
	outline: none;
	cursor: pointer;
	font-size: 15px;
}

.dropdown-content {
	display: none;
	position: absolute;
	background-color: #f9f9f9;
	box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
	z-index: 1;
	overflow-y: scroll;
	width: 90%;
	height: 40vh;
}

.dropdown-content option {
	color: black;
	padding: 12px 16px;
	display: block;
}

.dropdown:hover .dropdown-content {
	display: block;
}

@media (max-width: 1024px) {
	.content {
		width: 100%;
	}
	.content .wrap .main main {
		width: 70%;
	}
	.container-main {
		flex-direction: column;
	}
	.container-main .sidebar {
		width: 100%;
	}
	.icon .done i::after {
		width: 110px;
	}
}

@media (max-width: 768px) {
	.container-main {
		height: auto;
		width: 100%;
	}

	.icon .done i::after {
		width: 80px;
	}
	/* .message p {
    margin: 0 !important;
    width: auto;
  } */
}

@media (max-width: 680px) {
	.content .wrap .main main {
		padding: 50px 0;
		width: 90%;
	}

	.icon .done i::after {
		width: 48px;
	}
	.content_information {
		flex-direction: column;
	}
	.text_area_a,
	.text_area_b,
	.text_area_c {
		margin: 0.5rem 0 !important;
		width: 100% !important;
	}
	.text_area_b h3 {
		margin-left: 0;
	}
	.customer_information {
		padding: 0.5rem;
	}
	.content .wrap .main main .login_form .a,
	.content .wrap .main main .login_form .b {
		width: 93%;
		margin-bottom: 10px;
	}
	.content .wrap .main main .login_form .b {
		margin-left: 0;
	}
	.content .wrap .main main .login_form button {
		width: 100%;
		margin-left: 0;
	}
	.step_footer {
		flex-direction: column-reverse;
	}
	.content .wrap .main main p.need,
	.step_footer button {
		width: 100%;
	}
	.content .wrap .main main p.need {
		text-align: center;
	}
	.content .wrap .main main .login_form button,
	.step_footer button {
		font-size: 18px;
	}
}

.order-card {
	width: 98%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 10px;
	border: 1px solid #e2eaf5;
	border-radius: 5px;
	background-color: #fff;
	padding: 1rem;
	cursor: pointer;
	flex-wrap: wrap;
}

.order-card .product-section1 {
	display: flex;
	height: 100%;
	width: 40%;
}

.order-card .product-section1 .product-image {
	width: 100px;
}

.order-card .product-section1 .product-image img {
	width: 100%;
	object-fit: contain;
}

.order-card .product-section1 .product-info {
	display: flex;
	flex-direction: column;
	margin-left: 1rem;
	height: 100%;
}

.order-card .product-section1 .product-info .product-title {
	font-size: 0.9rem;
}

.order-card .product-section1 .product-info .product-color {
	color: #a5a5a5;
	font-size: 0.8rem;
	margin-top: 0.25rem;
}

.order-card .product-section1 .product-info .product-quantity {
	color: #a5a5a5;
	font-size: 0.8rem;
}

.order-card .product-section1 .product-info .product-size {
	color: #a5a5a5;
	font-size: 0.8rem;
}

.order-card .product-section1 .product-info .product-size span {
	text-transform: uppercase;
}

.order-card .product-section2 {
	display: flex;
	height: 100%;
	flex-direction: column;
	width: 20%;
	align-items: center;
}

.order-card .product-section2 p {
	font-size: 0.9rem;
	font-weight: 600;
}

.order-card .product-section3 {
	display: flex;
	flex-direction: column;
	height: 100%;
	width: 40%;
	align-items: flex-end;
}

.order-card .product-section3 .status-info {
	display: flex;
	align-items: center;
}

.order-card .product-section3 .status-info .status-indicator {
	width: 10px;
	height: 10px;
	border-radius: 50%;
}

.order-card .product-section3 .status-info .status-indicator.green {
	background-color: #26a541;
}

.order-card .product-section3 .status-info .status-indicator.yellow {
	background-color: #ff9f00;
}

.order-card .product-section3 .status-info .status-indicator.red {
	background-color: #ff6161;
}

.order-card .product-section3 .status-info p {
	font-size: 0.9rem;
	margin-left: 0.25rem;
}

.order-card .product-section3 .product-status-message {
	font-size: 0.8rem;
	margin-top: 0.5rem;
}

.order-card .product-section3 .product-rating {
	display: flex;
	align-content: center;
	margin-top: 0.25rem;
}

.order-card .product-section3 .product-rating i {
	color: #fb641b;
	margin: 2px;
	font-size: 20px;
}

.order-card .product-section3 .product-rating i.active {
	color: #fb641b;
}

@media (max-width: 680px) {
	.order-card {
		flex-direction: column;
	}

	.order-card .product-section1,
	.order-card .product-section2,
	.order-card .product-section3 {
		width: 100%;
		align-items: start;
		margin: 0.25rem 0;
	}
}

.prod-card2 {
	width: 100%;
	height: 160px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 20px;
	border: 1px solid #e2eaf5;
	border-radius: 5px;
	background-color: #fff;
	padding: 2rem;
}

.card-content {
	width: 100%;
	height: 100%;
	display: flex;
}

.prod-card2 .card-content .left-img {
	width: 20%;
	height: 100%;
}

.prod-card2 .card-content .left-img img {
	width: 100%;
	max-height: 120px;
	object-fit: contain;
}

.prod-card2 .card-content .right-details {
	width: 80%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	justify-content: space-between;
}

.prod-card2 .card-content .right-details .item-name {
	width: 95%;
	display: flex;
	justify-content: space-between;
}

.prod-card2 .card-content .right-details .item-name p {
	color: #313131;
}

.prod-card2 .card-content .right-details .item-name .delete {
	display: flex;
	align-items: center;
	cursor: pointer;
}

.prod-card2 .card-content .right-details .item-name .delete img {
	width: 20px;
}

.prod-card2 .card-content .right-details .item-name .delete p {
	font-size: 12px;
	color: red;
}

.prod-card2 .card-content .right-details .item-price-time {
	width: 95%;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.prod-card2 .card-content .right-details .item-price-time .tenure {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.prod-card2 .card-content .right-details .item-price-time .tenure p {
	font-size: 14px;
	color: #464646;
	display: flex;
	align-items: center;
}

.prod-card2 .card-content .right-details .item-price-time .tenure p .qbtn {
	width: 30px;
	height: 30px;
	border: 2px solid #fb6b25;
	border-radius: 50%;
	margin: 0 5px;
	display: flex;
	align-items: center;
	justify-content: center;
	outline: none;
	cursor: pointer;
}

.prod-card2 .card-content .right-details .item-price-time .tenure p .qbtn p {
	font-size: 1.2rem;
	transform: translate(0.5px, -1px);
}

.prod-card2 .card-content .right-details .item-price-time .tenure span {
	font-size: 12px;
	font-weight: 500;
	color: #acadaa;
}

.prod-card2 .card-content .right-details .item-delivery {
	width: 95%;
	display: flex;
	align-items: center;
	justify-content: fl;
	border: 1px solid #fb6b25;
	padding: 0.3rem 0.5rem;
	border-radius: 5px;
}

.prod-card2 .card-content .right-details .item-delivery img {
	width: 20px;
	margin-right: 10px;
}

.prod-card2 .card-content .right-details .item-delivery p {
	font-size: 13px;
	color: #515151;
	margin-right: 5px;
}

.prod-card2 .card-content .right-details .item-delivery i {
	font-size: 6px;
	border-radius: 50%;
	border: 0.65px solid #fb6b25;
	color: #fb6b25;
	width: 12px;
	height: 12px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0 10px;
	cursor: pointer;
}

@media screen and (max-width: 680px) {
	.prod-card2 .card-content .right-details .item-price-time .tenure:nth-child(3) {
		display: none;
	}
}

.add {
  text-decoration: none;
  padding: 0;
  margin: 1rem auto;
  border-radius: 3px;
  max-width: 1700px;
  width: 98%;
}

.add img {
  width: 100%;
  object-fit: contain;
}

/* .add .add-button{
    background-color: white;
    color: black;
    position: absolute;
    width: 200px;
    height: 50px;
    bottom: 36%;
    right: 19%;
    font-size: 20px;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
}

@media (max-width: 525px){
    .add{
        height: 35vh;
    }

    .add img{
        object-position: right
    }

    .add .add-button{
        width: 120px; 
        height: 40px;
    }
} */

.about {
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 98%;
  height: 500px;
  max-width: 1700px;
  margin: 1rem auto;
}

.about img {
  width: 33%;
  height: 100%;
  object-fit: cover;
}

.about .white-container {
  background-color: #000;
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 34%;
}

.about .white-container img {
  width: 150px;
  height: 30%;
  object-fit: contain;
  margin-bottom: 15px;
}

.about .white-container p {
  width: 75%;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  color: #fff;
}

@media (max-width: 768px) {
  .about {
    height: 400px;
  }
}

@media (max-width: 680px) {
  .about {
    flex-direction: column;
    height: auto;
  }

  .about img {
    width: 100%;
    height: 33%;
  }

  .about .white-container {
    width: 100%;
    padding: 20px 0;
  }

  .about .white-container p {
    font-size: 1.2rem;
  }
}

@media (max-width: 468px) {
  .about img {
    height: 30%;
  }

  .about .white-container {
    height: 40%;
  }

  .about .white-container img {
    width: 30%;
  }

  .about .white-container p {
    margin-top: 10px;
    font-size: 14px;
    width: 90%;
  }
}

.main {
	width: 100%;
	background-color: #eef0f3;
	position: relative;
	overflow: hidden;
}

.main .load {
	margin-top: 10px;
	height: 79vh;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #fff;
}

.mobile-mini-container {
	width: 100%;
	overflow-x: scroll;
	box-shadow: 0 3px 6px 0 #dee0e2;
	background-color: #fff;
	display: flex;
	padding-left: 0.8rem;
}

.mobile-mini-container::-webkit-scrollbar {
	width: 0;
	height: 0;
}

.mobile-mini-container .item img {
	width: 60px;
	height: 95px;
	object-fit: cover;
	margin: 0.25rem 0.5rem 0 0.5rem;
}

@media only screen and (min-width: 868px) {
	.mobile-mini-container {
		display: none;
	}
}

.footer {
	width: 95%;
	background-color: black;
	margin-left: auto;
	border-top-left-radius: 45px;
	padding: 60px;
	padding-bottom: 30px;
	margin-top: 50px;
	max-width: 1700px;
}

.footer .flex-container {
	height: 90%;
	display: flex;
	flex-direction: row;
	/* margin-bottom: 63px; */
	/* border-bottom: 3px solid white; */
}

.footer .flex-container .container-1 {
	color: grey;
	width: 60%;
	margin-bottom: 5px;
	margin-bottom: 1rem;
}

.footer .flex-container .container-1 .marfit-detail img {
	width: 20%;
}

.footer .flex-container .container-1 .marfit-detail p {
	width: 65%;
	font-size: 16px;
	margin: 15px 0 25px;
}

.footer .flex-container .container-1 .company-terms {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	height: 50%;
	width: 80%;
	/* margin-left: -25px; */
}

.footer .flex-container .container-1 .company-terms .company-items {
	display: flex;
	flex-direction: column;
	/* justify-content: space-between; */
	/* border: 1px solid white; */
	height: 100%;
}

.footer .flex-container .container-1 .company-terms .company-items:last-child {
	justify-content: flex-start;
}

.footer .flex-container .container-1 .company-terms .company-items:last-child h3 {
	margin-bottom: 20px;
}

.footer .flex-container .container-1 .company-terms .company-items:last-child a,
.footer .flex-container .container-1 .company-terms .company-items:last-child p {
	margin-bottom: 15px;
}

.footer .flex-container .container-1 .company-terms .company-items h3 {
	color: whitesmoke;
	font-size: 17px;
	font-weight: 500;
	margin-bottom: 10px;
}

.footer .flex-container .container-1 .company-terms .company-items a,
.footer .flex-container .container-1 .company-terms .company-items p {
	color: grey;
	text-decoration: none;
	font-size: 16px;
	margin-top: 1rem;
}

.footer .flex-container .container-1 .company-terms .company-items p i {
	margin-right: 4px;
}

.footer .flex-container .container-2 {
	width: 40%;
}

.footer .flex-container .container-2 .label {
	font-style: italic;
	color: white;
	font-size: 14px;
	font-weight: 500;
	word-spacing: 2px;
	margin-bottom: 20px;
}

.footer .flex-container .container-2 input {
	width: 100%;
	padding: 15px;
	font-size: 15px;
	border-radius: 5px;
	border: none;
	outline: none;
}

.footer .flex-container .container-2 .icons-pack {
	display: flex;
	flex-direction: row;
	align-items: center;
	margin: 20px 0 30px;
}

.footer .flex-container .container-2 .icons-pack a {
	text-decoration: none;
}

.footer .flex-container .container-2 .icons-pack a i {
	color: white;
	font-size: 35px;
	margin: 0 1rem;
}

.footer .flex-container .container-2 .icons-pack a:nth-child(2) i {
	font-size: 42px;
}

.footer .flex-container .container-2:nth-last-child(1) {
	color: white;
	font-size: 17px;
	font-weight: 500;
	font-style: italic;
}

.footer .flex-container .container-2 img {
	margin-top: 15px;
	width: 60%;
}

.footer .copyright {
	height: 16%;
	padding: 17px;
	color: white;
	border-top: 3px solid white;
}

.footer .copyright p {
	margin: 7px;
	font-size: 16px;
	text-align: center;
}

@media (max-width: 1024px) {
	.footer .flex-container .container-1 .company-terms {
		width: 90%;
	}

	.footer .flex-container .container-1 .company-terms .company-items h3 {
		font-size: 16px;
	}

	.footer .flex-container .container-1 .company-terms .company-items a,
	.footer .flex-container .container-1 .company-terms .company-items p {
		font-size: 14px;
	}
}

@media (max-width: 786px) {
	.footer {
		width: 100%;
		border-top-left-radius: 0;
	}

	.footer .flex-container .container-1 .marfit-detail img {
		width: 35%;
	}

	.footer .flex-container .container-1 .marfit-detail p {
		font-size: 12px;
		width: 85%;
	}

	.footer .flex-container .container-1 .company-terms {
		width: 90%;
	}

	.footer .flex-container .container-1 .company-terms .company-items h3 {
		font-size: 13px;
	}

	.footer .flex-container .container-1 .company-terms .company-items a,
	.footer .flex-container .container-1 .company-terms .company-items p {
		font-size: 11px;
	}

	.footer .copyright {
		padding: 13px;
	}

	.footer .copyright p {
		font-size: 10px;
	}
}

@media (max-width: 680px) {
	.footer {
		padding: 40px 30px 0;
		height: 100%;
	}

	.footer .flex-container {
		flex-direction: column;
	}

	.footer .flex-container .container-1,
	.footer .flex-container .container-2 {
		width: 100%;
	}

	.footer .flex-container .container-1 .marfit-detail img {
		width: 23%;
		text-align: right;
	}

	.footer .flex-container .container-1 .marfit-detail p {
		font-size: 0.99rem;
		width: 100%;
	}

	.footer .flex-container .container-1 .company-terms {
		flex-direction: column;
	}

	.footer .flex-container .container-1 .company-terms .company-items {
		margin-bottom: 20px;
		align-items: space-between;
	}

	.footer .flex-container .container-1 .company-terms .company-items h3 {
		font-size: 1.2rem;
	}

	.footer .flex-container .container-1 .company-terms .company-items a,
	.footer .flex-container .container-1 .company-terms .company-items p {
		font-size: 1rem;
		margin: 0.5rem 0;
	}

	.footer .flex-container .container-1 .company-terms .company-items p i {
		margin-right: 0.5rem;
	}

	.footer .flex-container .container-2 {
		text-align: center;
	}

	.footer .copyright {
		padding: 20px 0;
	}

	.footer .copyright p {
		font-size: 1rem;
	}
}

@media (max-width: 360px) {
	.footer {
		padding: 40px 30px 0;
		height: 100%;
	}

	.footer .flex-container .container-1 .marfit-detail img {
		width: 23%;
		text-align: right;
	}

	.footer .flex-container .container-1 .marfit-detail p {
		font-size: 0.99rem;
	}

	.footer .flex-container .container-1 .company-terms {
		flex-direction: column;
	}

	.footer .flex-container .container-1 .company-terms .company-items {
		margin-bottom: 20px;
		align-items: space-between;
	}

	.footer .flex-container .container-1 .company-terms .company-items h3 {
		font-size: 1.2rem;
	}

	.footer .flex-container .container-1 .company-terms .company-items a,
	.footer .flex-container .container-1 .company-terms .company-items p {
		font-size: 1rem;
		margin: 0.5rem 0;
	}

	.footer .flex-container .container-1 .company-terms .company-items p i {
		margin-right: 0.5rem;
	}

	.footer .copyright {
		padding: 20px 0;
	}

	.footer .copyright p {
		font-size: 1rem;
	}
}

.dashboard-container {
	width: 85vw;
	max-width: 1500px;
	margin: 6vh auto;
	min-height: 80vh;
	display: flex;
	justify-content: space-between;
}

.dashboard-container .loader-container {
	width: 180px;
	height: 45px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.dashboard-container .loader {
	width: 100%;
	height: 100%;
	display: flex;
	transform: scale(1.5);
	align-items: center;
	justify-content: center;
}

.dashboard-container .sidebar {
	width: 20%;
	padding: 1rem 0;
	box-shadow: 2px 2px 6px 0px #c5c5c5;
	border-radius: 3px;
	background-color: #fff;
	min-height: 80vh;
}

.dashboard-container .sidebar .menu {
	padding: 1rem 2rem;
	cursor: pointer;
	margin: 1rem 0;
	font-size: 1rem;
	transition: all ease-in-out 0.3s;
	color: #464646;
}

.dashboard-container .sidebar .menu:hover {
	background-color: #39328021;
	transition: all ease-in-out 0.3s;
}

.dashboard-container .sidebar .menu-active {
	padding: 1rem 2rem;
	background-color: azure;
	border-right: 7px solid #fb641b;
	background-color: #39328021;
	cursor: pointer;
	margin: 1rem 0;
	font-size: 1.1rem;
	transition: all ease-in-out 0.3s;
	font-weight: bold;
	color: #464646;
}

.dashboard-container .content {
	width: 78%;
	min-height: 80vh;
	padding: 2rem;
	background-color: #fff;
	box-shadow: 2px 2px 6px 0px #c5c5c5;
	border-radius: 3px;
	display: flex;
	flex-direction: column;
}

.dashboard-container .content h1 {
	font-size: 1.2rem;
	font-weight: 500;
	color: #fb641b;
	letter-spacing: 1px;
}

.dashboard-container .content .divider {
	width: 80px;
	height: 3px;
	border-radius: 2px;
	background-color: #39328021;
	margin: 0.5rem 0;
}

.dashboard-container .content .wishlist-container {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	padding: 1rem 0;
}

.dashboard-container .content .order-container {
	width: 100%;
	flex-wrap: wrap;
	padding: 1rem 0;
	overflow-y: scroll;
}

.empty {
	margin-top: 2rem;
	background-color: #fb641b;
	width: 260px;
	height: 42px;
	display: flex;
	align-items: center;
	text-decoration: none;
	font-weight: 500;
	justify-content: center;
	border-radius: 3px;
	color: #fff;
	font-size: 14px;
}
.dashboard-container .content .input-list {
	width: 100%;
	overflow-y: scroll;
	display: flex;
	flex-direction: column;
}
.dashboard-container .content .input-group {
	display: flex;
	align-items: center;
	width: 100%;
	margin: 1rem 0;
}

.dashboard-container .content .input-group .title {
	width: 25%;
	font-size: 1.1rem;
	color: #464646;
	margin-right: 1rem;
}

.dashboard-container .content .input-group .value {
	font-size: 1.1rem;
	color: #464646;
}

.dashboard-container .content .input-group input {
	border: none;
	border-bottom: 1px solid #464646;
	font-size: 1rem;
	padding: 0.5rem 0;
	outline: none;
}

.dashboard-container .content .input-group .gender-group {
	display: flex;
	align-items: center;
	justify-content: space-around;
	width: 200px;
}

.dashboard-container .content .input-group .gender-group .gender {
	display: flex;
	/* justify-content: space-around; */
	align-items: center;
	margin: 0 1rem 0 0;
}

.dashboard-container .content .input-group .gender-group .gender i {
	font-size: 1rem;
	margin: 0 0.25rem 0 0;
	color: #464646;
	/* transform: translateY(1.5px); */
	/* transition: all ease-in-out 0.3s; */
	cursor: pointer;
}

.dashboard-container .content .input-group .gender-group .gender i.activated {
	color: #fb641b;
	/* transition: all ease-in-out 0.3s; */
	/* transform: translateY(1.5px); */
	cursor: pointer;
}

.dashboard-container .content .input-group .gender-group .gender p {
	color: #464646;
}

.dashboard-container .content .profile-edit-button {
	width: 180px;
	height: 45px;
	background-color: #fb641b;
	color: #fff;
	border-radius: 3px;
	font-size: 1rem;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 1rem 0 0 0;
	cursor: pointer;
	transform: translateY(0px);
	transition: transform 0.3s ease-in-out;
}

.dashboard-container .content .profile-edit-button:hover {
	transform: translateY(-5px);
	transition: transform 0.3s ease-in-out;
}

.dashboard-container .content .button-group {
	display: flex;
}

.dashboard-container .content .button-group .profile-cancel-button {
	width: 180px;
	height: 45px;
	border: 1.5px solid #fb641b;
	color: #fb641b;
	border-radius: 3px;
	font-size: 1rem;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 1rem 0 0 0;
	cursor: pointer;
	transform: translateY(0px);
	transition: transform 0.3s ease-in-out;
}

.dashboard-container .content .button-group .profile-cancel-button:hover {
	transform: translateY(-5px);
	transition: transform 0.3s ease-in-out;
}

.dashboard-container .content .button-group .profile-save-button {
	width: 180px;
	height: 45px;
	background-color: #fb641b;
	color: #fff;
	border-radius: 3px;
	font-size: 1rem;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 1rem 0 0 1rem;
	cursor: pointer;
	transform: translateY(0px);
	transition: transform 0.3s ease-in-out;
}

.dashboard-container .content .button-group .profile-save-button:hover {
	transform: translateY(-5px);
	transition: transform 0.3s ease-in-out;
}

.dashboard-container .content .ordersdiv p {
	font-size: 14px;
	font-weight: 500;
	text-align: center;
	line-height: 35px;
}

.dashboard-container .content .ordersdiv p span {
	font-size: 18px;
	font-weight: bold;
}

.dashboard-container .content .address-cont {
	width: 100%;
}

.dashboard-container .content .address-cont .fa-arrow-left {
	height: 40px;
	font-size: 22px;
	cursor: pointer;
}

.dashboard-container .content .address-cont .address {
	width: 100%;
	height: 80px;
	margin: 0 auto;
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-bottom: 1px solid lightgrey;
}

.dashboard-container .content .address-cont .address .paras {
	width: 80%;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	align-items: flex-start;
}

.dashboard-container .content .address-cont .address .paras p {
	font-size: 16px;
	font-weight: 600;
	color: #fb641b;
}

.dashboard-container .content .address-cont .address .paras p:nth-child(2) {
	font-size: 14px;
	color: #000;
}

.dashboard-container .content .address-cont .address .actions {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-left: 6rem;
}

.dashboard-container .content .address-cont .address .actions .minus {
	width: 30px;
	height: 30px;
	cursor: pointer;
	background-color: red;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-right: 1rem;
}

.dashboard-container .content .address-cont .address .actions .minus i {
	font-size: 12px;
	color: white;
}

.dashboard-container .content .address-cont .address .actions .edit {
	width: 30px;
	height: 30px;
	cursor: pointer;
	background-color: green;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-right: 1rem;
}

.dashboard-container .content .address-cont .address .actions .edit i {
	font-size: 12px;
	color: white;
}

.dashboard-container .content .addtab.active {
	transform: scale(1);
	transition: transform 0.4s;
}

.dashboard-container .content .addtab {
	width: 100%;
	margin: 0 auto;
	display: flex;
	flex-direction: column;
	transform: scale(0);
	transform-origin: center;
	transition: transform 0.4s;
}

.dashboard-container .content .addtab .inputs {
	width: 80%;
	height: 100%;
	display: flex;
	flex-direction: column;
}

.dashboard-container .content .addtab .inputs .region {
	width: 70%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}

.dashboard-container .content .addtab .inputs .region input {
	width: 48%;
}

.dashboard-container .content .addtab .inputs .address-input {
	width: 70%;
	height: 45px;
	padding: 10px;
	border-radius: 5px;
	border: 1px solid grey;
	font-size: 14px;
	outline: none;
	margin-bottom: 15px;
}

.dashboard-container .content .addtab .inputs input:focus {
	border: 1px solid darkgrey;
}

.dashboard-container .content .addtab .inputs button {
	cursor: pointer;
	padding: 0.5rem 0;
	width: 80px;
	background-color: #fb641b;
	color: #fff;
	font-size: 18px;
	font-weight: bold;
	outline: none;
	border: 1px solid #fb641b;
	border-radius: 10px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 1rem;
}

.dashboard-container .content .addtab .inputs button p {
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: fit-content;
}

.dashboard-container .content .addtab i {
	cursor: pointer;
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: fit-content;
}

.dashboard-container .content .editTab.active {
	transform: scale(1);
	transition: transform 0.4s;
}

.dashboard-container .content .editTab {
	height: 100%;
	width: 99%;
	margin: 0 auto;
	display: flex;
	flex-direction: column;
	transform: scale(0);
	transform-origin: center;
	transition: transform 0.4s;
}

.dashboard-container .content .editTab .inputs {
	width: 80%;
	height: 100%;
	display: flex;
	flex-direction: column;
	/* justify-content: flex-start;
  align-items: center; */
}

.dashboard-container .content .editTab .inputs .region {
	width: 70%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}

.dashboard-container .content .editTab .inputs .region input {
	width: 48%;
}

.dashboard-container .content .editTab .inputs .address-input {
	width: 70%;
	height: 45px;
	padding: 10px;
	border-radius: 5px;
	border: 1px solid grey;
	font-size: 14px;
	outline: none;
	margin-bottom: 15px;
}

.dashboard-container .content .editTab .inputs input:focus {
	border: 1px solid darkgrey;
}

.dashboard-container .content .editTab .inputs button {
	cursor: pointer;
	padding: 0.5rem 0;
	width: 80px;
	background-color: #fb641b;
	color: #fff;
	font-size: 18px;
	font-weight: bold;
	outline: none;
	border: 1px solid #fb641b;
	border-radius: 10px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 1rem;
}

.dashboard-container .content .editTab .inputs button p {
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: fit-content;
}

.dashboard-container .content .editTab i {
	cursor: pointer;
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: fit-content;
}

.dashboard-container .content .newAddress {
	width: 30%;
	height: 60px;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	cursor: pointer;
}

.dashboard-container .content .newAddress i {
	font-size: 16px;
	color: green;
}

.dashboard-container .content .newAddress p {
	font-size: 15px;
	font-weight: bold;
	margin-left: 15px;
}

.dashboard-container .content .referMain {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.dashboard-container .content .referMain .referanimation {
	height: 200px;
	width: 200px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	position: relative;
}

.dashboard-container .content .referMain .referCode {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: 2rem;
}

.dashboard-container .content .referMain .referCode .pointHistory {
	display: flex;
	flex-direction: column;
	width: 100%;
	margin-top: 3rem;
	margin-right: auto;
}

.dashboard-container .content .referMain .referCode .pointHistory .point-container {
	width: 100%;
	display: flex;
	justify-content: space-between;
	margin: 0.5rem;
}

.dashboard-container .content .referMain .referCode .pointHistory .point-container .point-header {
	display: flex;
	flex-direction: column;
}

.dashboard-container .content .referMain .referCode .pointHistory .point-container .point-header p {
	font-size: 0.85rem;
	font-weight: 600;
}

.dashboard-container .content .referMain .referCode .pointHistory .point-container .point-header p:nth-child(2) {
	font-size: 0.7rem;
	font-weight: 600;
	color: #464646;
}

.dashboard-container .content .referMain .referCode .pointHistory .point-container .postive-points {
	color: #10caa5;
}

.dashboard-container .content .referMain .referCode .pointHistory .point-container .negative-points {
	color: #de3500;
}

.dashboard-container .content .referMain .referCode .referInput {
	width: 40%;
	height: 40px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
}

.dashboard-container .content .referMain .referCode .referInput input {
	width: 65%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.1);
	color: #000;
	border: 1px solid grey;
	border-top-left-radius: 10px;
	border-bottom-left-radius: 10px;
	border-right: none;
	font-size: 17px;
	text-align: center;
	cursor: text;
	font-weight: bold;
}

.dashboard-container .content .referMain .referCode .referInput .copyButton {
	height: 100%;
	width: 35%;
	background-color: #fb641b;
	color: #fff;
	border-top-right-radius: 10px;
	border-bottom-right-radius: 10px;
	border: 1px solid #fb641b;
	cursor: pointer;
	outline: none;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

.dashboard-container .content .referMain .referCode .referInput .copyButton p {
	font-size: 17px;
	font-weight: 500;
	transform: translateX(5px);
	transition: transform 0.5s;
}

.dashboard-container .content .referMain .referCode .referInput .copyButton i {
	font-size: 14px;
	transform: translateX(250px);
	transition: transform 0.5s;
}

.dashboard-container .content .referMain .referCode .referInput .copyButton:hover p {
	margin-right: 5px;
}

.dashboard-container .content .referMain .referCode .referInput .copyButton:hover p,
.dashboard-container .content .referMain .referCode .referInput .copyButton:hover i {
	transform: translateX(0);
}

.dashboard-container .content .referMain .referCode .referInput .copiedButton {
	height: 100%;
	width: 35%;
	background-color: green;
	color: #fff;
	border-top-right-radius: 10px;
	border-bottom-right-radius: 10px;
	border: 1px solid green;
	cursor: pointer;
	outline: none;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

.dashboard-container .content .referMain .referCode .referInput .copiedButton p {
	font-size: 14px;
	font-weight: 500;
	transform: translateX(5px);
	transition: transform 0.5s;
}

.dashboard-container .content .referMain .referCode .referInput .copiedButton i {
	font-size: 14px;
	transform: translateX(250px);
	transition: transform 0.5s;
}

.dashboard-container .content .referMain .referCode .referInput .copiedButton:hover p {
	margin-right: 5px;
}

.dashboard-container .content .referMain .referCode .referInput .copiedButton:hover p,
.dashboard-container .content .referMain .referCode .referInput .copiedButton:hover i {
	transform: translateX(0);
}

.dashboard-container .redeemHead {
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	/* padding: 0 1rem; */
	margin-bottom: 1rem;
	cursor: pointer;
}

.dashboard-container .content .redeem-cont {
	width: 100%;
	height: 80%;
}

.dashboard-container .content .redeem-cont .redeemCard {
	height: 200px;
	width: 80%;
	margin: 0 auto;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	position: relative;
}

.dashboard-container .content .redeem-cont .redeemCode {
	width: 80%;
	height: 50%;
	margin: auto;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.dashboard-container .content .redeem-cont .redeemCode .redeemInput {
	width: 300px;
	height: 40px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	z-index: 9;
}

.dashboard-container .content .redeem-cont .redeemCode .redeemInput input {
	width: 75%;
	height: 100%;
	background-color: #fff;
	color: #000;
	border: 1px solid grey;
	border-top-left-radius: 10px;
	border-bottom-left-radius: 10px;
	border-right: none;
	font-size: 17px;
	cursor: text;
	outline: none;
	font-weight: bold;
	padding: 0 0.9rem;
	letter-spacing: 1.5px;
}

.dashboard-container .content .redeem-cont .redeemCode .redeemInput input::placeholder {
	text-align: center;
}

.dashboard-container .content .redeem-cont .redeemCode .redeemInput .redeem {
	color: #fff;
	background-color: #ff7c3b;
	border-top-right-radius: 10px;
	border-bottom-right-radius: 10px;
	border: 1px solid #ff7c3b;
	font-size: 14px;
	width: 25%;
	height: 100%;
	cursor: pointer;
	outline: none;
	font-weight: bold;
	position: relative;
}

.dashboard-container .content .redeem-cont .redeemCode .redeemInput .redeem.active {
	background-color: #fb641b;
	border: 1px solid #fb641b;
}

.dashboard-container .refer-header {
	width: 100%;
	display: flex;
	justify-content: space-between;
}

/* .dashboard-container .content .order-container .orderList {
  width: 98%;
  padding: 0.5rem 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100px;
  border: 1px solid lightgray;
  margin-bottom: 10px;
  transition: box-shadow 0.3s;
  cursor: pointer;
  text-decoration: none;
  color: #000;
}

.dashboard-container .content .order-container .orderList:hover{
  box-shadow: 2px 3px 4px rgba(0, 0, 0, 0.15);
  transition: box-shadow 0.3s;
}

.dashboard-container .content .order-container .orderList .part1 {
  width: 33%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.dashboard-container .content .order-container .orderList .part1 h5{
  font-size: 0.9rem;
}

.dashboard-container .content .order-container .orderList .part1 img {
  height: 70%;
}

.dashboard-container .content .order-container .orderList .part1 .part1-detail{
  padding: 0.3rem 1rem 0.5rem;
}

.dashboard-container .content .order-container .orderList .part1 .part1-detail p{
  font-size: 0.7rem;
  color: darkgray;
  padding: 0.2rem 0 0;
}

.dashboard-container .content .order-container .orderList .part2 {
  width: 33%;
  display: flex;
  justify-content: center;
}

.dashboard-container .content .order-container .orderList .part2 p{
  font-size: 0.95rem;
  font-weight: bold;
}

.dashboard-container .content .order-container .orderList .part3{
  width: 33%;
  display: flex;
  flex-direction: column;
  padding-left: 4rem;
}

.dashboard-container .content .order-container .orderList .part3 .one{
  display: flex;
  padding: 0 0 0.5rem ;
  align-items: center;
}


.dashboard-container .content .order-container .orderList .part3 .one .indictionCircle{
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: green;
}

.dashboard-container .content .order-container .orderList .part3 .one .deliveryState{
  font-size: 0.8rem;
  font-weight: bold;
  margin-left: 10px;
}

.dashboard-container .content .order-container .orderList .part3 .deliveryDate{
  font-size: 0.7rem;
  font-weight: 600;
  color: darkgray;
} */

@media (max-width: 1024px) {
	.dashboard-container .content .addtab .inputs {
		width: 100%;
	}
	.dashboard-container .content .newAddress {
		width: 80%;
	}
}
/* @media (max-width: 768px) {
  .dashboard-container {
    width: 90vw;
    height: 60vh;
  }

  .dashboard-container .sidebar {
    width: 28%;
  }

  .dashboard-container .content .input-group .title {
    width: 30%;
  }

  .dashboard-container .content .referCode .referInput {
    width: 55%;
  }
} */

@media (max-width: 768px) {
	.dashboard-container {
		flex-direction: column;
		height: auto;
		min-height: auto;
	}

	/* .dashboard-container .loader {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  } */

	.dashboard-container .sidebar {
		width: 100%;
		height: 100px;
		min-height: auto;
		display: flex;
		flex-direction: row;
		overflow-x: scroll;
		padding: 0;
	}

	.dashboard-container .sidebar::-webkit-scrollbar {
		width: 0;
		height: 0;
	}

	.dashboard-container .content {
		width: 100%;
		height: 87%;
		padding: 1rem;
		background-color: #fff;
		box-shadow: 2px 2px 6px 0px #c5c5c5;
		border-radius: 3px;
		display: flex;
		flex-direction: column;
	}
	.dashboard-container .content .input-list {
		height: 71%;
	}
	.dashboard-container .sidebar .menu {
		margin: 0;
		padding: 0 1rem;
		display: flex;
		align-items: center;
		white-space: nowrap;
	}

	.dashboard-container .sidebar .menu:last-child {
		width: auto;
	}

	.dashboard-container .sidebar .menu-active {
		margin: 0;
		padding: 0 1rem;
		display: flex;
		align-items: center;
		border-right: none;
		border-bottom: 7px solid #fb641b;
		white-space: nowrap;
		font-size: 1rem;
	}
	.dashboard-container .content .address-cont .address {
		padding: 10px 0;
	}
	.dashboard-container .content .address-cont .address .paras {
		width: 70%;
	}
	.dashboard-container .content .address-cont .address .actions {
		width: 20%;
		padding: 0;
	}
	.dashboard-container .content .editTab .inputs {
		width: 100%;
	}
	.dashboard-container .content .editTab .inputs .region {
		width: 95%;
	}
	.dashboard-container .content .editTab .inputs input {
		width: 95%;
	}
	.dashboard-container .content .addtab .inputs .region,
	.dashboard-container .content .addtab .inputs input {
		width: 95%;
	}
	.dashboard-container .content .redeem-cont .redeemCode .redeemInput {
		width: 100%;
	}
}

@media (max-width: 678px) {
	.dashboard-container {
		width: 95vw;
	}
	.dashboard-container .content .address-cont .address {
		flex-direction: column;
		height: auto;
		align-items: flex-start;
	}

	.dashboard-container .content .address-cont .address .paras {
		width: 100%;
	}

	.dashboard-container .content .address-cont .address .actions {
		width: auto;
		margin-top: 1rem;
	}
}



.categorylist-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.categorylist-container .categorylist-breadcrumb {
  width: 100%;
  height: 50px;
  background-color: #f7f7f7;
  border-bottom: 1.2px solid #e5e5e5;
  box-shadow: inset 0px 1px 3px 0px rgba(0, 0, 0, 0.2);
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.categorylist-container .categorylist-breadcrumb .breadcrumb-menu {
  width: 85%;
  max-width: 1500px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.categorylist-container
  .categorylist-breadcrumb
  .breadcrumb-menu
  .bd-menu-list {
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.categorylist-container
  .categorylist-breadcrumb
  .breadcrumb-menu
  .bd-menu-list
  a {
  font-size: 14px;
  margin-right: 14px;
  text-decoration: none;
  color: #414141;
}

.categorylist-container
  .categorylist-breadcrumb
  .breadcrumb-menu
  .bd-menu-list
  a
  i {
  font-size: 10px;
}

.categorylist-container
  .categorylist-breadcrumb
  .breadcrumb-menu
  .bd-menu-stats {
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.categorylist-container
  .categorylist-breadcrumb
  .breadcrumb-menu
  .bd-menu-stats
  p {
  font-size: 14px;
  color: #414141;
}

@media (max-width: 680px){
  .categorylist-container
  .categorylist-breadcrumb
  .breadcrumb-menu
  .bd-menu-stats{
    display: none;
  }
}
.subcategory-list-container {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
}

.subcategory-list-container .subcategory-header {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: space-evenly;
	margin-bottom: 20px;
}

.subcategory-list-container .subcategory-header p {
	font-size: 22px;
	margin: 0 0 0.5rem 0;
}

.subcategory-list-container .subcategory-header .underline {
	width: 100px;
	height: 5px;
	background-color: #fb641b;
	border-radius: 10px;
}

.subcategory-list-container .subcategory-grids {
	width: 100%;
	display: flex;
	align-items: center;
	flex-wrap: wrap;
}

.subcategory-list-container .subcategory-grids .sub {
	width: 40vw;
	height: 40vw;
	position: relative;
	cursor: pointer;
	margin: 0.3rem;
	transform: translateY(0px);
	transition: transform 0.3s ease-in;
	max-width: 270px;
	max-height: 270px;
}

.subcategory-list-container .subcategory-grids .sub:hover {
	box-shadow: 2px 2px 6px 0px #e5e5e5;
	transform: translateY(-10px);
	transition: transform 0.3s ease-in;
	border-radius: 10px;
}

.subcategory-list-container .subcategory-grids .sub img {
	width: 100%;
	height: 100%;
	border-radius: 10px;
}

.subcategory-list-container .subcategory-grids .sub .room-name {
	background-color: white;
	padding: 0.3rem 0.8rem;
	position: absolute;
	top: 0;
	left: 0;
	border-top-right-radius: 0;
	border-top-left-radius: 10px;
	border-bottom-right-radius: 10px;
	border-bottom-left-radius: 0;
}

.subcategory-list-container .subcategory-grids .sub:nth-child(even) .room-name {
	background-color: white;
	padding: 0.3rem 0.8rem;
	position: absolute;
	top: 0;
	left: auto;
	right: 0;
	border-top-right-radius: 10px;
	border-top-left-radius: 0;
	border-bottom-right-radius: 0;
	border-bottom-left-radius: 10px;
}

.subcategory-list-container .subcategory-grids .sub .room-name p {
	font-size: 16px;
	color: #464646;
	font-weight: 500;
}

.most-rated-container {
  width: 100%;
  background-color: whitesmoke;
  margin-top: 30px;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.most-rated-container .most-rated-header {
  width: 85%;
  max-width: 1500px;
  height: 75px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-evenly;
  margin-top: 15px;
}

.most-rated-container .most-rated-header p {
  font-size: 22px;
}

.most-rated-container .most-rated-header .underline {
  width: 200px;
  height: 5px;
  background-color: #FB641B;
  border-radius: 10px;
}

.most-rated-container .most-rated-body {
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.most-rated-container .most-rated-body .swiper-container {
  width: 100%;
  margin: 40px 0;
}

.most-rated-container .most-rated-body .swiper-container .swiper-wrapper {
  display: flex;
  align-items: center;
}

.most-rated-container .most-rated-body .swiper-container .swiper-slide {
  text-align: center;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  justify-content: center;
  align-items: center;
  border: 1px solid #e2eaf0;
  cursor: pointer;
  height: 360px;
  width: 260px !important;
  border-radius: 5px;
  padding: 10px;
}

.most-rated-container .most-rated-body .swiper-container .swiper-slide img {
  height: 240px;
  width: 85%;
  object-fit: contain;
}

.most-rated-container
  .most-rated-body
  .swiper-container
  .swiper-slide
  .details {
  width: 90%;
  height: 80px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
  padding: 0 10px;
}

.most-rated-container
  .most-rated-body
  .swiper-container
  .swiper-slide
  .details
  p {
  font-size: 16px;
}

.most-rated-container
  .most-rated-body
  .swiper-container
  .swiper-slide
  .details
  .price-review {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.most-rated-container
  .most-rated-body
  .swiper-container
  .swiper-slide
  .details
  .price-review
  p {
  font-size: 16px;
  color: #000;
}

.most-rated-container
  .most-rated-body
  .swiper-container
  .swiper-slide
  .details
  .price-review
  a {
  /* padding: 0.3rem; */
  width: 80px;
  height: 30px;
  background-color: #fff;
  outline: none;
  border: 1px solid orange;
  color: orange;
  border-radius: 3px;
  font-size: 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.productlist-container {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background-color: #fff;
}

.productlist-container .categorylist-breadcrumb {
	width: 100%;
	height: 50px;
	background-color: #f7f7f7;
	border-bottom: 1.2px solid #e5e5e5;
	box-shadow: inset 0px 1px 3px 0px rgba(0, 0, 0, 0.2);
	margin-bottom: 20px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.productlist-container .categorylist-breadcrumb .breadcrumb-menu {
	width: 85%;
	max-width: 1700px;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.productlist-container .categorylist-breadcrumb .breadcrumb-menu .bd-menu-list {
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-start;
}

.productlist-container .categorylist-breadcrumb .breadcrumb-menu .bd-menu-list a {
	font-size: 14px;
	margin-right: 14px;
	text-decoration: none;
	color: #414141;
	text-transform: capitalize;
}

.productlist-container .categorylist-breadcrumb .breadcrumb-menu .bd-menu-list a i {
	font-size: 10px;
}

.productlist-container .categorylist-breadcrumb .breadcrumb-menu .bd-menu-stats {
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

.productlist-container .categorylist-breadcrumb .breadcrumb-menu .bd-menu-stats p {
	font-size: 14px;
	color: #414141;
}

.productlist-container .catalogue {
	width: 85%;
	max-width: 1700px;
	display: flex;
	justify-content: space-between;
}

.productlist-container .catalogue .card-list-container {
	width: 75%;
}

.productlist-container .catalogue .card-list {
	width: 100%;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	padding: 0 0 0 1rem;
}

.productlist-container .catalogue .filter {
	width: 285px;
}

.productlist-container .catalogue .mobile-filter {
	display: none;
}
.mobile-filter-active {
	display: none;
}

@media (max-width: 1024px) {
	.productlist-container .catalogue {
		width: 95%;
	}
	.productlist-container .catalogue .card-list-container {
		width: 100%;
	}
}

@media (max-width: 768px) {
	.productlist-container .categorylist-breadcrumb .breadcrumb-menu {
		width: 90%;
	}

	.productlist-container .categorylist-breadcrumb .breadcrumb-menu .bd-menu-stats {
		display: none !important;
	}
	.productlist-container .catalogue {
		display: flex;
		justify-content: center;
		flex-direction: column;
	}
	.productlist-container .catalogue .filter {
		display: none;
	}
	.mobile-filter-active {
		display: flex;
		width: 100%;
		height: 50%;
	}
}

@media (max-width: 375px) {
	.productlist-container .catalogue .card-list {
		padding: 0;
	}
	.productlist-container .catalogue .card-list-container {
		width: 100%;
	}
}

.filter-container {
	width: 100%;
	background-color: #fff;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	margin-bottom: 1rem;
}

.filter-container .filter-header {
	width: 100%;
	max-width: 1500px;
	height: 50px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.filter-container .filter-header .left {
	height: 100%;
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
}

.filter-container .filter-header .left .filter {
	width: 50%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-start;
}

.filter-container .filter-header .left .filter img {
	margin-right: 5px;
	width: 25px;
	height: 20px;
}

.filter-container .filter-header .left .filter p {
	font-size: 16px;
	color: #515151;
	font-weight: 500;
	letter-spacing: 0.5px;
}

.filter-container .filter-header .left .filter-inPhone {
	display: none;
}

.filter-container .filter-header .left .filter-inPhone img {
	margin-right: 5px;
	width: 25px;
	height: 25px;
}

.filter-container .filter-header .left .filter-inPhone p {
	font-size: 16px;
	color: #515151;
	font-weight: 500;
	letter-spacing: 0.5px;
}

.filter-container .filter-header .right .reset {
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

.filter-container .filter-header .right .reset button {
	width: 70px;
	height: 35px;
	background-color: #fff;
	border: 1px solid #c5c5c5;
	border-radius: 5px;
	cursor: pointer;
	font-size: 14px;
	color: #515151;
	font-weight: 500;
	outline: none;
}

.filter-container .price {
	width: 100%;
	border: 1px solid #e7e7e7;
	padding: 1.5rem 1.2rem;
	border-radius: 5px;
	margin: 0.5rem 0 0 0;
}

.filter-container .price h1 {
	font-size: 14px;
	font-weight: 500;
	margin-bottom: 0.5rem;
	color: #515151;
}

.filter-container .price p {
	font-size: 0.9rem;
	margin-bottom: 0.5rem;
	color: #204483;
	font-weight: 500;
}

.filter-container .price .slider {
	margin: 1rem 0.2rem;
}
.filter-container .price .slider .sliderLabel {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.filter-container .price .slider .sliderLabel .indicator {
	width: 2px;
	height: 6px;
	background-color: #e7e7e7;
}

.filter-container .price .slider .sliderLabel p {
	font-size: 0.6rem;
	color: "#e7e7e7";
}

.filter-container .price .slider .sliderLabel .active {
	font-size: 0.7rem;
	font-weight: bold;
	color: "#e7e7e7";
}

.filter-container .avail {
	width: 100%;
	border: 1px solid #e7e7e7;
	padding: 1.5rem 1.2rem;
	border-radius: 5px;
	margin: 0.5rem 0 0 0;
}

.filter-container .avail h1 {
	font-size: 14px;
	font-weight: 500;
	margin-bottom: 0.5rem;
	color: #515151;
}

.filter-container .cat {
	width: 100%;
	border: 1px solid #e7e7e7;
	padding: 1.5rem 1.2rem;
	border-radius: 5px;
	margin: 0.5rem 0 0 0;
}

.filter-container .cat h1 {
	font-size: 14px;
	font-weight: 500;
	margin-bottom: 0.5rem;
	color: #515151;
}

.filter-container .colors {
	width: 100%;
	border: 1px solid #e7e7e7;
	padding: 1.5rem 1.2rem;
	border-radius: 5px;
	margin: 0.5rem 0 0 0;
}

.filter-container .colors h1 {
	font-size: 14px;
	font-weight: 500;
	margin-bottom: 0.5rem;
	color: #515151;
}

.rc-slider-handle {
	border: none !important;
	background-color: #fb641b !important;
}

.rc-slider-dot {
	border: none !important;
	background-color: transparent !important;
}
.rc-slider-track {
	background-color: #fb641b !important;
}

.rc-slider-handle:active {
	box-shadow: none !important;
}

.rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging {
	box-shadow: none !important;
}

.category {
	width: 100%;
	display: flex;
	align-items: center;
	margin: 0.5rem 0;
	cursor: pointer;
}

.category i {
	width: 1.1rem;
	height: 1.1rem;
	font-size: 1.2rem;
	margin: 0 1rem 0 0.5rem;
	color: #fb641b;
}

.category .uncheck {
	width: 1.1rem;
	height: 1.1rem;
	margin: 0 1rem 0 0.5rem;
	border-radius: 2px;
	background-color: #e7e7e7;
}

.category p {
	font-size: 14px;
	color: #787878;
	font-weight: normal;
}

.colorCheck {
	width: 100%;
	display: flex;
	align-items: center;
	margin: 0.5rem 0;
	cursor: pointer;
}

.colorCheck i {
	width: 1.1rem;
	height: 1.1rem;
	font-size: 1.2rem;
	margin: 0 1rem 0 0.5rem;
	color: #fb641b;
}

.colorCheck .uncheck {
	width: 1.1rem;
	height: 1.1rem;
	margin: 0 1rem 0 0.5rem;
	border-radius: 2px;
	background-color: #e7e7e7;
}

.colorCheck p {
	font-size: 14px;
	color: #787878;
	font-weight: normal;
}

.card-cont {
	height: 300px;
	width: 240px;
	border-top: 1px solid rgba(0, 0, 0, 0.03);
	border-left: 1px solid rgba(0, 0, 0, 0.1);
	box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.2);
	position: relative;
	background-color: white;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-bottom: 7px;
	cursor: pointer;
	margin: 0 1rem 1rem 0rem;
	text-decoration: none;
	color: black;
}

.card-cont.grey {
	position: relative;
}

.card-cont.grey::after {
	content: "";
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
}

.card-cont.grey .outOfStock {
	font-size: 1rem;
	font-weight: 600;
	color: red;
	position: absolute;
	top: 50%;
	left: 50%;
	width: 100%;
	text-align: center;
	transform: translate(-50%, -50%);
	z-index: 99;
}

.card-cont .circle {
	width: 25px;
	height: 25px;
	border-radius: 50%;
	box-shadow: 1px 1px 3px lightgray;
	position: absolute;
	top: 10px;
	right: 10px;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #fff;
	z-index: 99;
}

.card-cont .circle i {
	color: lightgray;
}

.card-cont .img-container {
	width: 90%;
	height: 160px;
	margin-top: 10px;
}

.card-cont .img-container img {
	width: 100%;
	height: 100%;
	object-fit: contain;
}

.card-cont .short-description {
	display: flex;
	flex-direction: column;
	margin-top: 14px;
	justify-content: space-around;
	text-decoration: none;
	color: #000;
	width: 80%;
}

.card-cont .short-description .item-title {
	font-weight: 400;
	font-size: 14px;
	margin-bottom: 7px;
}

.card-cont .short-description .price-flex {
	display: flex;
	flex-direction: row;
}

.card-cont .short-description .item-price {
	font-size: 14px;
	margin-bottom: 7px;
}

.card-cont .short-description .price-flex .price-line-through {
	text-decoration: line-through;
	color: grey;
	margin-right: 10px;
	font-size: 13px;
}

.card-cont .short-description .price-flex .discount {
	font-weight: bold;
	color: #fb6b25;
	font-size: 13px;
}

.card-cont .circle .red {
	color: red;
}

@media (max-width: 768px) {
	.card-cont {
		height: 340px;
		width: 210px;
	}
}

@media only screen and (max-width: 580px) {
	.card-cont {
		width: 45% !important;
		margin-right: 0.6rem !important;
	}
	.swiper-slide .card-cont {
		width: 95% !important;
		margin-right: 0.6rem !important;
		margin-bottom: 0 !important;
	}
}

.categorylist-breadcrumb {
	width: 100%;
	height: 50px;
	background-color: #f7f7f7;
	border-bottom: 1.2px solid #e5e5e5;
	box-shadow: inset 0px 1px 3px 0px rgba(0, 0, 0, 0.2);
	display: flex;
	align-items: center;
	justify-content: center;
}

.categorylist-breadcrumb .breadcrumb-menu {
	width: 85%;
	max-width: 1500px;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.categorylist-breadcrumb .breadcrumb-menu .bd-menu-list {
	/* width: 50%; */
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-start;
}

.categorylist-breadcrumb .breadcrumb-menu .bd-menu-list a {
	font-size: 14px;
	margin-right: 14px;
	text-decoration: none;
	color: #414141;
}

.categorylist-breadcrumb .breadcrumb-menu .bd-menu-list a i {
	font-size: 10px;
}

.categorylist-breadcrumb .breadcrumb-menu .bd-menu-stats {
	/* width: 50%; */
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

.categorylist-breadcrumb .breadcrumb-menu .bd-menu-stats p {
	font-size: 14px;
	color: #414141;
}

.product-container {
	width: 100%;
	height: 73vh;
	background-color: #fff;
	box-shadow: 0px 3px 5px lightgrey;
	overflow: hidden;
}

.product-desc {
	width: 80%;
	margin: 0 auto;
	display: flex;
	flex-direction: row;
	height: 100%;
	padding-top: 0.5rem;
	background-color: #fff;
	scrollbar-width: 0;
}

.product-desc .all-product-image {
	width: 40%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	position: relative;
}

.product-desc .all-product-image .carousal-section {
	width: 100%;
	height: 85%;
	display: flex;
	flex-direction: row;
	margin-bottom: 0.5rem;
}

.product-desc .all-product-image .carousal-section .product-images {
	overflow-y: scroll;
	scrollbar-width: none;
}

.product-desc .all-product-image .carousal-section .product-images::-webkit-scrollbar {
	width: 2px;
}

.product-desc .all-product-image .carousal-section .product-images::-webkit-scrollbar-thumb {
	width: 2px;
	background-color: #fb6b25;
}

.product-desc .all-product-image .carousal-section .product-images .preview-image {
	width: 60px;
	height: 60px;
	cursor: pointer;
	padding: 0.5rem;
	opacity: 0.75;
	border-radius: 5px;
	margin: 0 5px;
}

.activeImage {
	border: 2px solid #fb6b25;
	opacity: 1 !important;
}

.product-desc .all-product-image .carousal-section .product-images .preview-image img {
	object-fit: contain;
	width: 100%;
	height: 100%;
}

.product-desc .all-product-image .carousal-section .product-image {
	display: flex;
	justify-content: center;
	width: 80%;
}

.product-desc .all-product-image .carousal-section .product-image .product-image-container {
	width: 18vw !important;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 18vw !important;
	margin: 0 auto;
}

.product-desc .all-product-image .carousal-section .product-image .product-image-image {
	width: 100% !important;
	height: 100% !important;
	object-fit: contain;
	background-color: #fff;
}

.product-desc .all-product-image .carousal-section .product-image .product-image-container2 {
	width: 100% !important;
	display: none;
	align-items: center;
	justify-content: center;
}

.product-desc .all-product-image .carousal-section .product-image .product-image-container2 .product-image-carousel {
	width: 100%;
}

.product-desc .all-product-image .carousal-section .product-image .product-image-container2 .product-image-carousel .slider-image-container {
	width: 100%;
	height: 400px;
}

.product-desc .all-product-image .carousal-section .product-image .product-image-container2 .product-image-carousel .slider-image-container img {
	height: 100%;
	object-fit: contain;
}

.product-desc .all-product-image .carousal-section .product-zoom-container {
	width: 60vw !important;
	height: 65vh !important;
	background-color: #fff;
	border-radius: 5px;
	box-shadow: 5px 5px 10px 0 #dee0e2;
	z-index: 1;
}

.product-desc .all-product-image .carousal-section .product-zoom-image {
	width: 1400px !important;
	height: 1800px !important;
	background-color: #fff;
}

.product-desc .all-product-image .buying-options {
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	position: absolute;
	bottom: 20px;
}

.product-desc .all-product-image .buying-options .outstock {
	background-color: #fb6b25;
	color: #fff;
	font-size: 20px;
	font-weight: bold;
	width: 90%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 10px 0;
}

.product-desc .all-product-image .buying-options .option {
	width: 180px;
	height: 45px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 3px;
	margin: 0 0.5rem;
	cursor: pointer;
	transition: transform 0.3s;
}

.product-desc .all-product-image .buying-options .option:hover {
	transform: translateY(-5px);
}

.product-desc .all-product-image .buying-options .option:nth-child(1) {
	background-color: #fff;
	border: 2px solid #fb6b25;
	color: #000;
	transition: background-color 0.1s, color 0.1s;
}

.product-desc .all-product-image .buying-options .option:nth-child(1):active {
	background-color: orangered;
	border-color: orangered;
	color: white;
}

.product-desc .all-product-image .buying-options .option:nth-child(2) {
	background-color: orangered;
	border: 2px solid orangered;
	color: #fff;
}

.product-desc .all-product-image .buying-options .option i {
	margin-right: 0.25rem;
}

.product-desc .description-section {
	width: 60%;
	overflow-y: scroll;
	padding-top: 40px;
	margin-bottom: 20px;
	scrollbar-width: none;
}

.product-desc .description-section .onsale {
	background-color: red;
	width: 100px;
	height: 30px;
	display: flex;
	justify-content: center;
	align-items: center;
	-webkit-clip-path: polygon(100% 0, 90% 50%, 100% 100%, 0 100%, 10% 50%, 0 0);
	        clip-path: polygon(100% 0, 90% 50%, 100% 100%, 0 100%, 10% 50%, 0 0);
}

.product-desc .description-section .onsale p {
	color: #fff;
	font-weight: bold;
}

.product-desc .description-section::-webkit-scrollbar {
	height: 0;
	width: 0;
}

.product-desc .description-section .product-title {
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	padding: 0.25rem 0.5rem 0.25rem 0;
}

.product-desc .description-section .product-title p {
	font-size: 32px;
	line-height: 41.2px;
	text-align: start;
	width: 80%;
}

.product-desc .description-section .product-title .circle {
	width: 40px;
	height: 40px;
	border-radius: 50%;
	box-shadow: 1px 1px 3px lightgray;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #fff;
	cursor: pointer;
}

.product-desc .description-section .product-title .circle i {
	font-size: 18px;
	color: darkgray;
}

.red {
	color: red !important;
}

.product-desc .price {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.product-desc .price .product-price {
	font-size: 28px;
	line-height: 39.2px;
	font-weight: 700px;
}

.product-desc .price .product-price-linethrough {
	font-size: 16px;
	line-height: 22.4px;
	text-decoration: line-through;
	color: #878787;
	margin: 0 1rem;
}

.product-desc .price .product-discount {
	font-size: 16px;
	line-height: 22.4px;
	letter-spacing: -0.2px;
	font-weight: bold;
	color: #fb6b25;
}

.product-desc .description-section .other-details {
	width: 100%;
	display: flex;
	flex-direction: row;
	margin-top: 10px;
}

.product-desc .description-section .other-details .quantity-cont .title-tag,
.product-desc .description-section .other-details .size-cont .title-tag,
.product-desc .description-section .other-details .color-cont .title-tag {
	font-weight: 600;
	margin-left: 7px;
	margin-bottom: 7px;
}

.product-desc .description-section .other-details .quantity-cont,
.product-desc .description-section .other-details .size-cont,
.product-desc .description-section .other-details .color-cont {
	margin-right: 20px;
}

.product-desc .description-section .other-details .quantity-cont .quantity {
	height: 30px;
	border: 2px solid grey;
	width: 100px;
	display: flex;
	justify-content: space-around;
	border-radius: 5px;
	align-items: center;
	cursor: pointer;
	-webkit-user-select: none;
	        user-select: none;
	overflow: hidden;
}

.product-desc .description-section .other-details .quantity-cont .quantity.grey {
	background-color: #eee;
	color: #777 !important;
}

.product-desc .description-section .other-details .quantity-cont .quantity .symbol {
	font-size: 18px;
	height: -webkit-fit-content;
	height: -moz-fit-content;
	height: fit-content;
	padding-bottom: 2px;
	width: 20%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.product-desc .description-section .other-details .quantity-cont .quantity .symbol.grey {
	font-size: 18px;
	height: -webkit-fit-content;
	height: -moz-fit-content;
	height: fit-content;
	padding-bottom: 2px;
	color: grey;
	width: 20%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.product-desc .description-section .other-details .size-cont {
	-webkit-user-select: none;
	        user-select: none;
}

.product-desc .description-section .other-details .size-cont .size {
	/* width: 185px; */
	height: 30px;
	display: flex;
	flex-direction: row;
	align-items: center;
	cursor: pointer;
	-webkit-user-select: none;
	        user-select: none;
}

.product-desc .description-section .other-details .size-cont .size p {
	padding: 0px 12px;
	height: 100%;
	text-align: center;
	font-size: 14px;
	font-weight: bold;
	display: flex;
	align-items: center;
	border: 2px solid grey;
	border-right: 0;
	border-left: 0;
}

.product-desc .description-section .other-details .size-cont .size p.grey {
	background-color: #eee;
	color: #777;
}

.product-desc .description-section .other-details .size-cont .size p:nth-child(1) {
	border-left: 2px solid grey;
	border-top-left-radius: 5px;
	border-bottom-left-radius: 5px;
}

.product-desc .description-section .other-details .size-cont .size p:last-child {
	border-right: 2px solid grey;
	border-top-right-radius: 5px;
	border-bottom-right-radius: 5px;
}

.product-desc .description-section .other-details .size-cont .size .sizeSelected {
	background-color: orangered;
	color: white;
}

.product-desc .description-section .other-details .color-cont {
	-webkit-user-select: none;
	        user-select: none;
	width: 100%;
}

.product-desc .description-section .other-details .color-cont .colors {
	border-radius: 5px;
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
	cursor: pointer;
	-webkit-user-select: none;
	        user-select: none;
	align-items: center;
	width: 100%;
}

.product-desc .description-section .other-details .color-cont .colors .color {
	color: #000;
	margin: 0;
	font-size: 14px;
	font-weight: bold;
	height: 60px;
	min-width: 80px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	border: 1px solid grey;
	border-radius: 5px;
	margin: 0 5px 10px 5px;
	padding: 0.25rem;
}
.product-desc .description-section .other-details .color-cont .colors .color img {
	height: 70%;
	object-fit: contain;
}

.product-desc .description-section .other-details .color-cont .colors .color p {
	font-size: 10px;
	color: #000;
}

.product-desc .description-section .other-details .color-cont .colors .color-selected {
	border: 2px solid orangered;

	border-radius: 5px;
}

.product-desc .description-section .other-details .out-of-stock-text {
	margin-right: 2rem;
}

.product-desc .description-section .other-details .out-of-stock-text h1 {
	color: #000;
	font-weight: bold;
	font-size: 1.5rem;
}

.product-desc .description-section .other-details .out-of-stock-text p {
	color: #464646;
	font-weight: bold;
	font-size: 1rem;
}

.product-desc .description-section .product-emboss {
	display: flex;
	margin-top: 1rem;
}

.product-desc .description-section .product-emboss i.fa-circle {
	color: #878787;
	margin-right: 5px;
}

.product-desc .description-section .product-emboss i.fa-check-circle {
	color: #26a541;
	margin-right: 5px;
}

.product-desc .description-section .product-emboss p {
	font-size: 0.85rem;
	cursor: pointer;
	color: #fb6b25;
}

.product-desc .description-section .product-emboss p span {
	color: #000;
}

.product-desc .description-section .product-emboss .emboss-field {
	display: flex;
	flex-direction: column;
}

.product-desc .description-section .product-emboss .emboss-field input {
	width: 240px;
	height: 35px;
	padding: 0.25rem;
	border-radius: 5px;
	border: 1px solid #fb6b25;
	outline: none;
}

.product-desc .description-section .product-emboss .emboss-field .emboss-buttons {
	display: flex;
}

.product-desc .description-section .product-emboss .emboss-field .emboss-buttons button {
	width: 30px;
	height: 30px;
	border-radius: 50%;
	margin: 0.5rem;
	border: none;
	color: #fff;
	cursor: pointer;
	outline: none;
}

.product-desc .description-section .product-emboss .emboss-field .emboss-buttons button:nth-child(1) {
	background-color: #26a541;
}

.product-desc .description-section .product-emboss .emboss-field .emboss-buttons button:nth-child(2) {
	background-color: #fb6b25;
}

.product-desc .description-section .product-delivery {
	display: flex;
	align-items: center;
	margin: 0.5rem 0;
}

.product-desc .description-section .product-delivery p {
	font-size: 1.1rem;
	font-weight: 600;
	color: #464646;
	margin-right: 1rem;
}

.product-desc .description-section .product-delivery .delivery-input {
	display: flex;
	align-items: center;
	border-bottom: 2px solid #fb6b25;
	position: relative;
}

.product-desc .description-section .product-delivery .delivery-input i {
	margin-right: 8px;
	color: #fb6b25;
}

.product-desc .description-section .product-delivery .delivery-input input {
	border: none;
	border: none;
	width: 160px;
	height: 40px;
	font-size: 0.9rem;
	outline: none;
}

.product-desc .description-section .product-delivery .delivery-input p {
	font-size: 0.85rem;
	font-weight: 600;
	color: #fb6b25;
	cursor: pointer;
	position: absolute;
	right: 0;
}

.product-desc .description-section .product-delivery .delivery-input-box p {
	font-size: 0.85rem;
	margin-top: 0.25rem;
}

.product-desc .description-section .product-delivery .delivery-input-box p.right {
	color: #26a541;
}

.product-desc .description-section .product-delivery .delivery-input-box p.wrong {
	color: #de3700;
}

.product-desc .description-section .product-details {
	border-bottom: 2px solid lightgray;
}

.product-desc .description-section .product-details h3 {
	padding: 25px 20px;
	border-top: 2px solid lightgray;
	border-bottom: 2px solid lightgray;
	font-size: 23px;
}

.product-desc .description-section .product-details .product-summary {
	margin-top: 15px;
	font-size: 15px;
}

.product-desc .description-section .product-details ul {
	margin: 20px;
	list-style: none;
	min-height: 100px;
}

.product-desc .description-section .product-details ul li {
	width: 70%;
	min-height: 40px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	/* margin: 15px 0; */
}

.product-desc .description-section .product-details ul li p {
	font-size: 14px;
	font-weight: 500;
	line-height: 19.6px;
	padding: 0 0.5rem;
	width: 50%;
}

.product-desc .description-section .product-details ul li .darkgrey {
	color: #878787;
}

.product-desc .description-section .rating .rating-header {
	display: flex;
	flex-direction: row;
	/* align-items: center; */
	width: 80%;
	margin: 30px 0;
}
.product-desc .description-section .rating .rating-header .rating-body {
	display: flex;
}

.product-desc .description-section .rating .rating-header h3 {
	font-size: 24px;
	line-height: 33.6px;
	margin-right: 30px;
}

.product-desc .description-section .rating .rating-header .stars {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	/* width: 45%; */
	padding: 0.25rem 0.6rem;
	border-radius: 50px;
	background-color: #26a541;
	color: #fff;
	margin-right: 10px;
}

.product-desc .description-section .rating .rating-header .stars p {
	margin-right: 5px;
}

.product-desc .description-section .rating .rating-header .rating-size {
	align-self: center;
	font-size: 16px;
	line-height: 22.4px;
	color: #878787;
}

.product-desc .description-section .rating .review-list {
	width: 100%;
}

.product-desc .description-section .rating .review-list .noratings {
	height: 60px;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: flex-start;
}

.product-desc .description-section .rating .review-list .noratings p {
	font-size: 17px;
	font-weight: 500;
}

.product-desc .description-section .rating .review-list .reviews {
	width: 95%;
	border-bottom: 1px solid lightgray;
	margin: 0 auto;
	margin-bottom: 20px;
}

.product-desc .description-section .rating .review-list .reviews .upper {
	display: flex;
	flex-direction: row;
	width: 100%;
	margin-bottom: 15px;
}

.product-desc .description-section .rating .review-list .reviews .upper .stars-mini {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	width: 80px;
	height: 30px;
	border-radius: 50px;
	background-color: #26a541;
	color: #fff;
	margin-right: 10px;
	font-size: 10px;
}

.product-desc .description-section .rating .review-list .reviews .upper .stars-mini p {
	font-size: 14px;
	margin-right: 3px;
}

.product-desc .description-section .rating .review-list .reviews .upper .review-text {
	font-size: 14px;
	line-height: 20.02px;
}

.product-desc .description-section .rating .review-list .reviews .lower {
	display: flex;
	flex-direction: row;
	margin-bottom: 20px;
}

.product-desc .description-section .rating .review-list .reviews .lower p {
	font-size: 12px;
	color: #878787;
	margin-left: 5px;
}

.buying-options-sticky {
	width: 100%;
	display: none;
	flex-direction: row;
	align-items: center;
	position: -webkit-sticky;
	position: sticky;
	bottom: 0px;
	background-color: #fff;
}

.buying-options-sticky .outstock {
	background-color: #fb6b25;
	color: #fff;
	font-size: 20px;
	font-weight: bold;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 10px 0;
}

.buying-options-sticky .option {
	width: 180px;
	height: 45px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 3px;
	margin: 0 0.5rem;
	cursor: pointer;
	transition: transform 0.3s;
}

.buying-options-sticky .option:hover {
	transform: translateY(-5px);
}

.buying-options-sticky .option:nth-child(1) {
	background-color: #fff;
	border: 2px solid #fb6b25;
	color: #000;
	transition: background-color 0.1s, color 0.1s;
}

.buying-options-sticky .option:nth-child(1):active {
	background-color: orangered;
	border-color: orangered;
	color: white;
}

.buying-options-sticky .option:nth-child(2) {
	background-color: orangered;
	border: 2px solid orangered;
	color: #fff;
}

.buying-options-sticky .option i {
	margin-right: 0.25rem;
}

.image-view-box {
	width: 100%;
	z-index: 999;
	position: fixed;
	top: 0vh;
	left: 0;
	height: 94vh;
	background-color: red;
}

#ReactSimpleImageViewer {
	width: 100%;
	height: 94vh;
}

#ReactSimpleImageViewer .react-simple-image-viewer__close {
	background-color: rgba(110, 110, 110, 0.25);
	opacity: 1;
	width: 40px;
	height: 40px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	padding-bottom: 5px;
	color: #000 !important;
	top: 10px !important;
}

#ReactSimpleImageViewer img {
	width: 100%;
	object-fit: contain;
}

@media (max-width: 768px) {
	.categorylist-breadcrumb .breadcrumb-menu {
		width: 90%;
	}
	.categorylist-breadcrumb .breadcrumb-menu .bd-menu-list {
		width: 100%;
	}
	.product-container {
		height: auto;
	}
	.product-desc {
		flex-direction: column;
		width: 90%;
	}
	.product-desc .all-product-image {
		width: 100%;
	}
	.product-desc .all-product-image .carousal-section .product-images::-webkit-scrollbar {
		width: 0;
		height: 0;
	}
	.product-desc .all-product-image .carousal-section .product-images .preview-image {
		width: 80px;
		height: 80px;
	}
	.product-desc .description-section {
		width: 100%;
	}
	.product-desc .description-section {
		overflow-y: visible;
	}
	.product-desc .all-product-image .buying-options {
		display: none;
	}
	.product-desc .all-product-image .carousal-section {
		flex-direction: column-reverse;
	}
	.product-desc .all-product-image .carousal-section .product-images {
		width: 100%;
		display: flex;
		overflow-x: scroll;
		scrollbar-width: 0;
	}
	.product-desc .all-product-image .carousal-section .product-image {
		width: 100%;
	}
	.product-desc .all-product-image .carousal-section .product-image .product-image-container {
		display: none;
	}

	.product-desc .all-product-image .carousal-section .product-images {
		display: none;
	}
	.product-desc .all-product-image .carousal-section .product-image .product-image-container2 {
		display: flex;
	}
	.buying-options-sticky {
		display: flex;
		justify-content: center;
		z-index: 99;
		margin-top: 10px;
	}
	.buying-options-sticky .outstock {
		width: 80%;
		margin: 10px auto;
	}
	.buying-options-sticky .option {
		width: 40%;
		height: 52px;
		margin-bottom: 20px;
		margin-top: 20px;
	}
	.buying-options-sticky .option i {
		margin-right: 0.5rem;
	}
}

@media (max-width: 680px) {
	.react-simple-image-viewer__close {
		top: 7vh !important;
		color: rgba(0, 0, 0, 0.5) !important;
	}
	.react-simple-image-viewer__previous {
		color: rgba(0, 0, 0, 0.5) !important;
		font-size: 30px !important;
		height: 50% !important;
	}
	.react-simple-image-viewer__next {
		color: rgba(0, 0, 0, 0.5) !important;
		font-size: 30px !important;
		height: 50% !important;
	}

	.product-desc {
		margin: 0px;
		width: 100%;
	}
	.product-container {
		display: flex;
		justify-content: center;
	}
	.product-desc .all-product-image .carousal-section .product-images .preview-image {
		width: 90px;
	}
	.product-desc .description-section .other-details .size-cont,
	.product-desc .description-section .other-details .color-cont,
	.product-desc .description-section .other-details .quantity-cont {
		margin-bottom: 15px;
	}
	.product-desc .description-section .other-details .size-cont .size {
		height: 33px;
	}
	.product-desc .description-section .other-details .size-cont .size p {
		padding: 0px 12px;
	}
	.product-desc .description-section .other-details .color-cont .colors .color {
		height: 80px;
	}
	.product-desc .description-section .other-details .color-cont .colors p {
		padding: 7px;
	}
	.product-desc .description-section {
		padding: 0 1rem;
	}

	.product-desc .description-section .rating .rating-header {
		margin: 0.25rem 0;
	}

	.product-desc .description-section .other-details {
		width: 100%;
		height: -webkit-fit-content;
		height: -moz-fit-content;
		height: fit-content;
		flex-direction: column;
		align-items: flex-start;
	}

	.product-desc .description-section .product-title p {
		font-size: 20px;
		line-height: 30px;
	}

	.product-desc .description-section .product-details {
		margin-top: 30px;
	}
	.product-desc .description-section .product-details ul li {
		width: 100%;
	}
	.product-desc .description-section .rating .rating-header {
		flex-direction: column;
	}
	.product-desc .description-section .rating .rating-header .rating-body .stars {
		width: 60px;
		padding: 0;
		height: 30px;
	}
	.product-desc .description-section .rating .rating-body {
		display: flex;
		flex-direction: row;
	}
	.product-desc .description-section .rating .review-list .reviews .upper .stars-mini {
		width: 14%;
		height: 28px;
		padding: 0.1rem;
	}
	.categorylist-breadcrumb {
		display: none;
	}
	.buying-options-sticky .outstock {
		width: 90%;
	}
	.buying-options-sticky .option {
		width: 45%;
		height: 40px;
	}
}

.coming-soon-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
}

.coming-soon-container .coming-box{
    width: 85%;
    height: 90vh;
    margin-top: 80px;
    padding: 2.5rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}

.coming-soon-container .coming-box h1 {
    color: #393280;
}
.not-found-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.not-found-container .not-found-box {
    width: 85%;
    height: 90vh;
    margin-top: 80px;
    padding: 2.5rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.not-found-container .not-found-box div {
    transform: scale(1.5);
    overflow: hidden;
}


.invoice-container {
	width: 100%;
	display: flex;
	align-items: center;
	flex-direction: column;
}

.invoice-container .invoice-container-main {
	width: 90%;
	background-color: #fff;
	margin: 1rem;
}

.invoice-container .invoice-container-main .invoice-printer {
	width: 100%;
	display: flex;
	justify-content: flex-end;
}
.invoice-container .invoice-container-main .invoice-printer .print-button {
	margin: 1rem;
	width: 120px;
	height: 35px;
	border-radius: 2px;
	border: none;
	background-color: #fb641b;
	color: #fff;
	cursor: pointer;
	margin: 1rem 1rem 1rem 0;
}

.invoice-box {
	width: 100%;
	height: 100vh;
	display: flex;
	position: relative;
	padding: 5rem 2rem;
	flex-direction: column;
}
.invoice-box .invoice-header {
	width: 100%;
	display: flex;
	align-items: center;
	padding: 0.5rem 0;
	justify-content: space-evenly;
	border-bottom: 2px solid #e5e5e5;
	margin-bottom: 0.25rem;
}

.invoice-box .invoice-header h1 {
	font-size: 1rem;
}

.invoice-box .invoice-header .header-divider {
	width: 2px;
	height: 25px;
	background-color: #e5e5e5;
}

.invoice-box .invoice-header .header-section {
	display: flex;
	flex-direction: column;
}

.invoice-box .invoice-header .header-section p {
	font-size: 0.8rem;
}

.invoice-box .invoice-header .header-section p span {
	font-weight: bold;
}

.invoice-box .invoice-location {
	display: flex;
	width: 100%;
	align-items: center;
	justify-content: space-evenly;
	margin-bottom: 0.5rem;
}

.invoice-box .invoice-location .location-section {
	display: flex;
	flex-direction: column;
}

.invoice-box .invoice-location .location-section h1 {
	font-size: 0.8rem;
}

.invoice-box .invoice-location .location-section p {
	font-size: 0.8rem;
}

.invoice-box .product-list {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	border: 2px solid #777;
}

.invoice-box .product-list .product-list-header {
	display: flex;
	align-items: center;
	width: 100%;
	padding-bottom: 0.5rem;
	border-bottom: 2px solid #777;
	margin-bottom: 0.25rem;
	padding: 0.25rem;
}

.invoice-box .product-list .product-list-header h1 {
	font-size: 0.8rem;
}

.invoice-box .product-list .product-list-header h1:nth-child(1) {
	width: 30%;
	text-align: center;
}

.invoice-box .product-list .product-list-header h1:nth-child(2) {
	width: 15%;
	text-align: center;
}

.invoice-box .product-list .product-list-header h1:nth-child(3) {
	width: 5%;
	text-align: center;
}

.invoice-box .product-list .product-list-header h1:nth-child(4) {
	width: 10%;
	text-align: center;
}
.invoice-box .product-list .product-list-header h1:nth-child(5) {
	width: 10%;
	text-align: center;
}
.invoice-box .product-list .product-list-header h1:nth-child(6) {
	width: 10%;
	text-align: center;
}
.invoice-box .product-list .product-list-header h1:nth-child(7) {
	width: 10%;
	text-align: center;
}
.invoice-box .product-list .product-list-header h1:nth-child(8) {
	width: 10%;
	text-align: center;
}

.invoice-box .product-list .product-info {
	display: flex;
	align-items: center;
	width: 100%;
	padding: 0.25rem;
}

.invoice-box .product-list .product-info p {
	font-size: 0.8rem;
}

.invoice-box .product-list .product-info p:nth-child(1) {
	width: 30%;
}

.invoice-box .product-list .product-info p:nth-child(2) {
	width: 15%;
	text-align: center;
}

.invoice-box .product-list .product-info p:nth-child(3) {
	width: 5%;
	text-align: center;
}

.invoice-box .product-list .product-info p:nth-child(4) {
	width: 10%;
	text-align: center;
}
.invoice-box .product-list .product-info p:nth-child(5) {
	width: 10%;
	text-align: center;
}
.invoice-box .product-list .product-info p:nth-child(6) {
	width: 10%;
	text-align: center;
}
.invoice-box .product-list .product-info p:nth-child(7) {
	width: 10%;
	text-align: center;
}
.invoice-box .product-list .product-info p:nth-child(8) {
	width: 10%;
	text-align: center;
}

.invoice-box .product-list .shipping-section {
	display: flex;
	align-items: center;
	width: 100%;
	padding: 0.25rem;
	border-bottom: 2px solid #777;
}

.invoice-box .product-list .shipping-section p {
	font-size: 0.8rem;
}

.invoice-box .product-list .shipping-section p:nth-child(1) {
	width: 30%;
}

.invoice-box .product-list .shipping-section p:nth-child(2) {
	width: 15%;
	text-align: center;
}

.invoice-box .product-list .shipping-section p:nth-child(3) {
	width: 5%;
	text-align: center;
}

.invoice-box .product-list .shipping-section p:nth-child(4) {
	width: 10%;
	text-align: center;
}
.invoice-box .product-list .shipping-section p:nth-child(5) {
	width: 10%;
	text-align: center;
}
.invoice-box .product-list .shipping-section p:nth-child(6) {
	width: 10%;
	text-align: center;
}
.invoice-box .product-list .shipping-section p:nth-child(7) {
	width: 10%;
	text-align: center;
}
.invoice-box .product-list .shipping-section p:nth-child(8) {
	width: 10%;
	text-align: center;
}

.invoice-box .product-list .product-quanity {
	display: flex;
	width: 100%;
	justify-content: space-between;
	padding: 0.25rem;
	align-items: center;
}

.invoice-box .product-list .product-quanity h1 {
	font-size: 0.8rem;
}

.invoice-box .product-list .product-quanity .product-price {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
}

.invoice-box .product-list .product-quanity .product-price p {
	font-size: 0.7rem;
}

.invoice-box .invoice-footer {
	width: 100%;
	display: flex;
	align-items: center;
	margin-top: 1rem;
	padding: 1rem;
}

.invoice-box .invoice-footer img {
	width: 80px;
	object-fit: contain;
}

.Viewall{
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    padding: 2rem;
    min-height: 55vh;
}

.Viewall .load{
  width: 100%;
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Viewall p {
    font-weight: 600;
    color: rgba(0, 0, 0, 0.8);
    font-size: 24px;
  }
  
.Viewall .lines {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 7px;
  }
  
.Viewall .lines .line-through {
    width: 20px;
    height: 1px;
    background-color: rgba(0, 0, 0, 0.8);
    margin: 3px 0;
  }
  
.Viewall .lines .rotated-line {
    transform: rotate(-45deg);
    display: inline-block;
    margin: 0 5px;
  }
  
.Viewall .lines .horizontal {
    width: 68px;
    height: 1px;
    background-color: rgba(0, 0, 0, 0.6);
    /* display: inline-block; */
  }

.Viewall .ViewallProducts{
    margin-top: 2rem;
    display: flex;
    flex-wrap: wrap;
}

@media only screen and (max-width: 580px){
  .Viewall{
    padding: 0.5rem !important;
 }
}
.NewArrival{
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    padding: 2rem;
    min-height: 55vh;
}

.NewArrival .load{
  width: 100%;
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.NewArrival p {
    font-weight: 600;
    color: rgba(0, 0, 0, 0.8);
    font-size: 24px;
  }
  
.NewArrival .lines {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 7px;
  }
  
.NewArrival .lines .line-through {
    width: 20px;
    height: 1px;
    background-color: rgba(0, 0, 0, 0.8);
    margin: 3px 0;
  }
  
.NewArrival .lines .rotated-line {
    transform: rotate(-45deg);
    display: inline-block;
    margin: 0 5px;
  }
  
.NewArrival .lines .horizontal {
    width: 68px;
    height: 1px;
    background-color: rgba(0, 0, 0, 0.6);
    /* display: inline-block; */
  }

.NewArrival .NewArrivalProducts{
    margin-top: 2rem;
    display: flex;
    flex-wrap: wrap;
}
.Sale{
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    padding: 2rem;
    min-height: 55vh;
}

.Sale .load{
  width: 100%;
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Sale p {
    font-weight: 600;
    color: rgba(0, 0, 0, 0.8);
    font-size: 24px;
  }
  
.Sale .lines {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 7px;
  }
  
.Sale .lines .line-through {
    width: 20px;
    height: 1px;
    background-color: rgba(0, 0, 0, 0.8);
    margin: 3px 0;
  }
  
.Sale .lines .rotated-line {
    transform: rotate(-45deg);
    display: inline-block;
    margin: 0 5px;
  }
  
.Sale .lines .horizontal {
    width: 68px;
    height: 1px;
    background-color: rgba(0, 0, 0, 0.6);
    /* display: inline-block; */
  }

.Sale .SaleProducts{
    margin-top: 2rem;
    display: flex;
    flex-wrap: wrap;
}
.productlist-container {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background-color: #fff;
}

.productlist-container .categorylist-breadcrumb {
	width: 100%;
	height: 50px;
	background-color: #f7f7f7;
	border-bottom: 1.2px solid #e5e5e5;
	box-shadow: inset 0px 1px 3px 0px rgba(0, 0, 0, 0.2);
	margin-bottom: 20px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.productlist-container .categorylist-breadcrumb .breadcrumb-menu {
	width: 85%;
	max-width: 1700px;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.productlist-container .categorylist-breadcrumb .breadcrumb-menu .bd-menu-list {
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-start;
}

.productlist-container .categorylist-breadcrumb .breadcrumb-menu .bd-menu-list a {
	font-size: 14px;
	margin-right: 14px;
	text-decoration: none;
	color: #414141;
	text-transform: capitalize;
}

.productlist-container .categorylist-breadcrumb .breadcrumb-menu .bd-menu-list a i {
	font-size: 10px;
}

.productlist-container .categorylist-breadcrumb .breadcrumb-menu .bd-menu-stats {
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

.productlist-container .categorylist-breadcrumb .breadcrumb-menu .bd-menu-stats p {
	font-size: 14px;
	color: #414141;
}

.productlist-container .catalogue {
	width: 85%;
	max-width: 1700px;
	display: flex;
	justify-content: space-between;
}

.productlist-container .catalogue .card-list-container {
	width: 75%;
}

.productlist-container .catalogue .card-list {
	width: 100%;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	padding: 0 0 0 1rem;
}

.productlist-container .catalogue .filter {
	width: 285px;
}

.productlist-container .catalogue .mobile-filter {
	display: none;
}
.mobile-filter-active {
	display: none;
}

@media (max-width: 1024px) {
	.productlist-container .catalogue {
		width: 95%;
	}
	.productlist-container .catalogue .card-list-container {
		width: 100%;
	}
}

@media (max-width: 680px) {
	.productlist-container .categorylist-breadcrumb .breadcrumb-menu .bd-menu-stats {
		display: none;
	}
	.productlist-container .catalogue {
		display: flex;
		justify-content: center;
		flex-direction: column;
	}
	.productlist-container .catalogue .filter {
		display: none;
	}
	.mobile-filter-active {
		display: flex;
		width: 100%;
		height: 50%;
	}
}

@media (max-width: 375px) {
	.productlist-container .catalogue .card-list {
		padding: 0;
	}
	.productlist-container .catalogue .card-list-container {
		width: 100%;
	}
}

.cart-container {
	width: 100%;
	/* background-color: #e7f1ff; */
	background-color: #f5f7fa;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	position: relative;
}

.cart-container .cart-section {
	width: 95%;
	margin-top: 30px;
	display: flex;
	flex-direction: row;
	justify-content: center;
}

.cart-container .cart-section .left {
	width: 66%;
	margin-right: 1rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
}

.cart-container .cart-section .left .head {
	width: 100%;
	height: 100px;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 1rem;
}

.cart-container .cart-section .left .head .head-text {
	width: 60%;
	height: 100%;
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
}

.cart-container .cart-section .left .head .head-text .sub {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	margin-right: 20px;
}

.cart-container .cart-section .left .head .head-text .sub p {
	font-size: 24px;
	color: #313131;
	margin-bottom: 6px;
}

.cart-container .cart-section .left .head .head-text .sub .line {
	width: 70px;
	height: 3px;
	background-color: #fb6b25;
	border-radius: 2px;
}

.cart-container .cart-section .left .head .head-text .item-count {
	width: 60px;
	height: 25px;
	background-color: #fb6b25;
	border-radius: 20px;
	margin-top: 5px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.cart-container .cart-section .left .head .head-text .item-count p {
	font-size: 12px;
	color: #fff;
	font-weight: 500;
	margin: 0;
}

.cart-container .cart-section .left .head .head-amt {
	width: 40%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	justify-content: flex-start;
}

.cart-container .cart-section .left .head .head-amt p {
	font-size: 24px;
	color: #313131;
}

.cart-container .cart-section .left .head .head-amt span {
	color: #bababa;
}

.cart-container .cart-section .left .body {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.cart-container .cart-section .right {
	width: 31%;
	height: 100%;
	margin-left: 1rem;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.cart-container .cart-section .right .coupon-box {
	width: 100%;
	height: 100px;
	display: flex;
}

.cart-container .cart-section .right .coupon-box .coupon {
	width: 100%;
	height: 90%;
	background-color: #fff;
	border: 1px solid #e2eaf5;
	border-radius: 5px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.cart-container .cart-section .right .coupon-box .coupon input {
	width: 60%;
	padding: 0.5rem;
	margin-right: 10px;
	border: 1px solid #bababa;
	border-radius: 5px;
}

.cart-container .cart-section .right .coupon-box .coupon input::placeholder {
	color: #bababa;
}

.cart-container .cart-section .right .coupon-box .coupon button {
	width: 20%;
	padding: 0.55rem;
	outline: none;
	border-radius: 5px;
	border: 1px solid #fb6b25;
	cursor: pointer;
	background-color: #fff;
	color: #fb6b25;
	font-size: 12px;
}

.cart-container .cart-section .right .process-box {
	width: 100%;
	height: 415px;
	background-color: #fff;
	border: 1px solid #e2eaf5;
	border-radius: 5px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
}

.cart-container .cart-section .right .process-box .process-header {
	width: 100%;
	height: 15%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	border-bottom: 1px solid #e2eaf5;
}

.cart-container .cart-section .right .process-box .process-header .step {
	position: relative;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 25%;
}

.cart-container .cart-section .right .process-box .process-header .step p {
	font-size: 13px;
	font-weight: 500;
	transition: 0.3s;
	color: #919191;
	cursor: default;
}

.cart-container .cart-section .right .process-box .process-header .step p.active {
	color: #10caa5;
	border-bottom: 2px solid #10caa5;
	padding-bottom: 2px;
	cursor: pointer;
}

.cart-container .cart-section .right .process-box .process-header .step p.done {
	color: #10caa5;
	cursor: pointer;
}

.cart-container .cart-section .right .process-box .process-header .process1 {
	border-bottom: 2px dashed #bababa;
	width: 12%;
	transition: border-bottom 0.5s ease-in-out;
	transform-origin: right;
}
.cart-container .cart-section .right .process-box .process-header .process1-active {
	border-bottom: 2px dashed #10caa5;
	width: 12%;
	transition: border-bottom 0.5s ease-in-out;
	transform-origin: left;
}
.cart-container .cart-section .right .process-box .process-header .process2 {
	border-bottom: 2px dashed #bababa;
	width: 12%;
	transition: border-bottom 0.5s ease-in-out;
	transform-origin: right;
}
.cart-container .cart-section .right .process-box .process-header .process2-active {
	border-bottom: 2px dashed #10caa5;
	width: 12%;
	transition: border-bottom 0.5s ease-in-out;
	transform-origin: left;
}

.cart-container .cart-section .right .process-box .process-body {
	width: 100%;
	height: 80%;
}

.cart-container .cart-section .right .process-box .process-body .section {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
}

.cart-container .cart-section .right .process-box .process-body .section .title {
	width: 100%;
	display: flex;
	padding: 1rem 2.5rem;
}

.cart-container .cart-section .right .process-box .process-body .section .title img {
	width: 20px;
	margin-right: 10px;
}

.cart-container .cart-section .right .process-box .process-body .section .title p {
	color: #fb6b25;
	font-size: 16px;
}

.cart-container .cart-section .right .process-box .process-body .section .body {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	padding: 0 2.5rem;
}

.cart-container .cart-section .right .process-box .process-body .section .body p {
	font-size: 13px;
	font-weight: 500;
	color: #717171;
	margin-bottom: 10px;
}

.cart-container .cart-section .right .process-box .process-body .section .body .line {
	width: 100%;
	height: 1px;
	background-color: #e5e5e5;
	margin-bottom: 10px;
}

.cart-container .cart-section .right .process-box .process-body .section .body .rent {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 15px;
}

.cart-container .cart-section .right .process-box .process-body .section .body .rent .points-container {
	position: relative;
	display: flex;
	align-items: center;
}

.cart-container .cart-section .right .process-box .process-body .section .body .rent .points-container .far.fa-circle {
	position: absolute;
	left: -20px;
	font-size: 16px;
	color: #c5c5c5;
	cursor: pointer;
}
.cart-container .cart-section .right .process-box .process-body .section .body .rent .points-container .fas.fa-check-circle {
	position: absolute;
	left: -20px;
	font-size: 16px;
	color: #10caa5;
	cursor: pointer;
}

.cart-container .cart-section .right .process-box .process-body .section .body .rent p {
	font-size: 14px;
	color: #616161;
	font-weight: normal;
	margin: 0;
}

.cart-container .cart-section .right .process-box .process-body .section .body .rent span {
	font-size: 14px;
	color: #313131;
	font-weight: 500;
}

.cart-container .cart-section .right .process-box .process-body .section .body .total {
	width: 100%;
	border: 1px solid #fb6b25;
	padding: 1rem 1rem;
	background-color: #f5f7fa;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 15px;
}

.cart-container .cart-section .right .process-box .process-body .section .body .total p {
	font-size: 16px;
	color: #616161;
	font-weight: normal;
	margin: 0;
}

.cart-container .cart-section .right .process-box .process-body .section .body .total span {
	font-size: 16px;
	color: #313131;
	font-weight: 500;
}

.cart-container .cart-section .right .process-box .process-body .section .body .next-button {
	width: 100%;
}

.cart-container .cart-section .right .process-box .process-body .section .body .next-button button {
	width: 100%;
	padding: 0.8rem 0;
	border: none;
	border-radius: 3px;
	background-color: #fb6b25;
	color: #fff;
	outline: none;
	cursor: pointer;
}

.cart-container .cart-section .right .process-box .process-body .address-section {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
}

.cart-container .cart-section .right .process-box .process-body .address-section .title {
	width: 100%;
	display: flex;
	padding: 1rem 2.5rem;
	align-items: center;
}

.cart-container .cart-section .right .process-box .process-body .address-section .title img {
	width: 20px;
	margin-right: 10px;
}

.cart-container .cart-section .right .process-box .process-body .address-section .title p {
	color: #fb6b25;
	font-size: 14px;
}

.cart-container .cart-section .right .process-box .process-body .address-section .title .address-type {
	display: flex;
	align-items: "center";
	margin: 0 0 0 auto;
}

.cart-container .cart-section .right .process-box .process-body .address-section .title .address-type h1 {
	color: #acadaa;
	font-size: 12px;
	font-weight: normal;
	margin: 0 0.25rem;
	cursor: pointer;
}

.cart-container .cart-section .right .process-box .process-body .address-section .title .address-type h1.active {
	color: #fb6b25;
	text-decoration: underline;
}

.cart-container .cart-section .right .process-box .process-body .address-section .body {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.cart-container .cart-section .right .process-box .process-body .address-section .body .address-card-list {
	width: 100%;
	height: 50%;
	margin-bottom: 5px;
	display: flex;
	align-items: center;
	flex-direction: column;
	overflow-y: scroll;
}
.cart-container .cart-section .right .process-box .process-body .address-section .body .address-card {
	width: 80%;
	height: 100%;
	padding: 1rem;
	border: 1px solid #e2eaf5;
	background-color: #f5f7fa;
	display: flex;
	border-radius: 3px;
	margin-bottom: 10px;
}

.cart-container .cart-section .right .process-box .process-body .address-section .body .address-card .check-address {
	width: 10%;
	display: flex;
	padding-top: 0.2rem;
}

.cart-container .cart-section .right .process-box .process-body .address-section .body .address-card .check-address i {
	color: #fb6b25;
	cursor: pointer;
}

.cart-container .cart-section .right .process-box .process-body .address-section .body .address-card .address-details {
	width: 70%;
	display: flex;
	flex-direction: column;
}

.cart-container .cart-section .right .process-box .process-body .address-section .body .address-card .address-details h1 {
	font-size: 14px;
	margin-bottom: 5px;
	color: #313131;
}

.cart-container .cart-section .right .process-box .process-body .address-section .body .address-card .address-details p {
	font-size: 13px;
	margin-bottom: 5px;
	color: #717171;
}

.cart-container .cart-section .right .process-box .process-body .address-section .body .address-card .address-actions {
	width: 20%;
	display: flex;
	justify-content: flex-end;
}

.cart-container .cart-section .right .process-box .process-body .address-section .body .address-card .address-actions i {
	font-size: 12px;
	margin-left: 10px;
	color: #717171;
	cursor: pointer;
}

.cart-container .cart-section .right .process-box .process-body .address-section .body .add-address {
	width: 100%;
	height: 60px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 5px;
}

.cart-container .cart-section .right .process-box .process-body .address-section .body .add-address .add-box {
	width: 80%;
	height: 100%;
	border: 1px dashed #e2eaf5;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
}

.cart-container .cart-section .right .process-box .process-body .address-section .body .add-address .add-box p {
	font-size: 14px;
	color: #fb6b25;
}

.cart-container .cart-section .right .process-box .process-body .address-section .body .next-back-button {
	width: 80%;
	height: 50px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.cart-container .cart-section .right .process-box .process-body .address-section .body .next-back-button button {
	width: 100%;
	padding: 0.8rem 0;
	border: none;
	border-radius: 3px;
	background-color: #fb6b25;
	color: #fff;
	outline: none;
	cursor: pointer;
}

.cart-container .cart-section .right .process-box .process-body .payment-section {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
}

.cart-container .cart-section .right .process-box .process-body .payment-section .title {
	width: 100%;
	display: flex;
	padding: 1rem 2.5rem;
}

.cart-container .cart-section .right .process-box .process-body .payment-section .title img {
	width: 20px;
	margin-right: 10px;
}

.cart-container .cart-section .right .process-box .process-body .payment-section .title p {
	color: #fb6b25;
	font-size: 16px;
}

.cart-container .cart-section .right .process-box .process-body .payment-section .body {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.cart-container .cart-section .right .process-box .process-body .payment-section .body .pay {
	width: 80%;
	height: 60px;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	border: 1px dashed #e2eaf5;
	padding: 0 1rem;
	cursor: pointer;
	margin-bottom: 10px;
}
.cart-container .cart-section .right .process-box .process-body .payment-section .body .pay i {
	color: #bababa;
}

.cart-container .cart-section .right .process-box .process-body .payment-section .body .pay i.active {
	color: #fb6b25;
}

.cart-container .cart-section .right .process-box .process-body .payment-section .body .pay img {
	width: 80px;
	margin-right: 50px;
}

.cart-container .cart-section .right .process-box .process-body .payment-section .body .pay p {
	font-size: 13px;
	color: #717171;
}

.cart-container .cart-section .right .process-box .process-body .payment-section .body .final-button {
	width: 80%;
	height: 50px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.cart-container .cart-section .right .process-box .process-body .payment-section .body .final-button button {
	width: 100%;
	padding: 0.8rem 0;
	border: none;
	border-radius: 3px;
	background-color: #fb6b25;
	color: #fff;
	outline: none;
	cursor: pointer;
}

.cart-container .modal-address {
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	opacity: 0;
	z-index: 999;
	visibility: hidden;
	transform: scale(1.1);
	transition: visibility 0s linear 0.25s, opacity 0.25s 0s, transform 0.25s;
	overflow: hidden;
}

.cart-container .modal-address-active {
	position: fixed;
	top: 0;
	width: 100%;
	height: 100vh;
	background-color: rgba(0, 0, 0, 0.5);
	opacity: 1;
	z-index: 999;
	transition: visibility 0s linear 0.25s, opacity 0.25s 0s, transform 0.25s;
}

.cart-container .modal-address .modal-content,
.cart-container .modal-address-active .modal-content {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background-color: white;
	width: 354px;
	height: 600px;
	border-radius: 0.5rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.5);
}

.cart-container .modal-address .modal-content .modal-header,
.cart-container .modal-address-active .modal-content .modal-header {
	width: 100%;
	height: 10%;
	padding: 0 15px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-bottom: 1px solid #d5d5d5;
}

.cart-container .modal-address .modal-content .modal-header p,
.cart-container .modal-address-active .modal-content .modal-header p {
	font-size: 16px;
	font-weight: 500;
	color: #fb6b25;
}

.cart-container .modal-address .modal-content .modal-header .modal-address-close-button,
.cart-container .modal-address-active .modal-content .modal-header .modal-address-close-button {
	width: 35px;
	height: 35px;
	text-align: center;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
}

.cart-container .modal-address .modal-content .modal-header .modal-address-close-button i,
.cart-container .modal-address-active .modal-content .modal-header .modal-address-close-button i {
	font-size: 25px;
	color: #fb6b25;
}

.cart-container .modal-address .modal-content .modal-body,
.cart-container .modal-address-active .modal-content .modal-body {
	width: 100%;
	height: 80%;
	padding: 0 25px;
	display: flex;
	flex-direction: column;
}

.cart-container .modal-address .modal-content .modal-body .modal-contact-details,
.cart-container .modal-address-active .modal-content .modal-body .modal-contact-details {
	width: 100%;
	height: 35%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding: 1rem 0;
}

.cart-container .modal-address .modal-content .modal-body .modal-contact-details p,
.cart-container .modal-address-active .modal-content .modal-body .modal-contact-details p {
	font-size: 12px;
	font-weight: 500;
}

.cart-container .modal-address .modal-content .modal-body .modal-contact-details input,
.cart-container .modal-address-active .modal-content .modal-body .modal-contact-details input {
	width: 100%;
	height: 40px;
	outline: none;
	border: 1px solid #bababa;
	border-radius: 5px;
	padding: 0 0.8rem;
}

.cart-container .modal-address .modal-content .modal-body .modal-contact-details input::placeholder,
.cart-container .modal-address-active .modal-content .modal-body .modal-contact-details input::placeholder {
	font-size: 12px;
	color: #717171;
}

.cart-container .modal-address .modal-content .modal-body .modal-address-details,
.cart-container .modal-address-active .modal-content .modal-body .modal-address-details {
	width: 100%;
	height: 65%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding: 0.8rem 0;
}

.cart-container .modal-address .modal-content .modal-body .modal-address-details p,
.cart-container .modal-address-active .modal-content .modal-body .modal-address-details p {
	font-size: 12px;
	font-weight: 500;
}

.cart-container .modal-address .modal-content .modal-body .modal-address-details input,
.cart-container .modal-address-active .modal-content .modal-body .modal-address-details input {
	width: 100%;
	height: 40px;
	outline: none;
	border: 1px solid #bababa;
	border-radius: 5px;
	padding: 0 0.8rem;
}

.cart-container .modal-address .modal-content .modal-body .modal-address-details #check-default,
.cart-container .modal-address-active .modal-content .modal-body .modal-address-details #check-default {
	width: 100%px;
	height: 20px;
	display: flex;
	align-items: center;
	justify-content: flex-start;
}

.cart-container .modal-address .modal-content .modal-body .modal-address-details #check-default input,
.cart-container .modal-address-active .modal-content .modal-body .modal-address-details #check-default input {
	width: 15px;
	margin-right: 10px;
}

.cart-container .modal-address .modal-content .modal-body .modal-address-details #check-default p,
.cart-container .modal-address-active .modal-content .modal-body .modal-address-details #check-default p {
	font-weight: normal;
	color: #717171;
}

.cart-container .modal-address .modal-content .modal-body .modal-address-details input::placeholder,
.cart-container .modal-address-active .modal-content .modal-body .modal-address-details input::placeholder {
	font-size: 12px;
	color: #717171;
}

.cart-container .modal-address .modal-content .modal-footer,
.cart-container .modal-address-active .modal-content .modal-footer {
	width: 100%;
	height: 10%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.cart-container .modal-address .modal-content .modal-footer button,
.cart-container .modal-address-active .modal-content .modal-footer button {
	width: 90%;
	height: 42px;
	border-radius: 5px;
	background-color: #fb6b25;
	color: #fff;
	border: none;
	cursor: pointer;
}

/* Modal Verfiy */

.cart-container .modal-verify {
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	opacity: 0;
	z-index: 999;
	visibility: hidden;
	transform: scale(1.1);
	transition: visibility 0s linear 0.25s, opacity 0.25s 0s, transform 0.25s;
	overflow: hidden;
}

.cart-container .modal-verify-active {
	position: fixed;
	top: 0;
	width: 100%;
	height: 100vh;
	background-color: rgba(0, 0, 0, 0.5);
	opacity: 1;
	z-index: 999;
	transition: visibility 0s linear 0.25s, opacity 0.25s 0s, transform 0.25s;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.cart-container .modal-verify .modal-content,
.cart-container .modal-verify-active .modal-content {
	background-color: white;
	width: 360px;
	padding: 1rem;
	border-radius: 0.5rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.5);
	margin-top: 10vh;
}

.cart-container .modal-verify .modal-content .modal-header,
.cart-container .modal-verify-active .modal-content .modal-header {
	width: 100%;
	height: 10%;
	padding: 15px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-bottom: 1px solid #d5d5d5;
}

.cart-container .modal-verify .modal-content .modal-header p,
.cart-container .modal-verify-active .modal-content .modal-header p {
	font-size: 16px;
	font-weight: 500;
	color: #fb6b25;
}

.cart-container .modal-verify .modal-content .modal-header .modal-verify-close-button,
.cart-container .modal-verify-active .modal-content .modal-header .modal-verify-close-button {
	width: 35px;
	height: 35px;
	text-align: center;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
}

.cart-container .modal-verify .modal-content .modal-header .modal-verify-close-button i,
.cart-container .modal-verify-active .modal-content .modal-header .modal-verify-close-button i {
	font-size: 25px;
	color: #fb6b25;
}

.cart-container .modal-verify .modal-content .modal-body,
.cart-container .modal-verify-active .modal-content .modal-body {
	width: 100%;
	padding: 0 25px;
	display: flex;
	flex-direction: column;
}

.cart-container .modal-verify .modal-content .modal-body .modal-contact-details,
.cart-container .modal-verify-active .modal-content .modal-body .modal-contact-details {
	width: 100%;
	display: flex;
	flex-direction: column;
	padding: 1rem 0;
}

.cart-container .modal-verify-active .modal-content .modal-body .modal-contact-details .verification-cont {
	margin-bottom: 1rem;
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
	width: 100%;
}

.cart-container .modal-verify-active .modal-content .modal-body .modal-contact-details .verification-cont .code-verification {
	margin: 0.5rem 0;
	height: 45px;
	width: 19%;
	display: flex;
	flex-direction: column;
}

.cart-container .modal-verify-active .modal-content .modal-body .modal-contact-details .verification-cont .code-container {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin: 15px 0;
	width: 100%;
}

.cart-container .modal-verify-active .modal-content .modal-body .modal-contact-details .verification-cont .code-verification input {
	width: 50px;
	height: 100%;
	text-align: center;
	background-color: #f4f6f8;
	border: none;
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
	font-size: 25px;
	border: 1px solid lightgray;
	border-radius: 5px;
}

.cart-container .modal-verify .modal-content .modal-body .modal-contact-details p,
.cart-container .modal-verify-active .modal-content .modal-body .modal-contact-details p {
	font-size: 12px;
	font-weight: 500;
}

.cart-container .modal-verify .modal-content .modal-body .modal-contact-details .contact-input,
.cart-container .modal-verify-active .modal-content .modal-body .modal-contact-details .contact-input {
	width: 100%;
	height: 40px;
	outline: none;
	border: 1px solid #bababa;
	border-radius: 5px;
	padding: 0 0.8rem;
	margin: 0.5rem 0;
}

.cart-container .modal-verify .modal-content .modal-body .modal-contact-details input,
.cart-container .modal-verify-active .modal-content .modal-body .modal-contact-details input {
	width: 100%;
}

.cart-container .modal-verify .modal-content .modal-body .modal-contact-details .contact-input::placeholder,
.cart-container .modal-verify-active .modal-content .modal-body .modal-contact-details .contact-input::placeholder {
	font-size: 12px;
	color: #717171;
}

.cart-container .modal-verify .modal-content .modal-body .modal-verify-details,
.cart-container .modal-verify-active .modal-content .modal-body .modal-verify-details {
	width: 100%;
	height: 65%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding: 0.8rem 0;
}

.cart-container .modal-verify .modal-content .modal-body .modal-verify-details p,
.cart-container .modal-verify-active .modal-content .modal-body .modal-verify-details p {
	font-size: 12px;
	font-weight: 500;
}

.cart-container .modal-verify .modal-content .modal-body .modal-verify-details input,
.cart-container .modal-verify-active .modal-content .modal-body .modal-verify-details input {
	width: 100%;
	height: 40px;
	outline: none;
	border: 1px solid #bababa;
	border-radius: 5px;
	padding: 0 0.8rem;
}

.cart-container .modal-verify .modal-content .modal-body .modal-verify-details input::placeholder,
.cart-container .modal-verify-active .modal-content .modal-body .modal-verify-details input::placeholder {
	font-size: 12px;
	color: #717171;
}

.cart-container .modal-verify .modal-content .modal-footer,
.cart-container .modal-verify-active .modal-content .modal-footer {
	width: 100%;
	height: 10%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.cart-container .modal-verify .modal-content .modal-footer button,
.cart-container .modal-verify-active .modal-content .modal-footer button {
	width: 90%;
	height: 42px;
	border-radius: 5px;
	background-color: #fb6b25;
	color: #fff;
	border: none;
	cursor: pointer;
}

@media (max-width: 768px) {
	.cart-container .cart-section {
		width: 100%;
		flex-direction: column;
	}

	.cart-container .cart-section .left {
		width: 100%;
	}

	.cart-container .cart-section .right {
		width: 100%;
		margin-left: 0;
	}
}

.prod-card {
	width: 100%;
	height: 200px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 20px;
	border: 1px solid #e2eaf5;
	border-radius: 5px;
	background-color: #fff;
	padding: 2rem;
}

.card-content {
	width: 100%;
	height: 100%;
	display: flex;
}

.prod-card .card-content .left-img {
	width: 20%;
	height: 100%;
}

.prod-card .card-content .left-img img {
	width: 100%;
	height: 100%;
	object-fit: contain;
}

.prod-card .card-content .right-details {
	width: 80%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	justify-content: space-between;
}

.prod-card .card-content .right-details .item-name {
	width: 95%;
	display: flex;
	justify-content: space-between;
}

.prod-card .card-content .right-details .item-name p {
	color: #313131;
}

.prod-card .card-content .right-details .item-name .delete {
	display: flex;
	align-items: center;
	cursor: pointer;
	padding: 5px;
}

.prod-card .card-content .right-details .item-name .delete i {
	font-size: 12px;
	margin-right: 5px;
	color: red;
}

.prod-card .card-content .right-details .item-name .delete p {
	font-size: 12px;
	color: red;
}

.prod-card .card-content .right-details .item-price-time {
	width: 95%;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.prod-card .card-content .right-details .item-price-time .tenure {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.prod-card .card-content .right-details .item-price-time .tenure p {
	font-size: 14px;
	color: #464646;
	display: flex;
	align-items: center;
}

.prod-card .card-content .right-details .item-price-time .tenure p .qbtn {
	width: 30px;
	height: 30px;
	border: none;
	border-radius: 50%;
	margin: 0 5px;
	display: flex;
	align-items: center;
	justify-content: center;
	outline: none;
	cursor: pointer;
	background-color: transparent;
}

.prod-card .card-content .right-details .item-price-time .tenure p .qbtn p {
	font-size: 1.2rem;
	transform: translate(0.5px, -1px);
}

.prod-card .card-content .right-details .item-price-time .tenure span {
	font-size: 12px;
	font-weight: 500;
	color: #acadaa;
}

.prod-card .card-content .right-details .item-delivery {
	width: 95%;
	display: flex;
	align-items: center;
	justify-content: fl;
	border: 1px solid #fb6b25;
	padding: 0.3rem 0.5rem;
	border-radius: 5px;
}

.prod-card .card-content .right-details .item-delivery img {
	width: 20px;
	margin-right: 10px;
}

.prod-card .card-content .right-details .item-delivery p {
	font-size: 13px;
	color: #515151;
	margin-right: 5px;
}

.prod-card .card-content .right-details .item-delivery i {
	font-size: 6px;
	border-radius: 50%;
	border: 0.65px solid #fb6b25;
	color: #fb6b25;
	width: 12px;
	height: 12px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0 10px;
	cursor: pointer;
}

@media screen and (max-width: 680px) {
	.prod-card .card-content .right-details .item-price-time .tenure:nth-child(3) {
		display: none;
	}
}

.contact-container {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.contact-container .contact-form-container {
	width: 100%;
	max-width: 400px;
	background-color: #f6f6f6;
	margin: 1rem 0;
	padding: 2rem;
	border-radius: 10px;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.contact-container .contact-form-container h1 {
	font-size: 1.5rem;
}

.contact-container .contact-form-container .form-inputs {
	margin: 0.5rem 0;
	width: 100%;
}

.contact-container .contact-form-container .form-inputs p {
	font-size: 1rem;
	font-weight: 600;
	margin: 0;
	margin-bottom: 0.5rem;
}

.contact-container .contact-form-container .form-inputs .form-input {
	width: 100%;
	height: 40px;
	outline: none;
	border: 1px solid #c5c5c5;
	padding: 0 0.5rem;
	border-radius: 5px;
}


.contact-container .contact-form-container .form-inputs input{
	width: 100%;
}

.contact-container .contact-form-container .form-inputs textarea {
	width: 100%;
	height: 100px;
	resize: none;
	outline: none;
	border: 1px solid #c5c5c5;
	padding: 0.5rem;
	border-radius: 5px;
}

.contact-container .contact-form-container button {
	width: 120px;
	height: 40px;
	font-size: 1rem;
	background-color: #ef6f0b;
	border-radius: 5px;
	color: #fff;
	outline: none;
	border: none;
	cursor: pointer;
}

.contact-container {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.contact-container .contact-form-container {
	width: 100%;
	max-width: 400px;
	background-color: #f6f6f6;
	margin: 1rem 0;
	padding: 2rem;
	border-radius: 10px;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.contact-container .contact-form-container h1 {
	font-size: 1.5rem;
}

.contact-container .contact-form-container .form-inputs {
	margin: 0.5rem 0;
	width: 100%;
}

.contact-container .contact-form-container .form-inputs p {
	font-size: 1rem;
	font-weight: 600;
	margin: 0;
	margin-bottom: 0.5rem;
}

.contact-container .contact-form-container .form-inputs .form-input {
	width: 100%;
	height: 40px;
	outline: none;
	border: 1px solid #c5c5c5;
	padding: 0 0.5rem;
	border-radius: 5px;
}


.contact-container .contact-form-container .form-inputs input{
	width: 100%;
}

.contact-container .contact-form-container .form-inputs textarea {
	width: 100%;
	height: 100px;
	resize: none;
	outline: none;
	border: 1px solid #c5c5c5;
	padding: 0.5rem;
	border-radius: 5px;
}

.contact-container .contact-form-container button {
	width: 120px;
	height: 40px;
	font-size: 1rem;
	background-color: #ef6f0b;
	border-radius: 5px;
	color: #fff;
	outline: none;
	border: none;
	cursor: pointer;
}

.contact-container {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.contact-container .contact-form-container {
	width: 100%;
	max-width: 400px;
	background-color: #f6f6f6;
	margin: 1rem 0;
	padding: 2rem;
	border-radius: 10px;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.contact-container .contact-form-container h1 {
	font-size: 1.5rem;
}

.contact-container .contact-form-container .form-inputs {
	margin: 0.5rem 0;
	width: 100%;
}

.contact-container .contact-form-container .form-inputs p {
	font-size: 1rem;
	font-weight: 600;
	margin: 0;
	margin-bottom: 0.5rem;
}

.contact-container .contact-form-container .form-inputs .form-input {
	width: 100%;
	height: 40px;
	outline: none;
	border: 1px solid #c5c5c5;
	padding: 0 0.5rem;
	border-radius: 5px;
}


.contact-container .contact-form-container .form-inputs input{
	width: 100%;
}

.contact-container .contact-form-container .form-inputs textarea {
	width: 100%;
	height: 100px;
	resize: none;
	outline: none;
	border: 1px solid #c5c5c5;
	padding: 0.5rem;
	border-radius: 5px;
}

.contact-container .contact-form-container button {
	width: 120px;
	height: 40px;
	font-size: 1rem;
	background-color: #ef6f0b;
	border-radius: 5px;
	color: #fff;
	outline: none;
	border: none;
	cursor: pointer;
}

.productlist-container {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background-color: #fff;
}

.productlist-container .categorylist-breadcrumb {
	width: 100%;
	height: 50px;
	background-color: #f7f7f7;
	border-bottom: 1.2px solid #e5e5e5;
	box-shadow: inset 0px 1px 3px 0px rgba(0, 0, 0, 0.2);
	margin-bottom: 20px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.productlist-container .categorylist-breadcrumb .breadcrumb-menu {
	width: 85%;
	max-width: 1700px;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.productlist-container .categorylist-breadcrumb .breadcrumb-menu .bd-menu-list {
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-start;
}

.productlist-container .categorylist-breadcrumb .breadcrumb-menu .bd-menu-list a {
	font-size: 14px;
	margin-right: 14px;
	text-decoration: none;
	color: #414141;
	text-transform: capitalize;
}

.productlist-container .categorylist-breadcrumb .breadcrumb-menu .bd-menu-list a i {
	font-size: 10px;
}

.productlist-container .categorylist-breadcrumb .breadcrumb-menu .bd-menu-stats {
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

.productlist-container .categorylist-breadcrumb .breadcrumb-menu .bd-menu-stats p {
	font-size: 14px;
	color: #414141;
}

.productlist-container .catalogue {
	width: 85%;
	max-width: 1700px;
	display: flex;
	justify-content: space-between;
}

.productlist-container .catalogue .card-list-container {
	width: 75%;
}

.productlist-container .catalogue .card-list {
	width: 100%;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	padding: 0 0 0 1rem;
}

.productlist-container .catalogue .filter {
	width: 285px;
}

.productlist-container .catalogue .mobile-filter {
	display: none;
}
.mobile-filter-active {
	display: none;
}

@media (max-width: 1024px) {
	.productlist-container .catalogue {
		width: 95%;
	}
	.productlist-container .catalogue .card-list-container {
		width: 100%;
	}
}

@media (max-width: 680px) {
	.productlist-container .categorylist-breadcrumb .breadcrumb-menu .bd-menu-stats {
		display: none;
	}
	.productlist-container .catalogue {
		display: flex;
		justify-content: center;
		flex-direction: column;
	}
	.productlist-container .catalogue .filter {
		display: none;
	}
	.mobile-filter-active {
		display: flex;
		width: 100%;
		height: 50%;
	}
}

@media (max-width: 375px) {
	.productlist-container .catalogue .card-list {
		padding: 0;
	}
	.productlist-container .catalogue .card-list-container {
		width: 100%;
	}
}

.trackorder-container {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.trackorder-container .trackorder-form-container {
	width: 100%;
	max-width: 400px;
	background-color: #f6f6f6;
	margin: 1rem 0;
	padding: 2rem;
	border-radius: 10px;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.trackorder-container .trackorder-form-container h1 {
	font-size: 1.5rem;
}

.trackorder-container .trackorder-form-container .form-inputs {
	margin: 0.5rem 0;
	width: 100%;
}

.trackorder-container .trackorder-form-container .form-inputs p {
	font-size: 1rem;
	font-weight: 600;
	margin: 0;
	margin-bottom: 0.5rem;
}

.trackorder-container .trackorder-form-container .form-inputs .form-input {
	width: 100%;
	height: 40px;
	outline: none;
	border: 1px solid #c5c5c5;
	padding: 0 0.5rem;
	border-radius: 5px;
}

.trackorder-container .trackorder-form-container .form-inputs input {
	width: 100%;
}

.trackorder-container .trackorder-form-container .form-inputs textarea {
	width: 100%;
	height: 100px;
	resize: none;
	outline: none;
	border: 1px solid #c5c5c5;
	padding: 0.5rem;
	border-radius: 5px;
}

.trackorder-container .trackorder-form-container button {
	width: 120px;
	height: 40px;
	font-size: 1rem;
	background-color: #ef6f0b;
	border-radius: 5px;
	color: #fff;
	outline: none;
	border: none;
	cursor: pointer;
}

.terms_conditions-container {
  padding: 5rem 4rem;
  background-color: #fff;
}

.terms_conditions-container .info .sub-header {
  padding-top: 1rem;
}

.terms_conditions-container .info .sub-header-1 {
  margin: 1rem 0;
}

.terms_conditions-container .info .text {
  line-height: 32px;
}

.terms_conditions-container .info .text p b {
  text-decoration: underline;
}

.terms_conditions-container .info .text .sub-text {
  padding: 1rem 0rem 1rem 2rem;
}

@media only screen and (max-width: 769px) {
  .terms_conditions-container {
    padding: 4rem 3rem;
  }
}

@media only screen and (max-width: 426px) {
  .terms_conditions-container {
    padding: 3rem 2rem;
  }

  .terms_conditions-container .header h1 {
    font-size: 24px;
  }

  .terms_conditions-container .info .sub-header {
    padding-top: 0.5rem;
  }

  .terms_conditions-container .info .sub-header h3 {
    font-size: 16px;
  }

  .terms_conditions-container .info .text {
    line-height: 20px;
  }

  .terms_conditions-container .info .text p {
    font-size: 14px;
  }
}

.shippingpolicy-container {
	padding: 5rem 4rem;
	background-color: #fff;
}

.shippingpolicy-container .info .sub-header {
	padding-top: 1rem;
}

.shippingpolicy-container .info .sub-header-1 {
	margin: 1rem 0;
}

.shippingpolicy-container .info .text {
	line-height: 32px;
}

.shippingpolicy-container .info .text p b {
	text-decoration: underline;
}

.shippingpolicy-container .info .text .sub-text {
	padding: 1rem 0rem 1rem 2rem;
}

@media only screen and (max-width: 769px) {
	.shippingpolicy-container {
		padding: 4rem 3rem;
	}
}

@media only screen and (max-width: 426px) {
	.shippingpolicy-container {
		padding: 3rem 2rem;
	}

	.shippingpolicy-container .header h1 {
		font-size: 24px;
	}

	.shippingpolicy-container .info .sub-header {
		padding-top: 0.5rem;
	}

	.shippingpolicy-container .info .sub-header h3 {
		font-size: 16px;
	}

	.shippingpolicy-container .info .text {
		line-height: 20px;
	}

	.shippingpolicy-container .info .text p {
		font-size: 14px;
	}
}

