.shippingpolicy-container {
	padding: 5rem 4rem;
	background-color: #fff;
}

.shippingpolicy-container .info .sub-header {
	padding-top: 1rem;
}

.shippingpolicy-container .info .sub-header-1 {
	margin: 1rem 0;
}

.shippingpolicy-container .info .text {
	line-height: 32px;
}

.shippingpolicy-container .info .text p b {
	text-decoration: underline;
}

.shippingpolicy-container .info .text .sub-text {
	padding: 1rem 0rem 1rem 2rem;
}

@media only screen and (max-width: 769px) {
	.shippingpolicy-container {
		padding: 4rem 3rem;
	}
}

@media only screen and (max-width: 426px) {
	.shippingpolicy-container {
		padding: 3rem 2rem;
	}

	.shippingpolicy-container .header h1 {
		font-size: 24px;
	}

	.shippingpolicy-container .info .sub-header {
		padding-top: 0.5rem;
	}

	.shippingpolicy-container .info .sub-header h3 {
		font-size: 16px;
	}

	.shippingpolicy-container .info .text {
		line-height: 20px;
	}

	.shippingpolicy-container .info .text p {
		font-size: 14px;
	}
}
