.card {
	margin-top: 10px;
	background-color: #fff;
	padding: 10px 15px;
	padding-bottom: 0;
	box-shadow: 2px 2px 6px darkgray;
	/* box-shadow: 0 2px 6px 0 #dee0e2; */
	box-shadow: 0 2px 6px 0 #dee0e2;
	margin: 1rem auto;
	border-radius: 3px;
	max-width: 1700px;
	width: 98%;
}

.card .card-title {
	height: 20%;
	width: 100%;
	display: flex;
	padding-top: 0%;
	justify-content: space-between;
	align-items: center;
	padding-bottom: 1rem;
}

.card .card-title .card-title-mini p {
	font-weight: 600;
	color: rgba(0, 0, 0, 0.8);
	font-size: 21px;
}

.card .card-title .card-title-mini .lines {
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-top: 7px;
}

.card .card-title .card-title-mini .lines .line-through {
	width: 16px;
	height: 1px;
	background-color: rgba(0, 0, 0, 0.8);
	margin: 3px 0;
}

.card .card-title .card-title-mini .lines .rotated-line {
	transform: rotate(-45deg);
	display: inline-block;
	margin: 0 5px;
}

.card .card-title .card-title-mini .lines .horizontal {
	width: 60px;
	height: 1px;
	background-color: rgba(0, 0, 0, 0.6);
	/* display: inline-block; */
}

.card .card-title a {
	height: 40px;
	width: 90px;
	background-color: #fb641b;
	font-size: 14px;
	border-radius: 3px;
	color: white;
	text-decoration: none;
	text-align: center;
	padding: 4px;
	font-weight: 400;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
}

.swipe {
	overflow: hidden;
	visibility: hidden;
	position: relative;
}
.swipe-wrap {
	overflow: hidden;
	position: relative;
}

.card .slider {
	/* height: 80%; */
}

.card .slider .swiper-container {
	width: 100%;
}

.card .slider .swiper-container .swiper-wrapper {
	width: 100%;
	margin-bottom: 1rem;
}

.card .slider .swiper-container .swiper-wrapper .swiper-slide {
	height: 300px;
	width: 240px;
	margin-right: 1rem;
}

.swiper-button-next {
	color: black !important;
	height: 40px !important;
	width: 40px !important;
	border-radius: 50%;
	background-color: #f6f6f6;
	box-shadow: 0 0 10px 0 gray;
}

.swiper-button-next::after {
	font-size: 24px !important;
	transform: translate(25%, 0);
}

.swiper-button-prev {
	color: black !important;
	height: 40px !important;
	width: 40px !important;
	border-radius: 50%;
	background-color: #f6f6f6;
	box-shadow: 0 0 10px 0 gray;
}

.swiper-button-prev::after {
	font-size: 24px !important;
	transform: translate(-15%, 0);
}

@media (max-width: 768px) {
	.card .card-title {
		padding: 10px 10px;
	}

	.card .card-title .card-title-mini p {
		font-size: 16px;
	}

	.card .card-title a {
		/* width: 10%;
        height: 100%; */
		font-size: 16px !important;
	}

	.card .card-title .card-title-mini .lines .line-through {
		width: 12px;
		margin: 1px 0;
	}

	.card .card-title .card-title-mini .lines .horizontal {
		width: 25px;
	}

	.card .slider .swiper-container .swiper-wrapper .swiper-slide {
		height: auto;
	}

	.swiper-button-next {
		display: none !important;
	}

	.swiper-button-prev {
		display: none !important;
	}
}

@media (max-width: 468px) {
	.card .card-title a {
		font-size: 6px;
	}
}
