.order-card {
	width: 98%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 10px;
	border: 1px solid #e2eaf5;
	border-radius: 5px;
	background-color: #fff;
	padding: 1rem;
	cursor: pointer;
	flex-wrap: wrap;
}

.order-card .product-section1 {
	display: flex;
	height: 100%;
	width: 40%;
}

.order-card .product-section1 .product-image {
	width: 100px;
}

.order-card .product-section1 .product-image img {
	width: 100%;
	object-fit: contain;
}

.order-card .product-section1 .product-info {
	display: flex;
	flex-direction: column;
	margin-left: 1rem;
	height: 100%;
}

.order-card .product-section1 .product-info .product-title {
	font-size: 0.9rem;
}

.order-card .product-section1 .product-info .product-color {
	color: #a5a5a5;
	font-size: 0.8rem;
	margin-top: 0.25rem;
}

.order-card .product-section1 .product-info .product-quantity {
	color: #a5a5a5;
	font-size: 0.8rem;
}

.order-card .product-section1 .product-info .product-size {
	color: #a5a5a5;
	font-size: 0.8rem;
}

.order-card .product-section1 .product-info .product-size span {
	text-transform: uppercase;
}

.order-card .product-section2 {
	display: flex;
	height: 100%;
	flex-direction: column;
	width: 20%;
	align-items: center;
}

.order-card .product-section2 p {
	font-size: 0.9rem;
	font-weight: 600;
}

.order-card .product-section3 {
	display: flex;
	flex-direction: column;
	height: 100%;
	width: 40%;
	align-items: flex-end;
}

.order-card .product-section3 .status-info {
	display: flex;
	align-items: center;
}

.order-card .product-section3 .status-info .status-indicator {
	width: 10px;
	height: 10px;
	border-radius: 50%;
}

.order-card .product-section3 .status-info .status-indicator.green {
	background-color: #26a541;
}

.order-card .product-section3 .status-info .status-indicator.yellow {
	background-color: #ff9f00;
}

.order-card .product-section3 .status-info .status-indicator.red {
	background-color: #ff6161;
}

.order-card .product-section3 .status-info p {
	font-size: 0.9rem;
	margin-left: 0.25rem;
}

.order-card .product-section3 .product-status-message {
	font-size: 0.8rem;
	margin-top: 0.5rem;
}

.order-card .product-section3 .product-rating {
	display: flex;
	align-content: center;
	margin-top: 0.25rem;
}

.order-card .product-section3 .product-rating i {
	color: #fb641b;
	margin: 2px;
	font-size: 20px;
}

.order-card .product-section3 .product-rating i.active {
	color: #fb641b;
}

@media (max-width: 680px) {
	.order-card {
		flex-direction: column;
	}

	.order-card .product-section1,
	.order-card .product-section2,
	.order-card .product-section3 {
		width: 100%;
		align-items: start;
		margin: 0.25rem 0;
	}
}
