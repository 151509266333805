.Viewall{
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    padding: 2rem;
    min-height: 55vh;
}

.Viewall .load{
  width: 100%;
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Viewall p {
    font-weight: 600;
    color: rgba(0, 0, 0, 0.8);
    font-size: 24px;
  }
  
.Viewall .lines {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 7px;
  }
  
.Viewall .lines .line-through {
    width: 20px;
    height: 1px;
    background-color: rgba(0, 0, 0, 0.8);
    margin: 3px 0;
  }
  
.Viewall .lines .rotated-line {
    transform: rotate(-45deg);
    display: inline-block;
    margin: 0 5px;
  }
  
.Viewall .lines .horizontal {
    width: 68px;
    height: 1px;
    background-color: rgba(0, 0, 0, 0.6);
    /* display: inline-block; */
  }

.Viewall .ViewallProducts{
    margin-top: 2rem;
    display: flex;
    flex-wrap: wrap;
}

@media only screen and (max-width: 580px){
  .Viewall{
    padding: 0.5rem !important;
 }
}