.subcategory-list-container {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
}

.subcategory-list-container .subcategory-header {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: space-evenly;
	margin-bottom: 20px;
}

.subcategory-list-container .subcategory-header p {
	font-size: 22px;
	margin: 0 0 0.5rem 0;
}

.subcategory-list-container .subcategory-header .underline {
	width: 100px;
	height: 5px;
	background-color: #fb641b;
	border-radius: 10px;
}

.subcategory-list-container .subcategory-grids {
	width: 100%;
	display: flex;
	align-items: center;
	flex-wrap: wrap;
}

.subcategory-list-container .subcategory-grids .sub {
	width: 40vw;
	height: 40vw;
	position: relative;
	cursor: pointer;
	margin: 0.3rem;
	transform: translateY(0px);
	transition: transform 0.3s ease-in;
	max-width: 270px;
	max-height: 270px;
}

.subcategory-list-container .subcategory-grids .sub:hover {
	box-shadow: 2px 2px 6px 0px #e5e5e5;
	transform: translateY(-10px);
	transition: transform 0.3s ease-in;
	border-radius: 10px;
}

.subcategory-list-container .subcategory-grids .sub img {
	width: 100%;
	height: 100%;
	border-radius: 10px;
}

.subcategory-list-container .subcategory-grids .sub .room-name {
	background-color: white;
	padding: 0.3rem 0.8rem;
	position: absolute;
	top: 0;
	left: 0;
	border-top-right-radius: 0;
	border-top-left-radius: 10px;
	border-bottom-right-radius: 10px;
	border-bottom-left-radius: 0;
}

.subcategory-list-container .subcategory-grids .sub:nth-child(even) .room-name {
	background-color: white;
	padding: 0.3rem 0.8rem;
	position: absolute;
	top: 0;
	left: auto;
	right: 0;
	border-top-right-radius: 10px;
	border-top-left-radius: 0;
	border-bottom-right-radius: 0;
	border-bottom-left-radius: 10px;
}

.subcategory-list-container .subcategory-grids .sub .room-name p {
	font-size: 16px;
	color: #464646;
	font-weight: 500;
}
