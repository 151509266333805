.main {
	width: 100%;
	background-color: #eef0f3;
	position: relative;
	overflow: hidden;
}

.main .load {
	margin-top: 10px;
	height: 79vh;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #fff;
}
