.prod-card2 {
	width: 100%;
	height: 160px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 20px;
	border: 1px solid #e2eaf5;
	border-radius: 5px;
	background-color: #fff;
	padding: 2rem;
}

.card-content {
	width: 100%;
	height: 100%;
	display: flex;
}

.prod-card2 .card-content .left-img {
	width: 20%;
	height: 100%;
}

.prod-card2 .card-content .left-img img {
	width: 100%;
	max-height: 120px;
	object-fit: contain;
}

.prod-card2 .card-content .right-details {
	width: 80%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	justify-content: space-between;
}

.prod-card2 .card-content .right-details .item-name {
	width: 95%;
	display: flex;
	justify-content: space-between;
}

.prod-card2 .card-content .right-details .item-name p {
	color: #313131;
}

.prod-card2 .card-content .right-details .item-name .delete {
	display: flex;
	align-items: center;
	cursor: pointer;
}

.prod-card2 .card-content .right-details .item-name .delete img {
	width: 20px;
}

.prod-card2 .card-content .right-details .item-name .delete p {
	font-size: 12px;
	color: red;
}

.prod-card2 .card-content .right-details .item-price-time {
	width: 95%;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.prod-card2 .card-content .right-details .item-price-time .tenure {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.prod-card2 .card-content .right-details .item-price-time .tenure p {
	font-size: 14px;
	color: #464646;
	display: flex;
	align-items: center;
}

.prod-card2 .card-content .right-details .item-price-time .tenure p .qbtn {
	width: 30px;
	height: 30px;
	border: 2px solid #fb6b25;
	border-radius: 50%;
	margin: 0 5px;
	display: flex;
	align-items: center;
	justify-content: center;
	outline: none;
	cursor: pointer;
}

.prod-card2 .card-content .right-details .item-price-time .tenure p .qbtn p {
	font-size: 1.2rem;
	transform: translate(0.5px, -1px);
}

.prod-card2 .card-content .right-details .item-price-time .tenure span {
	font-size: 12px;
	font-weight: 500;
	color: #acadaa;
}

.prod-card2 .card-content .right-details .item-delivery {
	width: 95%;
	display: flex;
	align-items: center;
	justify-content: fl;
	border: 1px solid #fb6b25;
	padding: 0.3rem 0.5rem;
	border-radius: 5px;
}

.prod-card2 .card-content .right-details .item-delivery img {
	width: 20px;
	margin-right: 10px;
}

.prod-card2 .card-content .right-details .item-delivery p {
	font-size: 13px;
	color: #515151;
	margin-right: 5px;
}

.prod-card2 .card-content .right-details .item-delivery i {
	font-size: 6px;
	border-radius: 50%;
	border: 0.65px solid #fb6b25;
	color: #fb6b25;
	width: 12px;
	height: 12px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0 10px;
	cursor: pointer;
}

@media screen and (max-width: 680px) {
	.prod-card2 .card-content .right-details .item-price-time .tenure:nth-child(3) {
		display: none;
	}
}
