.menu-ham {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: row;
	transform: translateX(-100%);
}

.menu-ham.active {
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 1000;
	transform: translateX(0%);
}

.blank {
	width: 100%;
	height: 100%;
}

.hide {
	display: none !important;
}

.menu-ham .hamburger-menu {
	width: 300px;
	overflow-y: scroll;
	scrollbar-width: none;
	background-color: #fff;
	padding: 0px;
	transform: translateX(-100%);
	transition: transform 0.5s ease-in-out;
}

.menu-ham .hamburger-menu::-webkit-scrollbar {
	width: 0;
	height: 0;
}

.menu-ham .hamburger-menu.active {
	transform: translateX(0%);
	transition: transform 0.5s ease-in-out;
}

.menu-ham .hamburger-menu .head {
	height: 12vh;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	border-bottom: 2px solid grey;
	padding: 7px;
}

.menu-ham .hamburger-menu .head .logo {
	height: 60px;
	width: 60px;
	margin-right: auto;
	display: flex;
	flex-direction: row;
	align-items: center;
}

.menu-ham .hamburger-menu .head .logo .logo-img {
	height: 100%;
	object-fit: contain;
}

.menu-ham .hamburger-menu .head i {
	position: absolute;
	top: 12px;
	right: 12px;
	font-size: 19px;
	cursor: pointer;
}

.menu-ham .hamburger-menu .ham-list {
	/* margin-top: 20px; */
	display: block;
}

.menu-ham .hamburger-menu .ham-list .MiniNav {
	display: flex;
	flex-direction: column;
}

.menu-ham .hamburger-menu .ham-list .MiniNav .MiniNav-category {
	display: flex;
	flex-direction: column;
}

.menu-ham .hamburger-menu .ham-list .MiniNav .MiniNav-category p {
	width: 100%;
	display: flex;
	justify-content: space-between;
}

.menu-ham .hamburger-menu .ham-list .MiniNav .MiniNav-category .subcategory-options {
	width: 100%;
	height: 0px;
	display: none;
	flex-direction: column;
	transition: height 0.5s;
}

.menu-ham .hamburger-menu .ham-list .MiniNav .MiniNav-category.opened .subcategory-options {
	height: fit-content;
	display: flex;
	transition: height 0.5s;
	margin-top: 10px;
}

.menu-ham .hamburger-menu .ham-list .MiniNav .MiniNav-category.opened .subcategory-options a {
	width: 100%;
	padding: 0.5rem 0;
	display: flex;
	padding-left: 10px;
	align-items: center;
	font-size: 15px;
	color: #464646;
	font-weight: 500;
}

.menu-ham .hamburger-menu .ham-list::-webkit-scrollbar {
	width: 0;
	height: 0;
}

.menu-ham .hamburger-menu .ham-list .box,
.menu-ham .hamburger-menu .ham-list #cart,
.menu-ham .hamburger-menu .ham-list #wishlist {
	padding: 1.5rem 15px;
	background-color: #fff;
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	border-bottom: 1px solid lightgray;
	text-decoration: none;
	color: #000;
	transition: height 0.5s;
	user-select: none;
}

.menu-ham .hamburger-menu .ham-list p {
	cursor: pointer;
}

.menu-ham .hamburger-menu .ham-list p i {
	transform: rotate(0deg);
	transition: transform 0.2s;
}

.menu-ham .hamburger-menu .ham-list .box:hover,
.menu-ham .hamburger-menu .ham-list #cart:hover,
.menu-ham .hamburger-menu .ham-list #wishlist:hover {
	/* background-color: #f5f5f5; */
}

.menu-ham .hamburger-menu .ham-list .box:active,
.menu-ham .hamburger-menu .ham-list #cart:active,
.menu-ham .hamburger-menu .ham-list #wishlist:active {
	/* background-color: grey; */
}

.menu-ham .hamburger-menu .ham-list .box span,
.menu-ham .hamburger-menu .ham-list .box p {
	text-decoration: none;
	font-size: 16px;
	font-weight: 500;
	color: black;
}

.menu-ham .hamburger-menu .ham-list .box.disappear {
	display: none !important;
}

.open-arrow {
	transform: rotate(180deg) !important;
	transition: transform 0.2s !important;
}

.menu-ham .hamburger-menu .ham-list #cart {
	display: none;
}

.hamburger-menu .ham-list #wishlist {
	display: none;
}

.menu-ham .hamburger-menu .ham-list .orange span,
.menu-ham .hamburger-menu .ham-list .orange p,
.menu-ham .hamburger-menu .ham-list .orange i {
	color: #fb641b;
}

@media (max-width: 1024px) {
	.menu-ham .hamburger-menu {
		width: 500px;
	}
}

@media (max-width: 680px) {
	.menu-ham .hamburger-menu {
		width: 600px;
	}

	.hide {
		display: flex !important;
	}

	.menu-ham .hamburger-menu .ham-list .box:hover,
	.menu-ham .hamburger-menu .ham-list #cart:hover,
	.menu-ham .hamburger-menu .ham-list #wishlist:hover {
		background-color: #fff;
	}

	.menu-ham .hamburger-menu .ham-list .box:active,
	.menu-ham .hamburger-menu .ham-list #cart:active,
	.menu-ham .hamburger-menu .ham-list #wishlist:active {
		background-color: #fff;
	}
}

@media (max-width: 568px) {
	.menu-ham .hamburger-menu .ham-list #cart {
		display: flex;
	}
	.hamburger-menu .ham-list #wishlist {
		display: flex;
	}
	.menu-ham .hamburger-menu .ham-list .box.disappear {
		display: flex !important;
	}
}
