.add {
  text-decoration: none;
  padding: 0;
  margin: 1rem auto;
  border-radius: 3px;
  max-width: 1700px;
  width: 98%;
}

.add img {
  width: 100%;
  object-fit: contain;
}

/* .add .add-button{
    background-color: white;
    color: black;
    position: absolute;
    width: 200px;
    height: 50px;
    bottom: 36%;
    right: 19%;
    font-size: 20px;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
}

@media (max-width: 525px){
    .add{
        height: 35vh;
    }

    .add img{
        object-position: right
    }

    .add .add-button{
        width: 120px; 
        height: 40px;
    }
} */
