.mini-container {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	box-shadow: 0 3px 6px 0 #dee0e2;
	background-color: #fff;
	align-items: center;
	position: sticky;
	top: 60px;
	z-index: 998;
}

.mini-container .mini-container-main {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 40px;
}

.mini-container .mini-content {
	font-size: 16px;
	color: black;
	font-weight: bold;
	text-decoration: none;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 100%;
	cursor: pointer;
	margin: 0 20px;
	position: relative;
}

.mini-container .mini-content .mini-content-context {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.mini-container .mini-content a {
	font-size: 16px;
	color: black;
	font-weight: bold;
	text-decoration: none;
}

.mini-container .mini-content i {
	margin-left: 5px;
}

.mini-container .mini-content .fa-chevron-down {
	transition: transform 0.2s;
	font-size: 10px;
}

.mini-container .mini-content:hover .fa-chevron-down {
	transform: rotate(180deg);
}

.mini-container .mini-content .category-options {
	background-color: #fff;
	position: absolute;
	display: none;
	flex-direction: column;
	align-items: center;
	top: 40px;
	left: 50%;
	transform: translateX(-55%);
	padding: 0.2rem 0;
	border: 1px solid lightgray;
	width: 180px;
	z-index: 100;
}

.mini-container .mini-content .category-options::after {
	content: "";
	width: 8px;
	height: 8px;
	background-color: #fff;
	transform: rotate(45deg);
	position: absolute;
	top: -5px;
	left: 50%;
	border: 1px solid lightgray;
	border-right: none;
	border-bottom: none;
	z-index: -1;
}

.mini-container .mini-content:hover .category-options {
	display: flex;
}

.mini-container .mini-content .category-options a {
	width: 100%;
	padding: 8px 15px;
	font-weight: 500;
	display: flex;
	align-items: center;
}

.mini-container .mini-content .category-options p {
	color: #000;
	font-size: 16px;
	font-weight: 500;
	margin: 0 0 0 10px;
}

.mini-container .mini-content .category-options a:hover {
	color: #fb641b;
	background-color: #e5e5e5;
	/* font-weight: bold; */
}

.mini-container .new {
	color: #fb641b;
	font-size: 16px;
	font-weight: bold;
	text-decoration: none;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	margin: 0 15px;
	cursor: pointer;
	white-space: nowrap;
}

.mini-container .sale {
	color: #fb641b;
	font-size: 16px;
	font-weight: bold;
	text-decoration: none;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 80%;
	margin: 0 15px;
	cursor: pointer;
}

@media only screen and (max-width: 868px) {
	.mini-container {
		display: none;
	}
}
