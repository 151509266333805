.invoice-box {
	width: 100%;
	height: 100vh;
	display: flex;
	position: relative;
	padding: 5rem 2rem;
	flex-direction: column;
}
.invoice-box .invoice-header {
	width: 100%;
	display: flex;
	align-items: center;
	padding: 0.5rem 0;
	justify-content: space-evenly;
	border-bottom: 2px solid #e5e5e5;
	margin-bottom: 0.25rem;
}

.invoice-box .invoice-header h1 {
	font-size: 1rem;
}

.invoice-box .invoice-header .header-divider {
	width: 2px;
	height: 25px;
	background-color: #e5e5e5;
}

.invoice-box .invoice-header .header-section {
	display: flex;
	flex-direction: column;
}

.invoice-box .invoice-header .header-section p {
	font-size: 0.8rem;
}

.invoice-box .invoice-header .header-section p span {
	font-weight: bold;
}

.invoice-box .invoice-location {
	display: flex;
	width: 100%;
	align-items: center;
	justify-content: space-evenly;
	margin-bottom: 0.5rem;
}

.invoice-box .invoice-location .location-section {
	display: flex;
	flex-direction: column;
}

.invoice-box .invoice-location .location-section h1 {
	font-size: 0.8rem;
}

.invoice-box .invoice-location .location-section p {
	font-size: 0.8rem;
}

.invoice-box .product-list {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	border: 2px solid #777;
}

.invoice-box .product-list .product-list-header {
	display: flex;
	align-items: center;
	width: 100%;
	padding-bottom: 0.5rem;
	border-bottom: 2px solid #777;
	margin-bottom: 0.25rem;
	padding: 0.25rem;
}

.invoice-box .product-list .product-list-header h1 {
	font-size: 0.8rem;
}

.invoice-box .product-list .product-list-header h1:nth-child(1) {
	width: 30%;
	text-align: center;
}

.invoice-box .product-list .product-list-header h1:nth-child(2) {
	width: 15%;
	text-align: center;
}

.invoice-box .product-list .product-list-header h1:nth-child(3) {
	width: 5%;
	text-align: center;
}

.invoice-box .product-list .product-list-header h1:nth-child(4) {
	width: 10%;
	text-align: center;
}
.invoice-box .product-list .product-list-header h1:nth-child(5) {
	width: 10%;
	text-align: center;
}
.invoice-box .product-list .product-list-header h1:nth-child(6) {
	width: 10%;
	text-align: center;
}
.invoice-box .product-list .product-list-header h1:nth-child(7) {
	width: 10%;
	text-align: center;
}
.invoice-box .product-list .product-list-header h1:nth-child(8) {
	width: 10%;
	text-align: center;
}

.invoice-box .product-list .product-info {
	display: flex;
	align-items: center;
	width: 100%;
	padding: 0.25rem;
}

.invoice-box .product-list .product-info p {
	font-size: 0.8rem;
}

.invoice-box .product-list .product-info p:nth-child(1) {
	width: 30%;
}

.invoice-box .product-list .product-info p:nth-child(2) {
	width: 15%;
	text-align: center;
}

.invoice-box .product-list .product-info p:nth-child(3) {
	width: 5%;
	text-align: center;
}

.invoice-box .product-list .product-info p:nth-child(4) {
	width: 10%;
	text-align: center;
}
.invoice-box .product-list .product-info p:nth-child(5) {
	width: 10%;
	text-align: center;
}
.invoice-box .product-list .product-info p:nth-child(6) {
	width: 10%;
	text-align: center;
}
.invoice-box .product-list .product-info p:nth-child(7) {
	width: 10%;
	text-align: center;
}
.invoice-box .product-list .product-info p:nth-child(8) {
	width: 10%;
	text-align: center;
}

.invoice-box .product-list .shipping-section {
	display: flex;
	align-items: center;
	width: 100%;
	padding: 0.25rem;
	border-bottom: 2px solid #777;
}

.invoice-box .product-list .shipping-section p {
	font-size: 0.8rem;
}

.invoice-box .product-list .shipping-section p:nth-child(1) {
	width: 30%;
}

.invoice-box .product-list .shipping-section p:nth-child(2) {
	width: 15%;
	text-align: center;
}

.invoice-box .product-list .shipping-section p:nth-child(3) {
	width: 5%;
	text-align: center;
}

.invoice-box .product-list .shipping-section p:nth-child(4) {
	width: 10%;
	text-align: center;
}
.invoice-box .product-list .shipping-section p:nth-child(5) {
	width: 10%;
	text-align: center;
}
.invoice-box .product-list .shipping-section p:nth-child(6) {
	width: 10%;
	text-align: center;
}
.invoice-box .product-list .shipping-section p:nth-child(7) {
	width: 10%;
	text-align: center;
}
.invoice-box .product-list .shipping-section p:nth-child(8) {
	width: 10%;
	text-align: center;
}

.invoice-box .product-list .product-quanity {
	display: flex;
	width: 100%;
	justify-content: space-between;
	padding: 0.25rem;
	align-items: center;
}

.invoice-box .product-list .product-quanity h1 {
	font-size: 0.8rem;
}

.invoice-box .product-list .product-quanity .product-price {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
}

.invoice-box .product-list .product-quanity .product-price p {
	font-size: 0.7rem;
}

.invoice-box .invoice-footer {
	width: 100%;
	display: flex;
	align-items: center;
	margin-top: 1rem;
	padding: 1rem;
}

.invoice-box .invoice-footer img {
	width: 80px;
	object-fit: contain;
}
