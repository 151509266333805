.terms_conditions-container {
  padding: 5rem 4rem;
  background-color: #fff;
}

.terms_conditions-container .info .sub-header {
  padding-top: 1rem;
}

.terms_conditions-container .info .sub-header-1 {
  margin: 1rem 0;
}

.terms_conditions-container .info .text {
  line-height: 32px;
}

.terms_conditions-container .info .text p b {
  text-decoration: underline;
}

.terms_conditions-container .info .text .sub-text {
  padding: 1rem 0rem 1rem 2rem;
}

@media only screen and (max-width: 769px) {
  .terms_conditions-container {
    padding: 4rem 3rem;
  }
}

@media only screen and (max-width: 426px) {
  .terms_conditions-container {
    padding: 3rem 2rem;
  }

  .terms_conditions-container .header h1 {
    font-size: 24px;
  }

  .terms_conditions-container .info .sub-header {
    padding-top: 0.5rem;
  }

  .terms_conditions-container .info .sub-header h3 {
    font-size: 16px;
  }

  .terms_conditions-container .info .text {
    line-height: 20px;
  }

  .terms_conditions-container .info .text p {
    font-size: 14px;
  }
}
