.panel-container {
	width: 100%;
	height: 400px;
	display: flex;
	justify-content: center;
	align-items: center;
	user-select: none;
	margin-top: 0.25rem;
	padding-top: 0.5rem;
}

.panel {
	width: 98%;
	max-width: 1700px;
	height: 100%;
	cursor: pointer;
	overflow: hidden;
	box-shadow: 0 2px 6px 0 #dee0e2;
	border-radius: 3px;
	overflow: hidden;
}

.panel .swiper-container {
	height: 100%;
}

.panel .swiper-container .swiper-pagination-bullet-active {
	background: #f35429;
}

.panel .swiper-container .cont {
	width: 100%;
	height: 100%;
}

.panel .swiper-container .cont img {
	width: 100%;
	height: 100%;
	object-fit: contain;
}

@media only screen and (max-width: 1400px) {
	.panel-container {
		height: 350px;
	}
}

@media only screen and (max-width: 1024px) {
	.panel-container {
		height: 270px;
	}
}

@media only screen and (max-width: 768px) {
	.panel-container {
		height: 200px;
	}
}

@media only screen and (max-width: 468px) {
	.panel-container {
		height: 110px;
	}
}
