.invoice-container {
	width: 100%;
	display: flex;
	align-items: center;
	flex-direction: column;
}

.invoice-container .invoice-container-main {
	width: 90%;
	background-color: #fff;
	margin: 1rem;
}

.invoice-container .invoice-container-main .invoice-printer {
	width: 100%;
	display: flex;
	justify-content: flex-end;
}
.invoice-container .invoice-container-main .invoice-printer .print-button {
	margin: 1rem;
	width: 120px;
	height: 35px;
	border-radius: 2px;
	border: none;
	background-color: #fb641b;
	color: #fff;
	cursor: pointer;
	margin: 1rem 1rem 1rem 0;
}
