.container {
	width: 100%;
	display: flex;
	align-items: center;
	flex-direction: column;
}
.container-main {
	width: 90%;
	display: flex;
	justify-content: center;
	position: relative;
	background-color: #fff;
}
.content {
	display: flex;
	width: 50%;
	flex-wrap: wrap !important;
}
.wrap {
	width: 100%;
}
.content .wrap .main {
	display: flex;
	flex-wrap: wrap !important;
	background-color: #fff;
}
.container-main .content .wrap .main main .head p {
	color: #fb641b;
	font-size: 32px;
	font-weight: 500;
	margin: 0;
	margin-top: 1rem;
}
.content .wrap .main main {
	width: 80%;
	/* padding: 30px 0; */
	margin: 0 auto;
}
.content .wrap .main main h2 {
	color: #333333;
	font-size: 18px;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
	font-weight: normal;
	/* display: inline-block; */
	margin-left: 15px;
}
.content .wrap .main main .Title .title {
	margin: 0px;
}
.content .wrap .main main .Title .text {
	margin: 0px;
	margin-top: 8px;
}
.text_area_a {
	min-width: 70px;
}
.text_area_a p {
	width: auto !important;
	margin-left: 0 !important;
}
.text_area_a,
.text_area_b h3 {
	margin-left: 15px;
}
.content .wrap .main main p {
	color: #545454;
	font-size: 14px;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
	/* font-weight: normal; */
	/* display: inline-block; */
	margin-left: 15px;
	width: 92%;
}
.content .wrap .main main p.need {
	width: 50%;
	margin: 0;
}
.section_content {
	margin-top: 30px;
	display: inline-block;
	width: 100%;
}

.icon {
	height: 80px;
}
.icon div {
	width: 40px;
	height: 100%;
	padding: 0.8rem 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
}
.icon .title,
.icon i {
	color: #777;
	white-space: nowrap;
}
.icon i {
	font-size: 12px;
	width: 27px;
	height: 27px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	border: 1px solid #c5c5c5;
	z-index: 1;
	background-color: #fff;
}

.icon i::after {
	transform-origin: left;
	transform: scaleX(0);
	transition: transform 0.2s ease;
}

.icon .done:last-child i {
	width: 30px;
	height: 30px;
	font-size: 16px;
}

.icon .done:first-child i::after {
	width: 0;
	height: 0;
}

.icon .done:last-child i::after {
	bottom: 15px;
}
.icon .done i::after {
	content: "";
	width: 92px;
	height: 3px;
	position: absolute;
	right: 27px;
	bottom: 12px;
	background-color: #179b4d;
	transform: scaleX(1);
	transition: transform 0.2s ease;
	z-index: 0;
}
.icon .done i {
	background-color: #179b4d;
	color: #fff;
	position: relative;
	font-size: 12px;
	border: none;
}
.icon .done .title {
	font-size: 14px;
	color: #464646;
}

.status {
	border: 1px solid #d9d9d9;
	border-radius: 5px;
	margin-bottom: 15px;
}
.status .icon_text {
	padding: 0.5rem;
}
.status .icon_text h2 {
	font-size: 16px !important;
	font-weight: 500 !important;
}
.content .wrap .main main .section_content .order_update {
	border: 1px solid #d9d9d9;
	border-radius: 5px;
	margin-top: 12px;
	padding: 0.5rem;
}
.content .wrap .main main .section_content .order_update h2 {
	font-weight: 500 !important;
}
.content .wrap .main main .section_content .message {
	border: 1px solid #d9d9d9;
	border-radius: 5px;
	margin-top: 12px;
	padding: 0.5rem;
}

.customer_information {
	border: 1px solid #d9d9d9;
	border-radius: 5px;
	margin-top: 12px;
	padding: 0.5rem 0;
	width: 100%;
}
.customer_information h2 {
	font-weight: 500 !important;
	margin-bottom: 10px;
}

.content_information {
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	justify-content: space-around;
}

.customer_information .content_information .text_area_a,
.customer_information .content_information .text_area_b,
.customer_information .content_information .text_area_c {
	width: 30%;
	margin: 0;
	padding: 0;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.customer_information .content_information .text_area_a h3,
.customer_information .content_information .text_area_b h3,
.customer_information .content_information .text_area_c h3 {
	font-size: 14px;
	color: #333333;
	word-wrap: break-word;
	text-align: center;
}

.customer_information .content_information .text_area_a p,
.customer_information .content_information .text_area_b p,
.customer_information .content_information .text_area_c p {
	font-size: 12px;
	color: #333333;
	word-wrap: break-word;
	text-align: center;
}

.step_footer span {
	text-decoration: none;
	color: #fb641b;
	font-size: 14px;
	cursor: pointer;
}

.step_footer {
	display: flex;
	width: 100%;
	flex-wrap: wrap !important;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding: 1rem 0;
}

.step_footer .replacement {
	display: flex;
	flex-direction: column;
}

.step_footer .replacement .need {
	display: flex;
}

.step_footer .replacement .replacement-section {
	display: flex;
	align-items: center;
}

.step_footer button {
	color: #ffffff;
	border: 1px solid #d9d9d9;
	border-radius: 5px;
	cursor: pointer;
	background-color: #fb641b;
	border: 1px transparent solid;
	border-radius: 5px;
	font-weight: 500;
	font-size: 14px;
	width: 100px;
	height: 40px;
	outline: none;
	margin-left: 0.5rem;
}

.step_footer button:hover {
	background-color: #fb641b;
}
.step_footer .need {
	margin-top: 10px;
}
.icon img {
	height: 15px;
	width: 15px;
	/* margin-left: 32px; */
	align-self: center;
}
.icon {
	display: flex;
	justify-content: space-around;
	justify-content: space-evenly;
}
.icon span {
	/* color: #45b5aa; */
	font-size: 14px;
	color: #333333;
	/* font-size: 18px; */
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
	font-weight: normal;
}

.container-main .sidebar {
	width: 50%;
	border-right: none;
	border-top: none;
	padding: 0 2rem;
	padding-top: 2rem;
}

.order_update h2 {
	margin-bottom: 10px;
}

.order_update .order-status {
	width: 90%;
	display: flex;
	flex-direction: column;
	margin: 0 auto;
}

.order_update .order-status .activity:not(:last-child) {
	border-bottom: 1px solid lightgray;
	margin-bottom: 10px;
	padding-bottom: 10px;
	padding-left: 10px;
}

.order_update .order-status .activity {
	padding-left: 10px;
}

.order_update .order-status .activity h5,
.order_update .order-status .activity p {
	margin-bottom: 5px;
}

.order_update .noUpdates {
	margin: 10px 0 20px;
	font-size: 16px;
}

.sidebar .items-container {
	width: 100%;
	margin: 1rem auto;
}

.sidebar .items-container .items {
	margin-left: 0;
	margin-top: 0;
	margin-bottom: 20px;
}

.sidebar .discount-sec {
	padding: 20px 0;
	width: 90%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	margin: 0 auto;
	border-top: 2px solid #ccc;
	border-bottom: 2px solid #ccc;
}

.sidebar .discount-sec input {
	height: 40px;
	width: 75%;
	border: 1px gray solid;
	outline: none;
	border-radius: 5px;
	padding: 5px 10px;
	font-size: 12px;
}

.sidebar .discount-sec button {
	height: 40px;
	width: 23%;
	border-radius: 5px;
	background-color: #9f9f9f;
	color: white;
	font-size: 12px;
	outline: none;
	border: 1px solid #9f9f9f;
	cursor: pointer;
}

.sidebar .order-details {
	display: flex;
	flex-direction: column;
	width: 100%;
	border-bottom: 2px solid #ccc;
	align-items: center;
	justify-content: space-between;
	margin-top: 2rem;
	padding: 1rem;
}

.sidebar .order-details .sub,
.sidebar .order-details .shipping-sub,
.sidebar .order-details .points-sub,
.sidebar .order-details .discount-sub {
	display: flex;
	flex-direction: row;
	width: 100%;
	justify-content: space-between;
	align-items: center;
	font-size: 14px;
	font-weight: 600;
	margin: 0.2rem 0;
}

.sidebar .order-details .discount-sub {
	color: green;
}

.sidebar .order-details .points-sub {
	color: goldenrod;
}

.sidebar .order-details .sub p,
.sidebar .order-details .shipping-sub p,
.sidebar .order-details .points-sub p,
.sidebar .order-details .discount-sub p {
	font-weight: bold;
}

.sidebar .order-details .sub .sub-title,
.sidebar .order-details .shipping-sub .sub-title {
	color: #555;
}

.sidebar .total {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 90%;
	align-items: center;
	margin: 15px auto;
	font-weight: bold;
	font-size: 18px;
	color: #fb641b;
}

.sidebar .invoice-button {
	width: 120px;
	background-color: #fb641b;
	height: 35px;
	border-radius: 3px;
	color: #fff;
	margin: 15px 0;
	border: none;
	cursor: pointer;
	outline: none;
}

.sidebar .total p:first-child {
	font-size: 14px;
	color: #000;
}

.step_footer p {
	display: inline-block !important;
}
.rating-cont {
	position: fixed;
	top: 0vh;
	right: 0;
	width: 100%;
	height: 100vh;
	background-color: rgba(0, 0, 0, 0.6);
	z-index: 999;
	display: flex;
	align-items: center;
	justify-content: center;
}
.rating-cont .rating-modal {
	padding: 0.5rem 1.4rem 1rem;
	display: flex;
	flex-direction: column;
	background-color: #fff;
	margin: auto;
	position: absolute;
	width: 40%;
	max-width: 500px;
	width: 90%;
	max-height: 90vh;
	overflow-y: scroll;
	border-radius: 10px;
}
.rating-cont .rating-modal .modal-head {
	height: 50px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-bottom: 1px solid #ccc;
	margin-bottom: 1rem;
}
.rating-cont .rating-modal .modal-head i {
	font-size: 18px;
	cursor: pointer;
}

.rating-cont .rating-modal .modal-body .selected-product {
	width: 100%;
	position: relative;
	cursor: pointer;
}

.rating-cont .rating-modal .modal-body .selected-product .selected-product-overlay {
	display: none;
}

.rating-cont .rating-modal .modal-body .selected-product.active .selected-product-overlay {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	background-color: rgba(251, 100, 27, 0.25);
	border-radius: 5px;
	display: flex;
}

.rating-cont .rating-modal .modal-body .selected-product .selected-product-overlay i {
	position: absolute;
	right: 10px;
	top: 10px;
	font-size: 1.25rem;
	color: #179b4d;
}

.rating-cont .rating-modal .modal-body .return-next {
	width: 100px;
	height: 35px;
	background-color: #fb641b;
	border: none;
	border-radius: 5px;
	color: #fff;
	font-weight: 600;
	cursor: pointer;
	outline: none;
}

.rating-cont .rating-modal .modal-body .return-next.done {
	background-color: #179b4d;
}

.rating-cont .rating-modal .modal-body .rating {
	margin-top: 23px;
	margin-bottom: 17px;
}

.rating-cont .rating-modal .modal-body .rating .stars {
	/* width: 230px; */
	display: flex;
	flex-direction: row;
	align-items: center;
}

.rating-cont .rating-modal .modal-body .rating .stars i {
	font-size: 20px;
	margin-right: 15px;
}

.rating-cont .rating-modal .modal-body .rating .stars p {
	font-weight: 500;
	margin-right: 30px;
	font-size: 15px;
}

.rating-cont .rating-modal .modal-body .rating .stars .fa-star {
	color: #fb641b;
	cursor: pointer;
}

.rating-cont .rating-modal .modal-body .rating .review {
	width: 100%;
	display: flex;
	align-items: flex-start;
	margin-top: 15px;
}
.rating-cont .rating-modal .modal-body .rating .review label {
	font-weight: 500;
	font-size: 15px;
	margin-right: 20px;
}
.rating-cont .rating-modal .modal-body .rating .review textarea {
	padding: 0.3rem 0.5rem;
	border: 1px solid lightgray;
	outline: none;
	border-radius: 5px;
	resize: none;
	height: 100px;
	transition: border 0.3s ease;
}
.rating-cont .rating-modal .modal-body .rating .review textarea:hover,
.rating-cont .rating-modal .modal-body .rating .review textarea:focus {
	border: 1px solid #fb641b;
	transition: border 0.3s ease;
}

.rating-cont .rating-modal .modal-footer {
	padding: 0rem 1rem 0;
	width: 100%;
	display: flex;
	justify-content: flex-end;
	align-items: center;
}

.rating-cont .rating-modal .modal-footer button {
	padding: 0.5rem 1rem;
	color: #fff;
	background-color: #fb641b;
	font-weight: 500;
	border: 1px solid #fb641b;
	border-radius: 5px;
	outline: none;
	cursor: pointer;
	font-size: 15px;
}

.dropdown {
	margin: 1rem 0;
	width: 100%;
}

.dropdown select {
	width: 100%;
	display: flex;
	justify-content: flex-start;
	height: 40px;
	align-items: center;
	padding-left: 15px;
	font-size: 15px;
	border-radius: 5px;
	border: 1px solid grey;
	outline: none;
	background-color: #f1f1f1;
}

.replace {
	padding: 0.5rem 1rem;
	color: #fff;
	background-color: #fb641b;
	font-weight: 500;
	border: 1px solid #fb641b;
	border-radius: 5px;
	outline: none;
	cursor: pointer;
	font-size: 15px;
}

.dropdown-content {
	display: none;
	position: absolute;
	background-color: #f9f9f9;
	box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
	z-index: 1;
	overflow-y: scroll;
	width: 90%;
	height: 40vh;
}

.dropdown-content option {
	color: black;
	padding: 12px 16px;
	display: block;
}

.dropdown:hover .dropdown-content {
	display: block;
}

@media (max-width: 1024px) {
	.content {
		width: 100%;
	}
	.content .wrap .main main {
		width: 70%;
	}
	.container-main {
		flex-direction: column;
	}
	.container-main .sidebar {
		width: 100%;
	}
	.icon .done i::after {
		width: 110px;
	}
}

@media (max-width: 768px) {
	.container-main {
		height: auto;
		width: 100%;
	}

	.icon .done i::after {
		width: 80px;
	}
	/* .message p {
    margin: 0 !important;
    width: auto;
  } */
}

@media (max-width: 680px) {
	.content .wrap .main main {
		padding: 50px 0;
		width: 90%;
	}

	.icon .done i::after {
		width: 48px;
	}
	.content_information {
		flex-direction: column;
	}
	.text_area_a,
	.text_area_b,
	.text_area_c {
		margin: 0.5rem 0 !important;
		width: 100% !important;
	}
	.text_area_b h3 {
		margin-left: 0;
	}
	.customer_information {
		padding: 0.5rem;
	}
	.content .wrap .main main .login_form .a,
	.content .wrap .main main .login_form .b {
		width: 93%;
		margin-bottom: 10px;
	}
	.content .wrap .main main .login_form .b {
		margin-left: 0;
	}
	.content .wrap .main main .login_form button {
		width: 100%;
		margin-left: 0;
	}
	.step_footer {
		flex-direction: column-reverse;
	}
	.content .wrap .main main p.need,
	.step_footer button {
		width: 100%;
	}
	.content .wrap .main main p.need {
		text-align: center;
	}
	.content .wrap .main main .login_form button,
	.step_footer button {
		font-size: 18px;
	}
}
